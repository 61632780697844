import { DatePipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";

const dateLabelMap = {
  startFrom: "Start from:",
  startTo: "Start to:",
  endFrom: "End from:",
  endTo: "End to:"
};

@Pipe({ standalone: false, name: "formatChip" })
export class FormatChipPipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  public transform(value: string, category: string): string {
    // SPEC: Chip associated to date parameter are prefixed by a label defined in the map above
    if (Object.keys(dateLabelMap).includes(category)) {
      value = this.datePipe.transform(new Date(value), "MM/dd/yyyy");
      return `${dateLabelMap[category]} ${value}`;
    }

    // SPEC: If for some reasons we cannot find a valid value, we display a chip with "No Value"
    return value || "No Value";
  }
}
