import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  NgZone,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges
} from "@angular/core";
import { ViewerMessageService, PouchService, Store } from "@viewer/core";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { runInAction } from "mobx";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { PreprintService } from "@viewer/core/toc-items/preprint.service";
import { PrintService } from "@viewer/core/print/print.service";
import { environment } from "@viewer-env/environment";
import { Preprint } from "@orion2/models/tocitem.models";
import { ESearchState } from "@viewer/core/search/searchState";
import { SupersededService } from "@viewer/core/superseded/superseded.service";
import { DocumentService } from "@viewer/core/toc-items/document.service";
import { ServiceBulletinService } from "@viewer/core/toc-items/service-bulletin.service";
import { PdfIosService } from "@viewer/pdf/pdf-ios.service";
import { CordovaService } from "libs/cordova/cordova.service";
import { isIos, getMediaId } from "@orion2/utils/functions.utils";
import { BasepubUserService } from "@viewer/core/basepub/basepub-user.service";
import { SearchProductsService } from "@viewer/home/search-products/service/search-products.service";
import { HttpParams } from "@angular/common/http";
import { MimeType } from "@orion2/models/enums";

@Component({
  standalone: false,
  selector: "o-pdf",
  templateUrl: "./pdf.component.html",
  styleUrls: ["./pdf.component.scss"]
})
export class PdfComponent implements OnInit, OnDestroy, OnChanges {
  @Input() extraDocId: string;
  @Input() encapsulatedPdfId: string;
  @Input() isPreprint: boolean;
  @Input() doNotPrint = false;

  @ViewChild("pdfViewer") pdfViewer;
  @ViewChild("frame", { read: ElementRef, static: true }) frame: ElementRef;

  public pdfBlobUrl: string;
  public pdfId: string;
  public isCordovaIos = environment.platform === "cordova" && isIos();
  public isPrint: boolean;

  private subscriptions: Subscription = new Subscription();
  private isSRD = false;
  private isContract = false;

  constructor(
    public store: Store,
    private pouchService: PouchService,
    private route: ActivatedRoute,
    private router: Router,
    private messageService: ViewerMessageService,
    private translate: TranslateService,
    private preprintService: PreprintService,
    private documentService: DocumentService,
    private sbService: ServiceBulletinService,
    private printService: PrintService,
    private supersededService: SupersededService,
    private pdfIosService: PdfIosService,
    private cordovaService: CordovaService,
    private basepubUserService: BasepubUserService,
    private searchProductsService: SearchProductsService,
    private zone: NgZone,
    private cd: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    // this is for back to encapsulated PDF from an encapsulated PDF ,
    if (
      changes["encapsulatedPdfId"]?.previousValue &&
      changes["encapsulatedPdfId"]?.currentValue &&
      changes["encapsulatedPdfId"]?.currentValue !== changes["encapsulatedPdfId"]?.previousValue
    ) {
      this.pdfId = this.encapsulatedPdfId;
      this.displayPDF();
    }
  }

  ngOnInit() {
    // SPEC: Do not execute init on preprint
    // this is done by the preprint component
    runInAction(() => {
      this.store.displayDone = false;
    });
    // Define handleUrlFromPDF
    // transform pdf url link to a DMC and navigate to
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).handleUrlFromPDF = (url: string) => {
      // url example: http://orion.airbus.com/pubs/online?search=@textToBeSearch&model=[@listOfAircraftModels]&version=[@listOfAircraftVersions]
      const linkRegex = /^https?:\/\/orion.airbus.com\/pubs\/online?.*search=([^&/]*)/g;
      let result = linkRegex.exec(url);
      if (!result) {
        // url example: http://orion.airbus.com/pub/@occCode/latest/dmc/@textToBeSearch
        const legacyLinkRegex = /^https?:\/\/orion.airbus.com\/pub\/\d+\/latest\/dmc\/([^&/]*)/g;
        result = legacyLinkRegex.exec(url);
      }
      if (!result) {
        // url example: http://h160-a-21-61-1002-00a-720a-a
        // example dmcInput = H160-A-21-61-1002-00A-720A-A
        // dmcInput can be a DM container (Z reference) = H160-A-21-61-1002-00Z-720A-A
        const legacyLinkRegex = /^https?:\/\/(h160-[^&/]*)\//gi;
        result = legacyLinkRegex.exec(url);
        // SPEC: DMCs are linked in lowercase, but needed in uppercase
        if (result) {
          result[1] = result[1]?.toUpperCase();
        }
      }
      if (result) {
        return this.zone.run(() =>
          this.router.navigate(["redirect", decodeURI(result[1])], {
            relativeTo: this.route.root.firstChild.firstChild
          })
        );
      }
      this.messageService.error(this.translate.instant("pdf.handle.link.error"));
    };
    this.isPrint = this.router.url.includes("/print");
    this.isSRD = this.router.url.includes("/srd");
    this.isContract = this.router.url.includes("/contract/");
    if (this.isCordovaIos) {
      this.pdfIosService.setup();
    }
    if (this.extraDocId) {
      if (environment.platform === "cordova") {
        const cordovaExtradocFilePath =
          // For android devices cordova plugin file seem to point to the wrong directory
          (this.isCordovaIos ? window["cordova"].file.applicationDirectory + "www/" : "file://") +
          "assets/extra-docs/" +
          this.extraDocId;
        this.printService.pdfFilepath = cordovaExtradocFilePath;
        if (isIos()) {
          this.pdfIosService.displayPDF(cordovaExtradocFilePath, this.store.highlightKeyword);
        }
      } else {
        this.printService.pdfFilepath = "user_manual";
      }

      if (!this.isCordovaIos) {
        this.pdfBlobUrl = "../../extra-docs/" + this.extraDocId;
      }

      runInAction(() => {
        this.store.displayDone = true;
      });
    } else {
      // We are not on extradoc
      this.subscriptions.add(
        this.route.paramMap.subscribe((params: ParamMap) => {
          // If we are in the special case where the PDF is used as illustr, we get its id from ':mediaId' in URL path
          this.pdfId = this.encapsulatedPdfId || params.get("pdfId") || params.get("mediaId");
          if (this.pdfId || this.isPrint || this.isPreprint || this.isSRD || this.isContract) {
            this.displayPDF();
          }
        })
      );
    }
  }

  ngOnDestroy() {
    if (this.isCordovaIos) {
      this.pdfIosService.dispose();
    }
    this.subscriptions.unsubscribe();
    // Reset pdfData on PdfComponent is destroy
    this.printService.pdfData = undefined;
    this.printService.pdfFilepath = "";
    // Clean Blob URL
    window.URL.revokeObjectURL(this.pdfBlobUrl);
  }

  // We cannot use Promise to call the callback writing a file is done asynchronously
  // with callback system (instead of Promise).
  public getPdfData(): Promise<Blob> {
    if (this.isPrint) {
      const uint8Array = new Uint8Array(this.printService.printPdf);
      const blob = new Blob([uint8Array], { type: MimeType.PDF });
      return Promise.resolve(blob);
    }
    if (this.isSRD) {
      const selectedFilters = this.searchProductsService.selectedFilters;
      let params = new HttpParams({
        fromObject: {
          aircraftTechnicalName: selectedFilters.aircraftTechnicalName,
          type: this.router.url.includes("/base") ? "base" : "srd"
        }
      });
      if (selectedFilters.aircraftVersion) {
        params = params.set("aircraftVersion", selectedFilters.aircraftVersion);
      }
      return this.basepubUserService
        .getSRD(params)
        .then((pdf: ArrayBuffer) => new Blob([pdf], { type: MimeType.PDF }));
    }
    if (this.isContract) {
      const contractId = this.route.snapshot.paramMap.get("contractId");
      return this.basepubUserService
        .getContractPdf(contractId)
        .then((pdf: ArrayBuffer) => new Blob([pdf], { type: MimeType.PDF }));
    }
    if (this.isPreprint) {
      let preprintPromise: Promise<Preprint>;
      if (this.store.currentDMC.startsWith("preprint")) {
        preprintPromise = this.pouchService.tocPublicCaller.get(
          this.store.currentDMC
        ) as Promise<Preprint>;
      } else {
        preprintPromise = this.preprintService.getPreprint(
          this.store.currentDMC
        ) as Promise<Preprint>;
      }
      return preprintPromise.then((preprint: Preprint) =>
        this.preprintService.getPreprintPdf(preprint._id)
      );
    }
    if (this.store.currentDMC) {
      if (this.store.currentDMC.startsWith("document")) {
        return this.documentService.getDocumentPdf(this.store.currentDMC);
      }
      if (this.store.currentDMC.startsWith("sb")) {
        return this.sbService.getServiceBulletinPdf(this.store.currentDMC);
      }
      if (this.store.currentDMC.startsWith("superseded")) {
        return this.supersededService.getPdf(this.pdfId).then(rawPdf => {
          const uint8Array = new Uint8Array(rawPdf.data);
          return new Blob([uint8Array], { type: MimeType.PDF });
        });
      }
      const pdfId = getMediaId(this.store.isLegacyImport, this.store.currentDMC, this.pdfId);
      return this.pouchService.pdfCaller.getAttachmentsBlob(pdfId);
    }
  }

  public loadPdf(): void {
    const query =
      this.store.highlightKeyword && !this.isPrint ? `#search=${this.store.highlightKeyword}` : "";
    this.pdfBlobUrl = this.pdfBlobUrl + query;

    if (this.pdfViewer) {
      this.pdfViewer.pdfSrc = this.pdfBlobUrl; // pdfSrc can be Blob or Uint8Array
      this.pdfViewer.refresh(); // Ask pdf viewer to load/reresh pdf
      this.cd.detectChanges();
    }
  }

  interceptClick() {
    if (!this.isCordovaIos) {
      // on cordova the print button on pdfJS iframe do not lauch the print because we don't have the print pop-up system
      // on pdfJS we have two print button one visible and one hidden according to the size of the screen
      // so we intercept click on these button for calling our print function
      if (environment.platform === "cordova") {
        const print = onClickEvent => {
          onClickEvent.preventDefault();
          onClickEvent.stopImmediatePropagation();
          this.printService.printFinal();
        };
        this.frame.nativeElement.firstChild.contentWindow.document.querySelector("#print").onclick =
          print;
        this.frame.nativeElement.firstChild.contentWindow.document.querySelector(
          "#secondaryPrint"
        ).onclick = print;
      }

      const viewerContainer =
        this.frame.nativeElement.firstChild.contentWindow.document.querySelector(
          "#viewerContainer"
        );

      if (viewerContainer) {
        viewerContainer.onclick = onClickEvent => {
          onClickEvent.preventDefault();
          onClickEvent.stopImmediatePropagation();
          const element = onClickEvent.srcElement as Element;
          if (element.tagName === "A" && this.store.offlineLoadingState === ESearchState.READY) {
            // example href element : http://orion.airbus.com/pubs/online?search=@textToBeSearch&model=[@listOfAircraftModels]&version=[@listOfAircraftVersions]
            // full DMC corresponding is DMC-H160-B1000-A-21-61-1002-00A-720A-A_en-US
            // prefix DMC, version B1000 and language are missing
            const url = element.getAttribute("href");
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            this.zone.run(() => (window as any).handleUrlFromPDF(url));
          }
        };
      }
    }
  }

  private displayPDF() {
    try {
      this.getPdfData().then((pdfBlob: Blob) => {
        // SPEC: we do not want to print encasulated pdf in case preprint is overridinng it
        this.printService.pdfData = this.doNotPrint ? undefined : pdfBlob;
        if (!this.isCordovaIos) {
          this.pdfBlobUrl = URL.createObjectURL(pdfBlob);
          this.loadPdf();

          runInAction(() => {
            this.store.displayDone = true;
          });
        } else {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const folderpath = (window as any).cordova.file.cacheDirectory;
          const filename = "temp.pdf";
          const iosFilePath = folderpath + filename;
          this.printService.pdfFilepath = iosFilePath;
          this.cordovaService.writeFile(iosFilePath, pdfBlob, MimeType.PDF).then(() => {
            this.pdfIosService.displayPDF(iosFilePath, this.store.highlightKeyword);
            runInAction(() => {
              this.store.displayDone = true;
            });
          });
        }
      });
    } catch (e) {
      console.error("PDF COMPONENT - error to get pdf : ", e);
      this.messageService.error(this.translate.instant("pdf.error"));
    }
  }
}
