import { TocItem } from "@orion2/models/tocitem.models";
import { MatTableDataSource } from "@angular/material/table";
import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { Store } from "@viewer/core";
import { IReactionDisposer, reaction } from "mobx";

@Component({
  standalone: false,
  selector: "o-toc-item-header",
  templateUrl: "./toc-item-header.component.html",
  styleUrls: ["./toc-item-header.component.scss"]
})
export class TocItemHeaderComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() dataSource: MatTableDataSource<TocItem>;
  @Input() displayReady: boolean;
  @Input() emptyMessage: string;
  @Input() disableInput = false;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public filter = "";

  private itemPerPageDisposer: IReactionDisposer;

  constructor(public store: Store) {
    this.itemPerPageDisposer = reaction(
      () => this.store.userItemsPerPage,
      (value: number) => {
        this.dataSource.paginator.pageSize = value;
      }
    );
  }

  ngAfterViewInit() {
    // We do this here to be sure that the dom had time to create the paginator
    if (this.dataSource) {
      this.dataSource.paginator = this.paginator;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["dataSource"]?.currentValue) {
      this.dataSource = changes["dataSource"].currentValue;
      this.dataSource.paginator = this.paginator;
    }
  }

  ngOnDestroy(): void {
    this.itemPerPageDisposer();
  }

  public onFilterChange() {
    this.dataSource.filter = this.filter;
  }
}
