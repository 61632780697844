import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { HistoryElem } from "@orion2/models/tocitem.models";
import { MatTableDataSource } from "@angular/material/table";
import { DatePipe } from "@angular/common";
import { Store } from "@viewer/core";
import { OrionCardData } from "@viewer/shared-module/orion-card/orion-card.component";

@Component({
  standalone: false,
  selector: "o-history-list",
  templateUrl: "./history-list.component.html",
  styleUrls: ["./history-list.component.scss"]
})
export class HistoryListComponent implements OnInit {
  @Input() history: HistoryElem[];
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onClick: EventEmitter<HistoryElem> = new EventEmitter();
  public dataSource: MatTableDataSource<string | HistoryElem>;
  dmcSelected: string;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.dmcSelected = this.store.currentDMC;
    const data = this.groupBy();
    this.dataSource = new MatTableDataSource(data);
  }

  groupBy(): (string | HistoryElem)[] {
    const dateFormatter = new DatePipe(this.store.currentLanguage);
    return this.history.reduce(
      (acc: (string | HistoryElem)[], cur: HistoryElem) => {
        const date = new Date(cur.date);
        const dateSection = dateFormatter.transform(date, "fullDate");
        if (!acc) {
          acc = [];
        }
        if (!acc.includes(dateSection)) {
          acc.push(dateSection);
        }
        acc.push(cur);
        return acc;
      },
      [] as (string | HistoryElem)[]
    );
  }

  emitClose(item: HistoryElem): void {
    this.onClick.emit(item);
  }

  isDate(index: number, item: string | HistoryElem): boolean {
    return typeof item === "string";
  }

  public getUrl(dmc: string) {
    return `/pub/${this.store.publicationID}/${this.store.publicationRevision}/du/${dmc}`;
  }

  public getOrionCardData(element: HistoryElem): OrionCardData {
    return {
      dmc: element.dmc,
      title: element.dmc,
      forceVisibility: true
    };
  }
}
