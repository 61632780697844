import { CoreCaller } from "@viewer/core/pouchdb/core/coreCaller";
import { Injector } from "@angular/core";
import { DbSchema } from "libs/transfert/model/pubSchema";
import { MediaObject } from "@orion2/models/couch.models";

/**
 * Represent Xml database (DMC and XSL)
 *
 * @export
 * @class XmlDBs
 * @extends {OrionDBs}
 */
export class MediaCaller extends CoreCaller {
  constructor(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    processor: any,
    protected injector: Injector,
    dbSchema: DbSchema
  ) {
    super(dbSchema, processor, injector);
  }

  /**
   * Get all thumb object for dmc
   *
   * @param dmc
   * @returns
   * @memberof MediaDBs
   */
  public getThumbs(dmc: string, keys: string[]): Promise<MediaObject[]> {
    if (this.store.isLegacyImport) {
      const queryParam = {
        startkey: `thumb__${dmc}`,
        endkey: `thumb__${dmc}\ufff0`,
        include_docs: true
      };

      return this.get<MediaObject>(queryParam) as Promise<MediaObject[]>;
    }

    return this.get(keys) as Promise<MediaObject[]>;
  }

  getAttachments(id: string) {
    return this.get([[id, { attachments: true, binary: true }]]);
  }

  public getAttachmentsBlob(id: string): Promise<Blob> {
    return this.callFunction("getAttachmentsBlob", [id]);
  }

  /**
   * Get list of welcome media
   * can be png / mp4 or both
   *
   * @returns
   * @memberof MediaDBs
   */
  public getWelcome(): Promise<MediaObject[]> {
    const queryParam = {
      startkey: "loap_welcome_",
      endkey: "loap_welcome_\ufff0",
      include_docs: true
    };
    return this.get<MediaObject[]>(queryParam) as Promise<MediaObject[]>;
  }
}
