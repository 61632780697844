/* eslint-disable @typescript-eslint/no-explicit-any */
import { WebWorkerService, ApplicabilityService, Store, SearchService } from "@viewer/core";
import {
  idAsKey,
  arrayUpsert,
  sortMapByKeys,
  getClimatShortLabel,
  getSectionFromDMC,
  handleS1000DSearchByDmc,
  isS1000DSearchByDmc,
  replaceRevisionFromDoc
} from "@viewer/shared-module/helper.utils";
import { SearchIndexBuilder, getIndexTypeFromId } from "@viewer/core/search/searchIndexBuilder";
import { getLanguage, handleFrench, phonemFilter } from "@orion2/utils/search.utils";
import { remove as removeDiacritics } from "diacritics";
import { MsmFormatter, TaskNamespace } from "@orion2/msm-formatter/index";
import elasticlunr from "elasticlunr";
import { InspectionService } from "@viewer/core/toc-items/inspection.service";
import {
  SearchResponse,
  SearchResult,
  SearchData,
  FacetedSearchResult
} from "@viewer/core/search/searchModel";
import { NgZone } from "@angular/core";
// Lodash.cloneDeep is really slow so we replace it with a faster lib.
// The import should be import copy from "fast-copy";
// By using import cloneDeep from "fast-copy"; we can use
// fast-copy as a direct replacement of cloneDeep.
import cloneDeep from "fast-copy";
import { SupersededIpc, SupersededService } from "@viewer/core/superseded/superseded.service";
import { separatorRegex } from "@orion2/utils/constants.utils";
import { Inspection, TaskInspectionDoc } from "@orion2/models/tocitem.models";
import { PreprintService } from "@viewer/core/toc-items/preprint.service";
import { IpcFormatter } from "@orion2/ipc-formatter/formatter";

export interface FieldsConfig {
  [key: string]: { boost: number; bool: string };
}

export class SearchProvider {
  public supersededCard = 0;
  private lunrObject: any;

  private allInspections: Inspection[] = [];
  private allTasks: TaskInspectionDoc[] = [];
  private notFoundTasks: TaskInspectionDoc[] = [];
  private notFoundOffset: number;
  private originalReferenceToResultMap: SearchResult[];
  private originalResultToReferenceMap = new Map<string, number>();
  // lunr ref to result
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private SearchIndexBuilder = new SearchIndexBuilder();

  constructor(
    private applicabilityService: ApplicabilityService,
    private store: Store,
    private dataProvider: SearchService,
    private workerService: WebWorkerService,
    private inspectionService: InspectionService,
    private supersededService: SupersededService,
    private ngZone: NgZone,
    private preprintService: PreprintService
  ) {}

  public async loadIndex(): Promise<void> {
    const docs = (await this.dataProvider.getBaseStructure()) as any;
    const docMap = idAsKey(docs);
    const referenceToResultMap: any = docMap["map"];
    delete referenceToResultMap._id;
    delete referenceToResultMap._rev;
    // Turns referenceToResultMap into an array of SearchResult
    this.originalReferenceToResultMap = Object.keys(referenceToResultMap).map(index => {
      referenceToResultMap[index].id = +index;
      return referenceToResultMap[index] as SearchResult;
    });
    this.store.referenceToResultMap = cloneDeep(this.originalReferenceToResultMap);
    this.originalResultToReferenceMap = new Map<string, number>();
    this.store.referenceToResultMap.forEach((searchResult, index) => {
      const dmc = searchResult.dmc;
      if (dmc) {
        // The reference is used in the facets
        // Remove the dmc revision
        this.originalResultToReferenceMap.set(replaceRevisionFromDoc(searchResult.dmc), +index);
      }
    });
    this.store.resultToReferenceMap = cloneDeep(this.originalResultToReferenceMap);

    // can't pass referenceMap in worker , empty array
    const lunrIndex = await this.workerService.buildIndex(docs);
    elasticlunr.tokenizer.setSeperator(separatorRegex);
    const packLanguage = getLanguage(this.store.publicationID);
    if (packLanguage === "fr") {
      handleFrench(elasticlunr);
    }

    this.notFoundOffset = this.store.referenceToResultMap.length;
    // When we load the index for the first time, we also have to make sure
    // inspections and tasks are loaded.
    await this.ngZone.runOutsideAngular(() => this.loadInspectionsAndTasks());

    if (
      this.store.pubInfo &&
      this.store.pubInfo.capabilities &&
      this.store.pubInfo.capabilities.search.phonetizer === true
    ) {
      const phonetizer = (token: any) => phonemFilter(token, packLanguage);
      elasticlunr.Pipeline.registerFunction(phonetizer, "phonetizer");
    }
    this.lunrObject = elasticlunr.Index.load(lunrIndex);
  }

  public async loadInspectionsAndTasks() {
    this.store.referenceToResultMap = cloneDeep(this.originalReferenceToResultMap);
    this.store.resultToReferenceMap = cloneDeep(this.originalResultToReferenceMap);

    const { allInspections, allTasks, notFoundTasks } = await this.inspectionService
      .getAllInspection()
      .then((inspections: Inspection[]) =>
        this.inspectionService
          .getAllTasks(inspections)
          // destructuring getAllTasks result
          .then(({ all, notFound }) => ({
            allInspections: inspections,
            allTasks: all,
            notFoundTasks: notFound
          }))
      );
    // allTasks is tasks that are part of at least one inspection
    this.allTasks = cloneDeep(allTasks);
    this.allInspections = cloneDeep(allInspections);
    this.notFoundTasks = cloneDeep(notFoundTasks);
    // For tasks, enrich referenceToResultMap with parents
    this.allTasks.forEach(task => {
      // Remove revision from the task id in order to compare with all revision
      const id = replaceRevisionFromDoc(task._id);

      // I have change to undefined to remove error logs, this is temporary
      const idRefSearch = this.store.resultToReferenceMap.get(id);
      if (this.store.referenceToResultMap[idRefSearch]) {
        (this.store.referenceToResultMap[idRefSearch] as any as TaskInspectionDoc).parents =
          task.parents;
      }
    });

    this.notFoundTasks.forEach((task, i) => {
      const taskId = task._id.split("__")[2];
      const notFoundTask = {
        dmc: task._id,
        revision: "deleted",
        applicabilityMD5: undefined,
        manual: "MSM",
        reference: undefined,
        shortTitle: undefined,
        id: this.notFoundOffset + i,
        versions: [],
        task: [taskId],
        score: -1,
        parents: task.parents
      };

      this.store.referenceToResultMap[this.notFoundOffset + i] = notFoundTask;
      this.store.resultToReferenceMap.set(notFoundTask.dmc, notFoundTask.id);
      task.parents.forEach(parent => {
        const inspection = this.allInspections.find((insp: Inspection) => insp._id === parent.id);
        const taskInInspection = inspection.tasks.find(t => t.idRefDM === task._id);
        if (taskInInspection) {
          taskInInspection.idRefSearch = this.notFoundOffset + i;
        }
      });
    });
  }

  /**
   * Search for a string in lunr index.
   * Filters results by applicability.
   * Boost is used for index scoring in result (see lunr doc).
   *
   * @param searchInput
   * @returns
   */
  public async search(searchInput: string, background = false): Promise<SearchResponse> {
    if (!this.lunrObject) {
      throw new Error("Index not loaded yet");
    }

    // Clean superseded card
    this.cleanResultMap();

    // If no search input then searchResult = this.store.referenceToResultMap;
    if (!searchInput) {
      const filteredResults = this.store.showNotApplicable
        ? this.store.referenceToResultMap
        : this.removeNotApplicable(this.store.referenceToResultMap);
      return Promise.resolve(this.hydrateSearchResult(filteredResults));
    }

    const fieldsConfig: FieldsConfig = {
      text: { boost: 1, bool: "AND" },
      infoName: { boost: 2, bool: "AND" },
      techName: { boost: 3, bool: "AND" },
      shortDmc: { boost: 4, bool: "AND" }
    };

    const lettersToLoad = searchInput
      .split(elasticlunr.tokenizer.seperator)
      .map((input: string) => removeDiacritics(input.charAt(0).toLowerCase()));

    // Add Superseded card
    // Caractere number >= 3 and contain a numeric caracter
    // Exemple of test for H175:
    // 3410-12-231
    // 3410-12
    // 22258BC050010L
    const supersededProm = this.supersededService.enableSupersededSeach(searchInput);

    return Promise.all([
      this.addSearchFieldsForList(fieldsConfig, lettersToLoad),
      supersededProm
    ]).then(([_, resSuperseded = []]: [void, SupersededIpc[]]) => {
      const searchResults = this.lunrObject.search(searchInput, {
        fields: fieldsConfig,
        expand: true
      });

      let searchResultsWithMeta: SearchResult[];
      // SPEC: When searchInput is a H160 DM-container, we want to get all dmc
      // that contains the searchInput with any letter instead of the "Z"
      // H160 DM-container example : H160-A-63-00-0001-00Z-136A-A
      // DMC variants = H160-A-63-00-0001-00A-136A-A, H160-A-63-00-0001-00B-136A-A
      // regex identifying a H160 DM container (Z reference)
      if (isS1000DSearchByDmc(searchInput)) {
        searchResultsWithMeta = handleS1000DSearchByDmc(
          searchInput,
          this.store.referenceToResultMap
        );
      } else {
        searchResultsWithMeta = searchResults.map(lunrResult => ({
          ...this.store.referenceToResultMap[lunrResult.ref],
          score: lunrResult.score
        }));
      }
      // this.store.referenceToResultMap is always defined and at least its length is 0
      const lastId = this.store.referenceToResultMap.length;

      // In case of error from superseded resSuperseded is an empty array
      searchResultsWithMeta = searchResultsWithMeta.concat(
        resSuperseded
          .map((ipc, index) => {
            const dmcObj = this.supersededService.createSearchObject(ipc, lastId + index);

            // SPEC: Add Superseded response only if Superseded IPC is newer than package IPC
            const packageIpc: SearchResult = this.store.referenceToResultMap.find(
              // Comparison with reference and versions to be sure that data are targeting the same IPC
              (el: SearchResult) =>
                // SPEC: dmcObject.reference contain the ipc ata numbers, for example : 67-13-00-01
                // and el.reference contain shortDMC from referenceToResultMap, for example : IPC 67-13-00-01
                el.reference?.includes(dmcObj.reference) &&
                JSON.stringify(dmcObj.versions) === JSON.stringify(el.versions)
            );

            // the order of tests is important because we can't do packageIpc.date if packageIPC is undefined
            const shouldAddSupersededResponse: boolean =
              // SPEC the ipc doesn't exist in the package => we should add it
              !packageIpc ||
              // SPEC this ipc has no date => we should add the result (because we can't decide which is older/newer)
              !packageIpc.date ||
              // SPEC this new superseded IPC has no date => we should add the result (because we can't decide which is older/newer)
              !dmcObj.date ||
              // SPEC both have a date => we keep the newer
              // SPEC iOS don't support format yyyy.mm.dd
              // We change by yyyy/mm/dd
              new Date(packageIpc.date.toString().replace(/\./g, "/")).getTime() <
                new Date(dmcObj.date.toString().replace(/\./g, "/")).getTime();

            if (shouldAddSupersededResponse) {
              // We add the new superseded obj to referenceToResultMap in order to get data
              // when we call updateMetaData() in search.service
              this.store.referenceToResultMap.push(dmcObj);
              this.supersededCard++;
              return dmcObj;
            }
          })
          .filter(Boolean)
      );

      // IN background mode we only need the resuls
      // we don't need to hydrateSearchResults
      // nor populate facets.
      // We need the fastests possible answer.
      if (background) {
        return {
          search: searchResultsWithMeta,
          facet: undefined,
          input: searchInput
        };
      }

      const filteredResults = this.store.showNotApplicable
        ? searchResultsWithMeta
        : this.removeNotApplicable(searchResultsWithMeta);
      return this.hydrateSearchResult(filteredResults);
    });
  }

  /**
   * From the fieldConfig object, iterate all the corresponding fields (example : textfield = 'a_something' in DB )
   * to retrieve all att.txt in DB for a given character (selectedChar)
   *
   * @param fieldConfig
   * @param charList
   */
  private async addSearchFieldsForList(fieldConfig: FieldsConfig, charList: string[]) {
    if (!this.lunrObject) {
      throw new Error("lunr root is not loaded yet");
    }
    const keyList = this.prepareKeyListRequest(fieldConfig, charList);
    if (keyList.length) {
      const response: any[] = await this.ngZone.runOutsideAngular(() =>
        this.dataProvider.getFieldForLetters(keyList)
      );

      if (response) {
        for (const element of response) {
          const details = getIndexTypeFromId(element);
          const fieldIndex = this.SearchIndexBuilder.keyMap[details.indexKeyName];
          const selectedChar = details.idLeaf;
          // If `letter is not already loaded` condition removed : This no longer happens since the keylist contains only missing fields
          this.lunrObject.index[fieldIndex].root[selectedChar] = element.docs;
        }
      }
    }
  }

  /**
   * Prepare keylist for PouchDb request.
   * Example : if search input = 'rotor', key list = ['a_r', 'b_r', 'c_r'].
   * If an index (ex: text for "r") is already load, the corresponding key is
   * dismissed (a_r).
   *
   * @param fieldConfig the configuration of the fields.
   * @param charList the list of characters to load.
   * @returns the list of keys.
   */
  private prepareKeyListRequest(fieldConfig: FieldsConfig, charList: string[]): string[] {
    const keyList = [];
    for (const field in fieldConfig) {
      if (!field) {
        continue;
      }
      for (const char of charList) {
        const rootNode = this.lunrObject.index[field].root;

        if (char.length !== 0 && !rootNode[char]) {
          const key = this.SearchIndexBuilder[`${field}Key`] + "_" + char;
          keyList.push(key);
        }
      }
    }
    return keyList;
  }

  private cleanResultMap(): void {
    // We should clear all superseded result to avoid useless content
    // The superseded are near the end of the map
    if (this.supersededCard > 0) {
      this.store.referenceToResultMap.splice(-this.supersededCard);
      this.supersededCard = 0;
    }
  }

  /**
   * Regenerate a new results list without notApplicable from filter
   *
   * @param resultstoFilter
   * @returns
   */
  private removeNotApplicable(resultstoFilter: SearchResult[]): SearchResult[] {
    return resultstoFilter
      .filter((result: SearchResult) => this.applicabilityService.isApplicable(result))
      .filter((result: SearchResult) => this.applicabilityService.isApplicablePart(result));
  }

  private hydrateSearchResult(searchResults: SearchResult[]): SearchResponse {
    const facetedSearchResult = new FacetedSearchResult();
    searchResults.forEach(lunrResult => this.populateFacet(lunrResult, facetedSearchResult));
    this.populateFacetWithInspection(facetedSearchResult, searchResults);

    // we need to sort values of each category by alphabetic order
    Object.keys(facetedSearchResult).forEach(category => {
      facetedSearchResult[category] = sortMapByKeys(facetedSearchResult[category]);
    });
    return {
      search: searchResults,
      facet: facetedSearchResult,
      input: this.store.searchInput
    };
  }

  private populateFacetWithInspection(facetedSearchResult: any, searchResult: any[]) {
    this.allInspections.forEach(inspection => {
      // the inspection taskId do not necessarily correspond to the right task
      // we have to trust the id on the search map corresponding to the idRefDM
      const mapInspection = inspection.tasks.map(ids =>
        this.store.resultToReferenceMap.get(ids.idRefDM)
      );

      if (mapInspection.length > 0) {
        facetedSearchResult.inspection.set(inspection.title, mapInspection);
      }
    });

    // Create a facet with all unused task in inspection
    const notInspectionTaskSearchIds = searchResult
      .filter(e => e.dmc.startsWith("task") && (!e.parents || e.parents.length === 0))
      .map(e => this.store.resultToReferenceMap.get(replaceRevisionFromDoc(e.dmc)));

    facetedSearchResult.inspection.set("-", notInspectionTaskSearchIds);
  }

  private populateFacet(searchResult: SearchResult, facetedSearchResult: FacetedSearchResult) {
    const revisionKey = searchResult.revision;
    const dmc = replaceRevisionFromDoc(searchResult.dmc);
    let manualKey = "-";
    let chapterKey = "-";
    let sectionKey = "-";

    if (!searchResult.type) {
      /**
       * SPEC: If searchResult.type !== "", it's an indexed toc items,
       * so we don't have manual and chapter infos.
       */
      manualKey = searchResult.manual || searchResult.reference?.split(" ")[0] || "Not available";
      // getSectionFromDMC always returns 2char - 2char.
      sectionKey = getSectionFromDMC(dmc);

      // chapter is the first 2 characters of section.
      chapterKey = sectionKey.substring(0, 2);
    }

    this._populateFacetWithVersions(searchResult, facetedSearchResult);

    if (searchResult.task && searchResult.task.length > 0) {
      this._populateFacetWithTask(searchResult, facetedSearchResult, revisionKey);
    } else if (searchResult.part && searchResult.part.length > 0) {
      this.populateFacetWithPart(searchResult, facetedSearchResult, revisionKey);
      // SPEC : We add the filter "parts" for intersections and to have good count of filters in parts universe
      // this filter is not visible in filter list
      arrayUpsert(facetedSearchResult.parts, "", searchResult.id);
    } else {
      arrayUpsert(facetedSearchResult.revision, revisionKey, searchResult.id);
      // SPEC : We add the filter "document" for intersections and to have good count of filters in document universe
      // this filter is not visible in filter list
      arrayUpsert(facetedSearchResult.document, "", searchResult.id);
    }

    if (searchResult.from === "superseded") {
      arrayUpsert(facetedSearchResult.superseded, "superseded", searchResult.id);
    }

    arrayUpsert(facetedSearchResult.chapter, chapterKey, searchResult.id);
    arrayUpsert(facetedSearchResult.manual, manualKey, searchResult.id);
    arrayUpsert(facetedSearchResult.section, sectionKey, searchResult.id);

    // Add indexed tocitem facets
    arrayUpsert(facetedSearchResult.types, searchResult.type || "-", searchResult.id);
  }

  /**
   * Populates the facet with versions.
   *
   * @param searchResult the result of the search.
   * @param facetedSearchResult the faceted result to populate with the
   * versions of the tasks.
   */
  private _populateFacetWithVersions(
    searchResult: SearchResult,
    facetedSearchResult: FacetedSearchResult
  ): void {
    if (searchResult.versions?.length) {
      for (const versionKey of searchResult.versions) {
        arrayUpsert(
          facetedSearchResult.versions,
          // if versionKey === "" then we put "-"
          versionKey || "-",
          searchResult.id
        );
      }
    } else {
      arrayUpsert(facetedSearchResult.versions, "-", searchResult.id);
    }
  }

  /**
   * Populates the facet with tasks (from the search result).
   *
   * @param searchResult the result of the search.
   * @param facetedSearchResult the faceted result to populate with the tasks
   * of the search.
   * @param revisionKey the key of the revision.
   */
  private _populateFacetWithTask(
    searchResult: SearchResult,
    facetedSearchResult: FacetedSearchResult,
    revisionKey: string
  ): void {
    const taskData = MsmFormatter.unserialize(searchResult.task);

    /**
     * TASK.length === 1 means that we only have taskId, and
     * the task is not found in referenceResultMap.
     * If the task is not found, we create a task with the status "deleted",
     * and every value is undefined except id.
     * Thus, we need to add a default value for every facet in case the task
     * is not found.
     * If we don't, it creates an error of elements count in all facets.
     * SPEC: add values for EVERY task facet for deleted tasks.
     */
    if (searchResult.task.length === 1) {
      this._populateFacetWithPartWithOnlyOneTask(taskData, facetedSearchResult, searchResult);
      return;
    }

    // climatic conditions
    const climats = taskData.applic?.climat || ["-"];
    climats.forEach(climat => {
      const shortLabel = getClimatShortLabel(climat);
      arrayUpsert(facetedSearchResult.climat, shortLabel, searchResult.id);
    });

    // limitType
    arrayUpsert(facetedSearchResult.limitType, taskData.limitType || "-", searchResult.id);

    // classification
    arrayUpsert(
      facetedSearchResult.classification,
      taskData.classification || "-",
      searchResult.id
    );

    /**
     * chapterATA
     * On legacy publication the taskData id is like: 63/11/00/000/000/013.
     * On S1000D publication the taskData id is like: H160-64-21-00-000-0B2-080.
     */
    let chapterAtaKey = taskData.id.split("/")[0];
    // All ATA chapters are on 2 digits.
    if (chapterAtaKey.length > 2) {
      chapterAtaKey = chapterAtaKey.split("-")[1];
    }
    arrayUpsert(facetedSearchResult.chapterATA, chapterAtaKey, searchResult.id);

    const revStatus = taskData.status ? taskData.status : revisionKey;
    arrayUpsert(facetedSearchResult.revision, revStatus, searchResult.id);

    // interval
    this._populateFacetWithInterval(taskData, searchResult, facetedSearchResult);

    // interval Type
    arrayUpsert(
      facetedSearchResult.intervalType,
      taskData.interval[0]?.limitTypeValue || "-",
      searchResult.id
    );
  }

  /**
   * Populates the facet with parts (from the search result).
   *
   * @param searchResult the result of the search.
   * @param facetedSearchResult the faceted result to populate with the parts
   * of the search.
   * @param revisionKey the key of the revision.
   */
  private populateFacetWithPart(
    searchResult: SearchData,
    facetedSearchResult: FacetedSearchResult,
    revisionKey: string
  ) {
    const partData = IpcFormatter.unserialize(searchResult.part);
    const revStatus = partData.status || revisionKey;
    arrayUpsert(facetedSearchResult.revision, revStatus, searchResult.id);
  }

  /**
   * When we populate the facets with tasks, but we have a single task.
   *
   * @param taskData the MSM task.
   * @param facetedSearchResult the faceted result to populate with the task data.
   * @param searchResult the result of the search.
   */
  private _populateFacetWithPartWithOnlyOneTask(
    taskData: TaskNamespace.MsmTask,
    facetedSearchResult: FacetedSearchResult,
    searchResult: SearchResult
  ) {
    let chapterAtaKey = taskData.id.split("/")[0];
    // All ATA chapters are on 2 digits.
    if (chapterAtaKey.length > 2) {
      chapterAtaKey = chapterAtaKey.split("-")[1];
    }
    arrayUpsert(facetedSearchResult.chapterATA, chapterAtaKey, searchResult.id);
    arrayUpsert(facetedSearchResult.revision, searchResult.revision, searchResult.id);

    arrayUpsert(facetedSearchResult.climat, "-", searchResult.id);

    const notAvailable = "Not available";
    arrayUpsert(facetedSearchResult.limitType, notAvailable, searchResult.id);
    arrayUpsert(facetedSearchResult.interval, notAvailable, searchResult.id);
    arrayUpsert(facetedSearchResult.intervalType, notAvailable, searchResult.id);
  }

  /**
   * Populates the facet with interval (from the data of the task).
   *
   * @param taskData the MSM task.
   * @param facetedSearchResult the faceted result to populate with the task data.
   * @param searchResult the result of the search.
   */
  private _populateFacetWithInterval(
    taskData: TaskNamespace.MsmTask,
    searchResult: SearchResult,
    facetedSearchResult: FacetedSearchResult
  ): void {
    for (const item of taskData.interval) {
      if (item.val && item.unit !== "ED") {
        const itemValue = `${item.val} ${item.unit}`;
        arrayUpsert(facetedSearchResult.interval, itemValue, searchResult.id);
      } else if (!item.operator && !item.unit) {
        arrayUpsert(facetedSearchResult.interval, "None", searchResult.id);
      } else {
        arrayUpsert(facetedSearchResult.interval, item.unit, searchResult.id);
      }
    }
  }
}
