import { Injector } from "@angular/core";
import { DbSchema } from "libs/transfert/model/pubSchema";
import { CoreCaller } from "@viewer/core/pouchdb/core/coreCaller";

/**
 * Represent Xml database (DMC and XSL)
 *
 * @export
 * @class XmlDBs
 * @extends {OrionDBs}
 */
export class SearchCaller extends CoreCaller {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(
    processor: any,
    protected injector: Injector,
    dbSchema: DbSchema
  ) {
    super(dbSchema, processor, injector);
  }

  public getBaseStructure() {
    return this.getAttachmentsBlobUrlList({
      keys: ["index", "map"]
    });
  }

  getAttachmentsBlobUrlList(param) {
    return this.callFunction("getAttachmentsBlobUrlList", param);
  }

  public getFieldForLetters(listKey: string[]) {
    return this.getAttachmentsBlobUrlList({
      keys: listKey
    });
  }
}
