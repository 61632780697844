import { Parent } from "@orion2/models/tocitem.models";

export interface SearchResult {
  applicabilityMD5: string;
  dmc: string;
  id: number;
  manual: string;
  reference: string;
  revision: string;
  score: number;
  shortTitle: string;
  task?: Array<string>;
  part?: Array<string>;
  from?: string;
  versions: Array<string>;
  parents: Array<Parent>;
  date?: Date | string; // only used for superseded object and indexed toc items
  // score: number; // not used right now // in js all number are floating
  type?: string; // Indexed toc item only
  minRevision?: string; // Indexed toc item only
  serialNo?: string[];
  attachedDMC?: string; // Indexed toc item only
  attachedPreprint?: boolean;
  issueInfo?: string;
}

export interface SearchData {
  applicabilityMD5: string;
  dmc: string;
  manual: string;
  reference: string;
  revision: string;
  shortTitle: string;
  id: number; // used for facet
  versions: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  task?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  part?: any;
  score?: number;
  from?: string;
  date?: string | Date;
  type?: string;
  minRevision?: string;
}

export interface SearchResponse {
  search: SearchResult[];
  facet: FacetedSearchResult;
  input: string;
}

export class FacetedSearchResult {
  manual = new Map<string, number[]>();
  types = new Map<string, number[]>();
  chapter = new Map<string, number[]>();
  section = new Map<string, number[]>();
  revision = new Map<string, number[]>();
  chapterATA? = new Map<string, number[]>();
  versions? = new Map<string, number[]>();
  limitType? = new Map<string, number[]>();
  intervalType? = new Map<string, number[]>();
  interval? = new Map<string, number[]>();
  inspection? = new Map<string, number[]>();
  climat? = new Map<string, number[]>();
  superseded? = new Map<string, number[]>();
  document? = new Map<string, number[]>();
  parts? = new Map<string, number[]>();
  classification? = new Map<string, number[]>();
}
