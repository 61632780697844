import { Store } from "@viewer/core";
import { Component, Input, OnInit, ChangeDetectorRef, OnDestroy } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSidenav } from "@angular/material/sidenav";
import { SettingsFormComponent } from "@viewer/settings-form/settings-form.component";
import { RoleService } from "@viewer/core/role/role.service";
import { Observable, shareReplay, Subscription } from "rxjs";
import { PrintService } from "@viewer/core/print/print.service";
import { SearchProductsService } from "@viewer/home/search-products/service/search-products.service";
import { DegradedStateService } from "libs/http/degraded-state/degraded-state.service";

@Component({
  standalone: false,
  selector: "o-settings-home",
  templateUrl: "./settings-home.component.html",
  styleUrls: ["./settings-home.component.scss"]
})
export class SettingsHomeComponent implements OnInit, OnDestroy {
  @Input() settingsSidenav: MatSidenav;
  public srdAvailable: boolean;
  private subscriptions = new Subscription();

  constructor(
    public dialog: MatDialog,
    public roleService: RoleService,
    public store: Store,
    private cd: ChangeDetectorRef,
    private printService: PrintService,
    private searchProductsService: SearchProductsService,
    private degradedStateHandler: DegradedStateService
  ) {}

  public get isDegradedState(): Observable<boolean> {
    return this.degradedStateHandler.degradedState.pipe(shareReplay());
  }

  ngOnInit() {
    this.subscriptions.add(
      this.settingsSidenav.openedStart.subscribe(() => {
        this.srdAvailable = !!this.searchProductsService.selectedFilters?.aircraftTechnicalName;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public openSettings(): void {
    this.dialog.open(SettingsFormComponent, {
      panelClass: "settings-dialog-container"
    });
  }

  public print(): void {
    this.close().then(() => {
      this.printService.printFinal();
    });
  }

  public close(): Promise<string> {
    return this.settingsSidenav.close();
  }

  public login(): Promise<boolean> {
    return this.roleService
      .login()
      .then(() => {
        this.cd.detectChanges();
        return true;
      })
      .catch(error => {
        console.error("Error login : ", error);
        return false;
      });
  }

  public logout(): Promise<boolean> {
    return this.roleService
      .logout()
      .then(() => {
        this.cd.detectChanges();
        return true;
      })
      .catch(error => {
        console.error("Error logout : ", error);
        return false;
      });
  }
}
