import { Pipe, PipeTransform } from "@angular/core";
import { getDisplayedRevision } from "@orion2/utils/front.utils";
import { PubDoc } from "libs/models/couch.models";

interface ProductRevisions {
  lastRev: PubDoc;
  oldRevs?: PubDoc[];
}

@Pipe({ standalone: false, name: "groupProducts" })
export class GroupProductsPipe implements PipeTransform {
  public transform(products: PubDoc[] = []): ProductRevisions[] {
    const revisionsMap = new Map<string, ProductRevisions>();

    products.forEach((product: PubDoc) => {
      if (revisionsMap.has(product.occurrenceCode)) {
        const lastRev = revisionsMap.get(product.occurrenceCode).lastRev;
        if (getDisplayedRevision(lastRev).localeCompare(getDisplayedRevision(product)) === -1) {
          revisionsMap.get(product.occurrenceCode).lastRev = product;
          revisionsMap.get(product.occurrenceCode).oldRevs.push(lastRev);
        } else {
          revisionsMap.get(product.occurrenceCode).oldRevs.push(product);
        }
      } else {
        revisionsMap.set(product.occurrenceCode, { lastRev: product, oldRevs: [] });
      }
    });

    revisionsMap.forEach((productRev: ProductRevisions) => {
      productRev.oldRevs.sort((productA: PubDoc, productB: PubDoc) => {
        return getDisplayedRevision(productB).localeCompare(getDisplayedRevision(productA));
      });
    });

    return Array.from(revisionsMap.values()).sort(
      (productRevA: ProductRevisions, productRevB: ProductRevisions) =>
        productRevA.lastRev.verbatimText.localeCompare(productRevB.lastRev.verbatimText)
    );
  }
}
