import xpath from "xpath";

import { Util, ARRAY_SEPARATOR, OBJECT_SEPARATOR } from "@orion2/utils/datamodule.utils";
import { TaskNamespace } from "@orion2/msm-formatter/types/MsmTask";

export class Remark {
  public static extractS1000D(xmlNode: Node, model: string): string {
    const data: string[] = [];
    let changed = false;
    let tigerRemarks: Array<xpath.SelectedValue> = [];
    let otherRemarks: Array<xpath.SelectedValue> = [];

    // SPEC: only Tiger should display timeLimit[5] as remarks,
    // all S1000D should display remarks/simplePara (it's remarks for Tiger, it's NRD for other S1000D)
    if (model === "JP") {
      tigerRemarks = xpath.select("timeLimit[5]/remarks/simplePara", xmlNode);
    }
    otherRemarks = xpath.select("remarks/simplePara", xmlNode);

    [...tigerRemarks, ...otherRemarks].forEach((remark: Node) => {
      changed =
        xpath.select(
          "string(ancestor::timeLimit/@changeMark | ../@changeMark | ./@changeMark)",
          remark
        ).length > 0;
      const simplePara = Util.cleanXml(xpath.select("string(.)", remark));
      data.push(simplePara + OBJECT_SEPARATOR + changed);
    });

    return data.join(ARRAY_SEPARATOR);
  }

  public static unserialize(data: string): TaskNamespace.Remark[] {
    return data
      ?.split(ARRAY_SEPARATOR)
      .map((description: string) => description.split(OBJECT_SEPARATOR))
      .filter((val: string[]) => val[0] !== "")
      .map((val: string[]) => ({
        value: val[0],
        changed: val[1] === "true"
      }));
  }
}
