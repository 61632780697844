import { ActionEnum, DBConnexionType } from "@viewer/core/pouchdb/types";
import { LocalStorageBackend } from "@openid/appauth/built/storage";
import { v4 as uuidv4 } from "uuid";
import { Injector } from "@angular/core";
import { DbSchema } from "libs/transfert/model/pubSchema";
import { CoreCaller } from "@viewer/core/pouchdb/core/coreCaller";

/**
 * Represent Statistics dbs
 */
export class StatsCaller extends CoreCaller {
  private storage = new LocalStorageBackend();

  constructor(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    processor: any,
    protected injector: Injector,
    dbSchema: DbSchema
  ) {
    super(dbSchema, processor, injector);
  }

  async logAction(action: ActionEnum, pubId: string, pubOccCode: string) {
    const today = Date.now();

    return this.getKey().then(key => {
      const args = {
        date: today,
        state: action,
        occCode: pubOccCode,
        deviceId: key,
        pubId
      };
      return this.doOnInstance(DBConnexionType.REMOTE, "post", args);
    });
  }

  async getKey() {
    return this.storage.getItem("stat_key").then(async key => {
      if (key === null || key === undefined) {
        const newKey = uuidv4();
        await this.storage.setItem("stat_key", newKey);
        return newKey;
      }
      return key;
    });
  }
}
