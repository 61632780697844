import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from "@angular/router";
import { ConfService } from "@viewer/core";

@Injectable()
export class LastConsultedGuard {
  constructor(
    private router: Router,
    private confService: ConfService
  ) {}

  canActivate(_next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): boolean | UrlTree {
    if (this.confService.conf.hasBP2 && JSON.stringify(_next.queryParams) === "{}") {
      return this.router.parseUrl(_state.url + "?lastConsulted=true");
    }

    return true;
  }
}
