import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";

@Component({
  standalone: true,
  selector: "o-revision-mark",
  templateUrl: "./revision-mark.component.html",
  styleUrls: ["./revision-mark.component.scss"],
  imports: [CommonModule, MatIconModule]
})
export class RevisionMarkComponent {
  @Input() dataRev: string;
}
