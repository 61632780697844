import { Component, OnInit } from "@angular/core";
import { SearchContractsService } from "@viewer/home/search-contracts/service/search-contracts.service";
import { Basepub } from "@orion2/models/basepub.models";
import { ContractsService } from "@viewer/home/service/contracts.service";
import { ActivatedRoute, Params } from "@angular/router";
import { Store } from "@viewer/core";

@Component({
  standalone: false,
  selector: "o-contract-list",
  templateUrl: "./contract-list.component.html",
  styleUrls: ["./contract-list.component.scss"]
})
export class ContractListComponent implements OnInit {
  public isRecentlyConsulted = false;
  public contracts: Basepub.Contract[];

  constructor(
    public store: Store,
    private route: ActivatedRoute,
    private contractsService: ContractsService,
    private searchContractsService: SearchContractsService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params: Params) => {
      this.isRecentlyConsulted = params.lastConsulted === "true";
      this.searchContractsService.selectedFilters = this.isRecentlyConsulted ? {} : params;
      this.getContracts(params);
    });
  }

  public removeChip(category: string): void {
    this.searchContractsService.removeFilter(category);
  }

  private getContracts(params?: Params): Promise<void> {
    this.contracts = undefined; // Reset contracts during process to display spinner
    return this.contractsService.getContracts(params).then((contracts: Basepub.Contract[]) => {
      this.contracts = contracts;
    });
  }
}
