import { Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { Store } from "@viewer/core/state/store";
import { reaction } from "mobx";

@Injectable()
export class TitleService {
  public headerTitle: string;
  public headerSubtitle: string;
  private oldTitle: string;

  constructor(
    private store: Store,
    private title: Title,
    private translate: TranslateService
  ) {
    reaction(
      () => this.store.inPrint,
      () => {
        if (this.store.inPrint) {
          this.oldTitle = this.title.getTitle();
          this.title.setTitle(
            `${this.store.currentDMC}__${this.store.duObject.issueNumber}__${this.store.duObject.inWork}`
          );
        } else if (this.oldTitle) {
          this.title.setTitle(this.oldTitle);
        }
      }
    );
  }

  public setHeaderTitle(title = "", subtitle = ""): void {
    this.headerTitle = title;
    this.headerSubtitle = subtitle;
  }

  /**
   * Use title or current dmc
   *
   * @param title
   * @param subtitle
   */
  public setPageTitle(title?: string, subtitle?: string): void {
    this.setHeaderTitle(title, subtitle);
    const verbatimText = this.store.pubInfo?.verbatimText || "";
    const revision = this.store.pubInfo?.revision || "";
    this.title.setTitle(
      ` ${verbatimText} ${revision} ${this.translate.instant(title)}  ${subtitle}`
    );
  }

  /**
   * Set title from DU with publication's title, revision and DU shorttitle and reference
   *
   * @param duMetadata
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public setPageTitleFromDU(duMetadata: any): void {
    const verbatimText = this.store.pubInfo?.verbatimText || "";
    const revision = this.store.pubInfo?.revision || "";
    this.setHeaderTitle(duMetadata?.shortTitle || duMetadata?.title, duMetadata?.reference);
    this.title.setTitle(
      duMetadata
        ? `${verbatimText} ${revision} > ${this.headerSubtitle} - ${this.headerTitle}`
        : "Orion 2"
    );
  }

  public setPageTitleFromCache(): void {
    const publication = this.store.pubInfo;
    this.setHeaderTitle(this.store.duObject.duTitle, this.store.duObject.duReference);
    this.title.setTitle(
      `${publication.verbatimText} ${publication.revision} ${this.headerTitle}  ${this.headerSubtitle}`
    );
  }
}
