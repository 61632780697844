/* eslint-disable @typescript-eslint/no-explicit-any */
import { CoreCaller } from "@viewer/core/pouchdb/core/coreCaller";
import { DBConnexionType } from "@viewer/core/pouchdb/types";
import { Injector } from "@angular/core";
import { DbSchema } from "libs/transfert/model/pubSchema";
import { Design } from "libs/design/design";
import { PubDoc, DbItem } from "@orion2/models/couch.models";
import { DEFAULT_ATTACHMENT_SIZE, createAttachement } from "@orion2/utils/attachments.utils";
import { ElectronService } from "tools/electron/viewer/helpers/electron.service";

export interface PubMeta {
  id: string;
  key: string;
  value: string;
}

/**
 * Represent Pub database (containing revision date, ...)
 *
 * @extends {CoreCaller}
 */
export class PubCaller extends CoreCaller {
  protected occurrenceCodeDesignDoc = Design.buildOccurrenceCodeDesign();

  private cachedProm = new Map<string, Promise<unknown>>();
  private cacheDate: Date;
  // Promise created when we want to ensure that the 'occurreceCode' index has been built. Resolve true when it's done
  private indexBuiltPromise: Promise<boolean>;
  private electronService: ElectronService;

  constructor(
    processor: any,
    protected injector: Injector,
    dbSchema: DbSchema
  ) {
    super(dbSchema, processor, injector);

    this.electronService = this.injector.get(ElectronService);
  }

  public doOnInstanceWithCache(type: DBConnexionType, query: string, args: any): Promise<any> {
    const argString = JSON.stringify([type, query, args]);

    return (
      this.getCachedProm(argString) ||
      this.setCachedProm(argString, this.doOnInstance(type, query, args))
    );
  }

  /**
   * Returns the list of pubs meta, ex: {"id":"bk117-d2_en-en__012.00.01","key":"133064","value":"012.00.01"}
   * sorted by occCode (key) and revision (value)
   *
   * @param occCodes The list of occCodes to look for. If occCodes = ["all"], fetch all publications.
   * @param target The database connexion type (local or remote).
   */
  public getPubsMeta(occCodes: string[], target = DBConnexionType.REMOTE): Promise<PubMeta[]> {
    const queryParam = {
      group: false,
      reduce: false,
      descending: true
    };

    // SPEC: We need to get all the publications from database when offline or in dev mode.
    if (occCodes.length === 1 && occCodes[0] === "all") {
      queryParam["start_key"] = "a";
      queryParam["end_key"] = "";
    } else {
      queryParam["keys"] = occCodes;
    }

    return this.ngZone.runOutsideAngular(() =>
      this.doOnInstanceWithCache(target, "query", ["occurrenceCode", queryParam])
        .then(
          (response: { total_rows: number; offset: number; rows: PubMeta[] }) =>
            // Although results are sorted (see descending:true in params), the sort is on _id which means that
            // when we have publications named without rev in the name the order can be incorrect
            // for exemple in pprod we have
            // {"id":"bk117-d2_en-en__012.00.01","key":"133064","value":"012.00.01"},
            // {"id":"bk117-d2_en-en__012.00","key":"133064","value":"012.00"},
            // {"id":"bk117-d2_014-00_en-en__014.00.01","key":"133064","value":"014.00.01"},
            // {"id":"bk117-d2_014-00_en-en__014.00","key":"133064","value":"014.00"},
            // {"id":"bk117-d2_012-00_en-en__012.00","key":"133064","value":"012.00"},
            // So we force order by occCode (key) en revision (value)
            response?.rows.sort(
              (pubA: PubMeta, pubB: PubMeta) =>
                -`${pubA.key}${pubA.value}`.localeCompare(`${pubB.key}${pubB.value}`)
            ) || []
        )
        .catch(err => {
          console.error("Unable to find CouchDB view 'occurrenceCode' ! \n", err);
          return [];
        })
    );
  }

  /**
   * Get 'pub' object from database. Provide informations like revision, import date...
   *
   * @param packageId
   * @returns
   */
  public getPub(packageId: string, target = DBConnexionType.LOCAL): Promise<PubDoc> {
    // Here we don't use this.get to avoid the replication strategy which is:
    // - if a document is not found locally then check remotly
    // - if the document is found remotly then replicate it.
    // we use a then instead of catch because doOnInstance already catch errors

    const remoteProm = () =>
      this.doOnInstanceWithCache(DBConnexionType.REMOTE, "get", packageId).catch(err => {
        console.error("GET REMOTE PUB", err);
      });

    const localProm = () =>
      this.doOnInstance(DBConnexionType.LOCAL, "get", packageId)
        .then(localPub => {
          // if document not found locally then try remote.
          if (localPub === null || localPub === undefined) {
            return remoteProm();
          }
          return localPub;
        })
        .catch(err => console.error("GET LOCAL PUB", err));

    return target === DBConnexionType.REMOTE ? remoteProm() : localProm();
  }

  /**
   * Get 'pubs' object from database for given ids. Provide informations like revision, import date...
   *
   * The algorithm differs from getPub. Here on remote we first look for pubs in localDB then we look for pubs not found
   * in remoteDB. Ultimatly we merge the results.
   *
   * @param packageId
   * @returns
   */
  public getPubs(packageIds: string[], target = DBConnexionType.LOCAL): Promise<PubDoc[]> {
    // Here we don't use this.do to avoid the replication strategy which is:
    // - if a document is not found locally then check remotly
    // - if the document is found remotly then replicate it.
    // we use a then instead of catch because doOnInstance already catch errors
    const params = { keys: packageIds, include_docs: true };

    // WE don't want to cache local promises because results might change due to downloads.
    // UNLESS we reset cache for local promises after downloads.
    const localProm = _params =>
      this.doOnInstance(DBConnexionType.LOCAL, "allDocs", _params).then(docs => docs.rows);

    const remoteProm = _params =>
      this.doOnInstance(DBConnexionType.LOCAL, "allDocs", _params)
        .then(pubDocs => {
          const foundNotFoundPubDocs = this.pubsFoundNotFoundLocally(pubDocs);
          // if document not found locally then try remote.
          if (foundNotFoundPubDocs.notFound.length > 0) {
            const __params = _params;
            _params.keys = foundNotFoundPubDocs.notFound.map(pub => pub.key);
            return this.doOnInstanceWithCache(DBConnexionType.REMOTE, "allDocs", __params).then(
              remotePubs => [...foundNotFoundPubDocs.found, ...remotePubs.rows]
            );
          }
          return pubDocs.rows;
        })
        .catch(err => console.error("GET LOCAL PUB", err));

    return target === DBConnexionType.REMOTE ? remoteProm(params) : localProm(params);
  }

  /**
   * Save 'pub' object in pubs DB and modify status to 'offline'
   *
   * @param pub
   */
  public saveOfflinePublication(pub: PubDoc): Promise<DbItem> {
    const jacketProm = pub._attachments ? this.getJacket(pub) : Promise.resolve(undefined);
    return jacketProm.then((jacket: string | Blob) => {
      if (jacket) {
        createAttachement(pub, jacket, DEFAULT_ATTACHMENT_SIZE);
      }

      // When saving attachement blob for electron pouchdb trigger an error that's why we convert it to base64 for electron
      const fixAttachProm = this.electronService.isElectron()
        ? Promise.all(
            Object.keys(pub._attachments).map((chunkName: string) =>
              this.electronService
                .convertAttachment((pub._attachments[chunkName] as any).data)
                .then((attachementObj: PouchDB.Core.Attachment) => {
                  pub._attachments[chunkName] = attachementObj;
                })
            )
          )
        : Promise.resolve();

      return fixAttachProm.then(() => {
        const doc = { ...pub, _rev: undefined, status: "offline" };
        return this.setLocal(doc);
      });
    });
  }

  /**
   * Create transfert document to store in local
   *
   * @returns
   * @memberof PubCaller
   */
  public createLocalTransfert(): Promise<DbItem> {
    const doc = {
      _id: "_local/transfert",
      date: Date(),
      transfert: true
    };
    return this.setLocal(doc);
  }

  /**
   * Get offline pubs from DB
   */
  public getOfflinePubs(): Promise<PubDoc[]> {
    const queryParam = {
      include_docs: true,
      start_key: "a"
    };

    return this.doOnInstance(DBConnexionType.LOCAL, "allDocs", queryParam)
      .then(resp => resp.rows)
      .catch(err => {
        console.error(err);
        return [];
      });
  }

  /**
   * Ensure the occurrenceCode _design exists locally and is up-to-date
   */
  public ensureIndexIsBuilt(): Promise<boolean> {
    // No need to run it several times if the index is already verified so we store the resolver as a promise
    if (!this.indexBuiltPromise) {
      this.indexBuiltPromise = new Promise((_resolve, _reject) => {
        this.doOnInstance(DBConnexionType.LOCAL, "get", ["_design/occurrenceCode"])
          .then(index => {
            if (
              !index ||
              !index.lastUpdate ||
              new Date(this.occurrenceCodeDesignDoc.lastUpdate).getTime() >
                new Date(index.lastUpdate).getTime()
            ) {
              if (!index) {
                index = this.occurrenceCodeDesignDoc;
              } else {
                index = { ...this.occurrenceCodeDesignDoc, _rev: index._rev };
              }
              this.setLocal(index)
                .then(() => {
                  this.refreshIndex()
                    .then(() => {
                      _resolve(true);
                    })
                    .catch(err => {
                      console.error("Could not refresh 'occurrenceCode' index ", err);
                      this.indexBuiltPromise = undefined;
                      _reject();
                    });
                })
                .catch(err => {
                  console.error("Could not put updated 'occurrenceCode' index ", err);
                  this.indexBuiltPromise = undefined;
                  _reject();
                });
            } else {
              _resolve(true);
            }
          })
          .catch(err => {
            console.error("Could resolve 'occurrenceCode' index ", err);
            this.indexBuiltPromise = undefined;
            _reject();
          });
      });
    }
    return this.indexBuiltPromise;
  }

  public refreshIndex(dbType = DBConnexionType.LOCAL, index = "occurrenceCode") {
    return this.doOnInstance(dbType, "query", [index, { stale: "update_after" }]);
  }

  /**
   * Resolve the identifiers and revision from an pub identifier (occCode, pubId, packageId, ...)
   *
   * @param pubIdentifier - the identifier, can be a pubId or an occurrenceCode
   * @param revision - the required revision (if badly spelled or empty or 'latest' get the latest one)
   * @param params  - (Optional) The additional params
   * @param searchForLastTechRev - (Optional) Search for the latest technical revision for the given revision
   * @param resolveNonPublished - (Optional) Search for the non published pubs
   *
   * @return A Promise containing and object the packageId, the alias of it and the revision
   */
  public resolvePubId(
    pubIdentifier: string,
    revision: string,
    searchForLastTechRev = false,
    resolveNonPublished = false,
    params = {}
  ): Promise<{
    aliasPackageId: string;
    packageId: string;
    revision: string;
  }> {
    return this.ensureIndexIsBuilt().then(() => {
      let queryParams = {};
      if (
        searchForLastTechRev ||
        revision === undefined ||
        revision === null ||
        !revision.match(/^(\d{3}(\.\d{2}(\.\d{2})?))$/m)
      ) {
        // Defines if we need to add a prefix to the 'revision' part of the couchDB query to specify a specific major revision (to retrieve
        //  the latest technical revision of this major revision) or no (to retrieve the latest revision available)
        const revisionPrefix = searchForLastTechRev && revision ? revision.substr(0, 6) : "";

        queryParams = {
          ...params,
          reduce: false,
          startkey: resolveNonPublished
            ? ["_nonPublished", pubIdentifier, `${revisionPrefix}z`]
            : [pubIdentifier, `${revisionPrefix}z`],
          endkey: resolveNonPublished
            ? ["_nonPublished", pubIdentifier, revisionPrefix]
            : [pubIdentifier, revisionPrefix],
          descending: true,
          limit: 1
        };
      } else {
        queryParams = {
          ...params,
          reduce: false,
          key: resolveNonPublished
            ? ["_nonPublished", pubIdentifier, revision]
            : [pubIdentifier, revision]
        };
      }

      // Here we don't use "do()" to avoid the replication as we only do a query there is nothing to replicate.
      // So first we try to resolve the pub identifiers using the local db and check the remoteDb only if nothing has been found.
      return this.doOnInstance(DBConnexionType.LOCAL, "query", ["occurrenceCode", queryParams])
        .then(resp => {
          if (
            resp === null ||
            resp === undefined ||
            resp.rows === undefined ||
            resp.rows.length === 0
          ) {
            return this.doOnInstance(DBConnexionType.REMOTE, "query", [
              "occurrenceCode",
              queryParams
            ]).then(res => {
              if (
                res === null ||
                res === undefined ||
                res.rows === undefined ||
                res.rows.length === 0
              ) {
                return Promise.resolve(undefined);
              }
              return res.rows[0].value;
            });
          }
          return resp.rows[0].value;
        })
        .catch(err => {
          console.error(`Could not resolve packageId of  ${pubIdentifier} ${revision}`, err);
          return Promise.resolve(undefined);
        });
    });
  }

  public deleteOfflinePub(pubId: string, pubRev: string): Promise<boolean> {
    return this.deleteLocal(this.createDbOfflineId(pubId, pubRev));
  }

  public getJacket(pub: PubDoc): Promise<Blob | string> {
    const targetDb = pub.status === "offline" ? DBConnexionType.LOCAL : undefined;
    return pub.jacket
      ? Promise.resolve(pub.jacket)
      : this.callFunction("getAttachmentsBlob", [pub._id], targetDb);
  }

  private getCachedProm(key: string): Promise<any> {
    const now = new Date();
    if (this.cacheDate && now.getTime() - this.cacheDate.getTime() < 24 * 3600 * 1000) {
      return this.cachedProm.get(key);
    }

    // if > 24h we reset cache
    this.cachedProm.clear();
    this.cacheDate = undefined;
  }

  private setCachedProm(key: string, prom: Promise<any>): Promise<any> {
    if (!this.cacheDate) {
      this.cacheDate = new Date();
    }
    this.cachedProm.set(key, prom);
    return prom
      .then(res => {
        // We don't want to cache results if results are undefined of empty (array or map)
        // because this might mean an error occured (network ?) and we want to be able to recover
        // from if.
        if (res && (res.length === 0 || res.size === 0)) {
          this.cachedProm.delete(key);
        }
        return res;
      })
      .catch(err => {
        // In case of error, we want to be able to try again next time
        // so we reset the cache and propagate the error.
        this.cachedProm.delete(key);
        throw err;
      });
  }

  private pubsFoundNotFoundLocally(pubDocs: any[any]): { found: any[]; notFound: any[] } {
    const results = { found: [], notFound: [] };
    if (pubDocs && pubDocs.rows) {
      pubDocs.rows.forEach(row =>
        typeof row.error !== "undefined" || (row.value && row.value.deleted)
          ? results.notFound.push(row)
          : results.found.push(row)
      );
    }
    return results;
  }

  /**
   * @param pubId
   * @param pubRev
   */
  private createDbOfflineId(pubId: string, pubRev: string) {
    return pubId + "__" + pubRev;
  }
}
