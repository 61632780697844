import { Pipe, PipeTransform } from "@angular/core";
import { Basepub } from "@orion2/models/basepub.models";
import { PubDoc } from "libs/models/couch.models";

@Pipe({
  standalone: false,
  name: "filterInfos"
})
export class FilterInfosPipe implements PipeTransform {
  public transform(items: Basepub.Contract[], searchText: string): Basepub.Contract[];
  public transform(items: PubDoc[], searchText: string): PubDoc[];
  public transform(
    items: (Basepub.Contract | PubDoc)[],
    searchText: string
  ): (Basepub.Contract | PubDoc)[] {
    // We immediately return if there is no searchText to avoid an unnecessary loop on items
    if (!searchText) {
      return items;
    }

    searchText = searchText.toLowerCase();
    return items?.filter(item => item.infos.toLowerCase().includes(searchText));
  }
}
