import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { SearchService } from "@viewer/core/search/search.service";
import { when } from "mobx";
import { Store } from "@viewer/core/state/store";
import { SearchResponse, SearchResult } from "@viewer/core/search/searchModel";
import { MsmFormatter } from "@orion2/msm-formatter/formatter";
import { MessageService } from "@orion2/message-service/message.service";
import { TranslateService } from "@ngx-translate/core";
import { getParam } from "@orion2/utils/functions.utils";
import { HistoryElem } from "@orion2/models/tocitem.models";
import { HistoricService } from "@viewer/core/toc-items/historic.service";
import { IpcFormatter } from "@orion2/ipc-formatter/formatter";

const SHE_NATO_CODE = "F0228";

@Component({
  standalone: false,
  selector: "o-redirect",
  templateUrl: "./redirect.component.html"
})
export class RedirectComponent implements OnInit {
  private key: string;
  private redirectRoute: string;
  private filterManual: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private searchService: SearchService,
    private messageService: MessageService,
    private translate: TranslateService,
    public store: Store,
    private historicService: HistoricService
  ) {}

  ngOnInit() {
    this.key = this.route.snapshot.paramMap.get("key");
    // SPEC: redirectRoute can be "shortDMC", "dmc", "first" or "she"
    this.redirectRoute = this.route.snapshot.url[0].path;

    this.filterManual = this.route.snapshot.queryParamMap.get("manual");

    // Have multiple search input
    this.key = decodeURI(this.key);

    // setting highlightKeyword is important for /first route
    // without that, this work first time and doesn't work after
    this.store.highlightKeyword = this.key;

    if (this.redirectRoute === "she" || this.redirectRoute === "external") {
      const natoCode =
        this.redirectRoute === "she" ? SHE_NATO_CODE : this.route.snapshot.paramMap.get("external");
      const dmMatrice = getParam(natoCode, this.store.pubInfo?.externalDoc)?.dmMatrice;
      if (!dmMatrice) {
        this.messageService.error(
          this.translate.instant("redirect.no.matrice", { natoCode }),
          undefined,
          4000
        );
      }

      return this.redirectFulltextAndScroll(dmMatrice);
    } else {
      if (!this.store.offlineSearchReady) {
        when(
          () => this.store.offlineSearchReady,
          () => this.search()
        );
      } else {
        this.search();
      }
    }
  }

  private search(): void {
    const promises: Promise<SearchResponse>[] = [];
    if (this.redirectRoute === "dmc" || this.redirectRoute === "redirect") {
      promises.push(this.searchService.search(this.key, true, "dmc"));
    }
    if (this.redirectRoute === "shortDMC" || this.redirectRoute === "redirect") {
      promises.push(this.searchService.search(this.key, true, "reference"));
    }
    if (
      this.redirectRoute !== "dmc" &&
      this.redirectRoute !== "shortDMC" &&
      this.redirectRoute !== "redirect"
    ) {
      promises.push(this.searchService.search(this.key, true));
    }
    Promise.all(promises).then((searchResults: SearchResponse[]) => {
      for (const searchResult of searchResults) {
        searchResult.search = this.filterSearchResults(searchResult.search);

        // If searchInput is undefined then we don't want to take the result. (equivalent to "dmc" input)
        if (searchResult.search.length > 0 && searchResult.input === this.key) {
          // We the first is a task we should redirect to the parent DM
          const first = searchResult.search[0];

          // SPEC: Only redirect to DMC if route first or just one result
          if (this.redirectRoute === "first" || searchResult.search.length === 1) {
            return this.openDu(this.selectDMCToRedirect(first));
          }
        }
      }

      return this.redirectToSearch();
    });
  }

  private redirectFulltextAndScroll(dm: string): Promise<boolean> {
    // If no DM select we choose to redirect to the loap
    return this.router.navigate(["du", dm ? dm : "loap"], {
      queryParams: {
        scroll: this.key
      },
      relativeTo: this.route.parent,
      skipLocationChange: true
    });
  }

  private selectDMCToRedirect(first: SearchResult): string {
    if (first.dmc.startsWith("task__")) {
      const taskData = MsmFormatter.unserialize(first.task);
      return taskData.parent;
    } else if (first.dmc.startsWith("part__")) {
      const partData = IpcFormatter.unserialize(first.part);
      return partData.parent;
    } else {
      return first.dmc;
    }
  }

  private filterSearchResults(searchResults: SearchResult[]): SearchResult[] {
    if (this.filterManual) {
      return searchResults.filter(
        (searchItem: SearchResult) => searchItem.manual === this.filterManual
      );
    }

    // If no filter is available we returning our results
    return searchResults;
  }

  private openDu(dmc: string): Promise<boolean> {
    const historic = {
      dmc
    } as HistoryElem;
    this.historicService.updateCurrentDMRoot(historic, this.store.currentDMC);
    return this.router.navigate(["du", dmc], {
      relativeTo: this.route.parent,
      skipLocationChange: true
    });
  }

  private redirectToSearch(): Promise<boolean> {
    return this.router.navigate(["search/documents", this.key], {
      relativeTo: this.route.parent,
      skipLocationChange: true
    });
  }
}
