import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  standalone: false,
  selector: "o-extra-doc",
  templateUrl: "./extra-doc.component.html",
  styleUrls: ["./extra-doc.component.scss"]
})
export class ExtraDocComponent implements OnInit {
  docId = "";

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.docId = this.route.snapshot.paramMap.get("pdfId");
  }
}
