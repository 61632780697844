/* eslint-disable @typescript-eslint/no-explicit-any */
import { PouchAllDocsResult, DbItem } from "@orion2/models/couch.models";
import { environment } from "@viewer-env/environment";
import { ConfService } from "@viewer/core/conf/conf.service";
import { Store } from "@viewer/core/state/store";
import { Injector, NgZone } from "@angular/core";
import { DbSchema } from "libs/transfert/model/pubSchema";
import { splitChunk } from "@viewer/shared-module/helper.utils";
import { getMajorRevision } from "@orion2/utils/functions.utils";
import {
  DbParams,
  ProcessorGateway,
  DatabaseConfObject,
  DBConnexionType,
  DbQuery
} from "@viewer/core/pouchdb/types";

/**
 * Single param , multiple param as []
 *
 * @export
 * @class XmlDBs
 * @extends {OrionDBs}
 */
export abstract class CoreCaller {
  public dbType: string;
  protected processor: any;
  protected dbParams: DbParams;
  protected confService: ConfService;
  protected store: Store;
  protected dbSchema: DbSchema;
  protected ngZone: NgZone;

  constructor(
    dbSchema: DbSchema,
    processor: ProcessorGateway,
    protected injector: Injector
  ) {
    this.store = this.injector.get(Store);
    this.confService = this.injector.get(ConfService);
    this.ngZone = this.injector.get(NgZone);

    this.dbSchema = dbSchema;
    this.dbType = dbSchema.type;
    this.processor = processor;

    if (!this.dbParams) {
      const revision = this.store.pubInfo?.revision || "";
      this.dbParams = {
        remote: {},
        local: {},
        isOffline: false,
        isPackagePDF: this.store.pubInfo?.isPackagePDF,
        useAuth: this.confService.useAuth,
        useWorker: environment.useWorker,
        isIncremental: this.dbSchema.incremental,
        exclude3Dmedia: this.store.pubInfo?.exclude3Dmedia,
        revision: getMajorRevision(revision, this.store.pubInfo?.isPackagePDF)
      };
    }

    this.dbParams.isOffline =
      this.store.pubInfo &&
      this.store.pubInfo.status &&
      this.store.pubInfo.status.toLowerCase() === "offline";

    if (!this.dbParams.remote) {
      this.dbParams.remote = {};
    }
  }

  public get dbPrefix() {
    return this.dbSchema.prefix;
  }

  public get dbSuffix() {
    return this.dbSchema.suffix;
  }

  replicate(id: string[]): Promise<void> {
    return this.callFunction("replicationStrategy", { _id: id });
  }

  sync(ids?: string[]): Promise<void> {
    // Slipt ids into chunk for cordova users due to SQLite limits
    if (window.hasOwnProperty("cordova") && ids) {
      const chunkMap = splitChunk(ids);
      return Promise.all(chunkMap.map(chunk => this.callFunction("sync", [chunk]))).then(() => {});
    } else {
      return this.callFunction("sync", [ids]);
    }
  }

  dbInformation() {
    return this.callFunction<any>("dbInformation");
  }

  replicationInProgress() {
    return this.callFunction("replicationInProgress");
  }

  localCount() {
    return this.callFunction<any>("localCount");
  }

  deleteLocal(id: string) {
    return this.callFunction<any>("deleteLocal", [id]);
  }

  public compact(): Promise<PouchDB.Core.Response> {
    return this.callFunction<PouchDB.Core.Response>("compact");
  }

  removeReplicationFlag() {
    return this.callFunction("removeReplicationFlag");
  }

  confObject(): Promise<DatabaseConfObject> {
    return this.callFunction("confObject");
  }

  info() {
    return this.callFunction("info");
  }

  enableCache(): Promise<any> {
    return this.callFunction("enableCache");
  }

  // can be better with multiple argument
  public get<T>(args: string | string[] | unknown = []): Promise<T | T[]> {
    if (Array.isArray(args)) {
      // get() in OrionDb will call allDoc if the args is an array
      return this.callFunction("get", {
        keys: args,
        include_docs: true
      }).then((result: PouchAllDocsResult) => result.rows as T[]);
    } else {
      return this.callFunction<T>("get", args).then((result: PouchAllDocsResult | T) =>
        // SPEC: With the reshape function inside OrionDBs the PouchDB.Core.AllDocsResponce interface
        // doesn't have doc attribute inside rows
        result["rows"] ? (result["rows"] as T[]) : (result as T)
      );
    }
  }

  getAll(): Promise<any> {
    return this.callFunction("getAll");
  }

  public replicateRemote(callbacks = {}, batchSize = 15): Promise<void> {
    return this.callFunction<void>("replicateRemote", [callbacks, batchSize]);
  }

  isReplicate(): Promise<any> {
    return this.callFunction("isReplicate");
  }

  public emptyLocal(): Promise<boolean> {
    return this.callFunction<boolean>("emptyLocal");
  }

  disabledCache(): Promise<any> {
    return this.callFunction("disabledCache");
  }

  cancelReplication(): Promise<any> {
    return this.callFunction("cancelReplication");
  }

  doOnInstance(type: DBConnexionType, query: string, args: any): Promise<any> {
    return this.callFunction("doOnInstance", [type, query, args]);
  }

  do(query: string, args: any[], replicateAction?: Function) {
    const replicateActionString = replicateAction ? replicateAction.toString() : "";

    return this.callFunction("do", [query, args, replicateActionString]);
  }

  public close() {
    return this.callFunction("close");
  }

  public getDbName(): string {
    return this.dbSchema.type;
  }

  public bulk(args: Object[]): Promise<any> {
    return this.callFunction("bulk", [args]);
  }

  public resetIsOffline() {
    // SPEC: When we returning to the store we want to ensure to get remote values if needed
    this.dbParams.isOffline = undefined;
  }

  /**
   * If the user is'nt authenticated force the target to DBConnexionType.LOCAL
   *
   * @protected
   * @param target
   * @returns
   * @memberof CoreCaller
   */
  protected getRealTarget(target: DBConnexionType): DBConnexionType {
    return !this.confService.useAuth || this.store.isLoggedIn ? target : DBConnexionType.LOCAL;
  }

  protected setLocal(doc): Promise<DbItem> {
    return this.callFunction("setLocal", doc);
  }

  protected callFunction<T>(
    functionName: string,
    param?: unknown,
    targetDb?: DBConnexionType
  ): Promise<T> {
    return this.ngZone.runOutsideAngular(() =>
      this.processor.exec(this.createQueryParam(functionName, param, targetDb))
    );
  }

  private createQueryParam(query: string, params?: any, targetDb?: DBConnexionType): DbQuery {
    return {
      query,
      params,
      targetDb,
      dbSchema: this.dbSchema,
      dbParams: this.dbParams
    } as DbQuery;
  }
}
