import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { PubDoc } from "@orion2/models/couch.models";
import { getDisplayedRevision } from "@orion2/utils/front.utils";

@Component({
  standalone: false,
  selector: "o-pub-replication",
  templateUrl: "./pub-replication.component.html",
  styleUrls: ["./pub-replication.component.scss"]
})
export class PubReplicationComponent implements OnInit {
  public pub: PubDoc;
  public displayedRevision: string;

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.activatedRoute.data.subscribe(({ pub }: { pub: PubDoc }) => {
      this.displayedRevision = getDisplayedRevision(pub);
      this.pub = pub;
    });
  }
}
