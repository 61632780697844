/**
 * @export
 * @interface MsmTask
 */
export namespace TaskNamespace {
  export interface MsmTask {
    id: string;
    title: string;
    description: Description[];
    documentation: Documentation[];
    mpn_pn: MpnPn[];
    limitType: string;
    interval: Interval[];
    margin: Margin[];
    versions: string;
    revision: string;
    parent: string;
    // for the moment status is present only on msmtask ATA100
    status?: string;
    preliminary?: PreliminaryRqmts;
    applic?: Applic;
    modelIdentCode: string;
    issueDate: string;
    section: string;
    changed: TaskInfoChanged;
    classification?: string;
    remarks?: Remark[];
  }

  export interface Documentation {
    linkDM: string;
    href: string;
    title: string;
    loc: string[];
    changed: boolean;
    shortDMC: string;
  }

  export interface Description {
    value: string;
    changed: boolean;
  }

  export interface Remark {
    value: string;
    changed: boolean;
  }

  export interface Interval {
    val: string;
    unit: string;
    operator: string;
    sampling: string;
    limitTypeValue: string;
    freqStart: string;
    temporaryLimit: string;
    changed: boolean;
  }

  export interface MpnPn {
    mpn: string;
    pn: string;
    linkDm: string;
    linkTitle: string;
    changed: boolean;
    mfc: string;
  }

  export interface Margin {
    val: string;
    unit: string;
    operator: string;
    changed: boolean;
  }

  export interface PreliminaryRqmts {
    zones: string[];
    changed: boolean;
  }

  export interface Applic {
    climat: string[];
  }

  export interface TaskInfoChanged {
    title: boolean;
    limitType: boolean;
    from: boolean;
    task: boolean;
  }
}
