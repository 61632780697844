import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  ChangeDetectorRef,
  AfterViewInit
} from "@angular/core";
import { MatDrawerToggleResult, MatSidenav } from "@angular/material/sidenav";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@viewer/core";
import { reaction, runInAction } from "mobx";
import { TitleService } from "@viewer/header/title.service";
import { combineLatest, map, Observable, skipWhile, Subscription, take, tap } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { TocMode } from "@viewer/layout/toc-mode.model";
import { SynchroService } from "@viewer/core/synchro/synchro.service";
import { NewsUtilsService } from "@viewer/news/services/news-utils.service";
import { NewsModalComponent } from "@viewer/news/news-modal/news-modal.component";

@Component({
  standalone: false,
  selector: "o-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnDestroy, AfterViewInit {
  @Input() tocNav: MatSidenav;
  @Input() settingsSidenav: MatSidenav;

  displaySearch = false;
  searchInput = "";

  private subscriptions = new Subscription();

  constructor(
    public synchroService: SynchroService,
    private router: Router,
    private route: ActivatedRoute,
    private eRef: ElementRef,
    public store: Store,
    private cd: ChangeDetectorRef,
    public titleService: TitleService,
    public dialog: MatDialog,
    private newsUtilsService: NewsUtilsService
  ) {}

  public get badgeNumber(): Observable<number> {
    return this.getUnreadNewsCount();
  }

  @HostListener("document:click", ["$event"])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.displaySearch = false;
      this.cd.markForCheck();
    }
  }

  /**
   * Set the mobx reaction
   */
  ngAfterViewInit() {
    this.subscriptions.add(this.updateSearchInput());
    this.subscriptions.add(this.updateSearchReady());
    this.shouldOpenNewsModal().subscribe();
  }

  /**
   * Clean the mobx reaction
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Return true is the search page is active
   * return {boolean}
   */
  searchInURL(): boolean {
    const urlPath = this.route.snapshot.firstChild.url[0];
    if (this.route.snapshot.firstChild.url[0]) {
      return urlPath["path"] === "search";
    }
    return false;
  }

  /**
   * Get the search input from store
   */
  updateSearchInput() {
    return reaction(
      () => this.store.searchInput,
      (input: string) => {
        this.searchInput = input;
        this.cd.markForCheck();
      },
      { name: "header-SearchUpdate" }
    );
  }

  /**
   * Display the search button when search is ready
   */
  updateSearchReady() {
    return reaction(
      () => this.store.offlineSearchReady,
      (_ready: boolean) => {
        this.cd.markForCheck();
      },
      { name: "header-offlineReady" }
    );
  }

  public openNewsModal(): void {
    this.dialog.open(NewsModalComponent, { panelClass: "news-modal" });
  }

  /**
   * The sidenav and the button to open it are not in the same component
   * We can use an event
   */
  tocToggle() {
    this.tocNav.toggle().then((state: MatDrawerToggleResult) => {
      runInAction(() => {
        this.store.tocState = state;
      });
    });
    if (this.store.tocMode === TocMode.OVER) {
      this.settingsSidenav.close();
    }
  }

  /**
   * Open the setting bar
   */
  settingsToggle() {
    this.settingsSidenav.toggle();
    if (this.store.tocMode === TocMode.OVER) {
      this.tocNav.close();
    }
  }

  /**
   * Open the search bar if close or redirect to search
   */
  search() {
    // the layout is create on :
    // http://localhost:4200/pub/ec130-b4t2_010-00_en-en/010.00/
    // we don't need ../.. to remove loap/loap
    this.router.navigate(["search", "documents", ""], {
      relativeTo: this.route
    });
  }

  /**
   * Handle the automatic news modal opening when entering a pub with unread news.
   */
  private shouldOpenNewsModal(): Observable<number> {
    return this.getUnreadNewsCount().pipe(
      skipWhile((count: number) => count === 0),
      take(1),
      tap(() => this.openNewsModal())
    );
  }

  /**
   * An observable for the total count of unread news (application + publication)
   */
  private getUnreadNewsCount(): Observable<number> {
    return combineLatest([
      this.newsUtilsService.applicationNews,
      this.newsUtilsService.publicationNews
    ]).pipe(
      map(([appNews, pubNews]) => this.newsUtilsService.countUnreadNews([...appNews, ...pubNews]))
    );
  }
}
