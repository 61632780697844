import { Injectable, ElementRef } from "@angular/core";
import { observable, action, computed } from "mobx-angular";
import { reaction, configure, makeObservable } from "mobx";
import { ESearchState } from "@viewer/core/search/searchState";
import { SynchroInformation } from "@viewer/content-provider/synchro-dom-manipulator";
import { DuObject } from "@viewer/content-provider/duObject";
import { StepObject, HotspotObject } from "@viewer/content-provider/step-dom-manipulator";
import { MediaObject, PubDoc } from "libs/models/couch.models";
import { environment } from "@viewer-env/environment";
import { BehaviorSubject } from "rxjs";
import { User } from "@orion2/auth/user";
import { SearchResult } from "@viewer/core/search/searchModel";
import { DEFAULT_ITEMS_PER_PAGE, incrementalAdminVersion } from "@orion2/utils/constants.utils";
import { TocMode } from "@viewer/layout/toc-mode.model";
import { IpcDetail } from "@orion2/models/ipc.models";
import { Note } from "@orion2/models/tocitem.models";
import {
  HistorySettings,
  WebtekSettings,
  NoteSettings,
  PlayerSettings,
  AircraftMappingSettings,
  SyncSettings,
  DataCollectionSettings
} from "@orion2/models/settings.models";
import { MatDrawerToggleResult } from "@angular/material/sidenav";

configure({
  enforceActions: "observed"
});
@Injectable()
export class Store {
  // User settings
  public currentLanguage = "en";
  public darkMode: boolean;
  public userActivate3D = false; // 3D viewer settings
  public userAutoSynchro = true; // Setting to activate automatic synchro
  public userItemsPerPage = DEFAULT_ITEMS_PER_PAGE;
  public debugMode = false;
  public redirectLastDm = false;

  // Auth
  public user: User;
  public isLoggedIn = false;

  // Publication
  public pubInfo: PubDoc; // DON'T USE pubInfo AS AN OBSERVABLE, IT BREAKS THE PUBINFO.MAPPING
  public publicationID = "";
  public publicationRevision = "";
  public currentDMC: string;
  public duObject: DuObject = {
    duTitle: undefined,
    duReference: undefined,
    data: undefined,
    dmc: undefined
  }; // Contain all data being displayed by the view
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public duStatus: any;

  // TOC
  public currentTocNode = undefined;
  public tocMode = TocMode.SIDE;
  public tocState: MatDrawerToggleResult;

  // Notes
  public noteDrawerOpen = false;
  public currentNotes: Note[] = [];

  // Search
  public searchInput = "";
  public referenceToResultMap: SearchResult[];
  public resultToReferenceMap = new Map<string, number>();
  public highlightKeyword = undefined;
  public topWords: string[];
  public searchTime = 0;
  public offlineLoadingState = ESearchState.NOTREADY;

  // Thumbs
  public currentMediaId = "";
  public mediaThumbs: MediaObject[] = [];
  public figureTitles = new Map<string, string>();
  public currentMediaICN: string;
  public hotspotFromSvg: string;
  public hotspotFromText: string;
  // To switch thumb on great image (svg,png or jpg) on print we have to get the media data.
  // we want to get this data after the display done to not slow down the loading of the page.
  // so we want to react to the displayDone value, but the value of this observable changes before the media is init.
  // the behaviorSuject allows to always react on the last value of the observable without needing a change in value
  public displayDoneBS = new BehaviorSubject(undefined);

  // IPC
  public ipcXML;
  public selectedParts = "";
  public selectedISN = "";
  public tableMode: boolean;
  public openAllEffectivity = false;
  public selectedConf: string;

  // ProceduralStore
  public isProcedural = false;
  public isProceduralLegacy = false;
  public isH160Procedural = false;
  public isS1000D = false;
  public isVendors = false;
  public isEncapsulatedPdf = false;
  public currentViewId = "";
  public currentHotspotId = "";
  public mainFigureId = "";
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public mediaType: any = false;
  public stepMetaMedia = ""; // video or 3D
  public stepObjects: StepObject[] = [];
  public hotspotObjects: Array<HotspotObject | IpcDetail> = [];
  public hotspotDmRefId: string[] = [];
  public ipcHotspotMap = new Map<string, string[]>();
  public pendingHotspot = "";
  public isMoreVisible = false;
  public synchronizationInformation: Map<string, SynchroInformation>;

  // Player 3D
  public isPlaying = false;
  public player3DIsReady = false;
  public playerIsCallOneTime = false;
  public isPlayerActivated = false; // is true if all settings 3D is true (admin settings + viewer settings + capabilities) and 3D media is found
  public apiManager = undefined;
  public _3DisAvailable = true;
  public playerLoadingIsfinished = false;

  public targetStepByStep: ElementRef;
  public pouchReady = false;

  // Applicability
  public applicableMD5: string[] = [];
  public showNotApplicable = undefined;
  public isFilteringAvailable = undefined;
  public applicCondition: string[];

  public mediaNumberingMap = new Map<string, string>();
  public chipNumberingMap = new Map<string, string>();
  public textNumberingMap = new Map<string, string>();

  // ORION Settings
  public historySettings: HistorySettings;
  public webtekSettings: WebtekSettings;
  public noteSettings: NoteSettings;
  public playerSettings: PlayerSettings;
  public aircraftMappingSettings: AircraftMappingSettings;
  public syncSettings: SyncSettings;
  public dataCollectionSettings: DataCollectionSettings;

  // Others
  public allowPreviewAccess = false; // Allow PubAccessResolver to consult non-published pubs once when set to true
  public deteriorate = false; // Set to true if role API is KO
  public stepbystepPlayer;
  public displayDone = false; // trigger when du is displayed to launch background proccess
  public needReload = false; // trigger when we need to refresh the application (ex: change settings 3D)
  public view = "default";
  public homeFilterText: string;
  public printPdf: number[] = [];
  public inPrint = false;
  public scrollYSave = 0;

  // SPEC: We use a guard with deactivate for displaying pop-up when the user leave the pub page.
  // If the user click on "Back to Store", this pop-up is not displayed.
  public byPassPopupLeavePub = false;

  // Internal Store data
  private pubsDiskSize = new Map<string, number>();

  constructor() {
    makeObservable(this, {
      userItemsPerPage: observable,
      currentLanguage: observable,
      publicationID: observable,
      publicationRevision: observable,
      inPrint: observable,
      isLoggedIn: observable,
      stepbystepPlayer: observable,
      displayDone: observable,
      currentDMC: observable,
      needReload: observable,
      currentTocNode: observable,
      view: observable,
      duObject: observable,
      duStatus: observable,
      searchInput: observable,
      topWords: observable,
      searchTime: observable,
      offlineLoadingState: observable,
      currentMediaId: observable,
      mediaThumbs: observable,
      currentMediaICN: observable,
      ipcXML: observable,
      selectedParts: observable,
      tableMode: observable,
      openAllEffectivity: observable,
      selectedConf: observable,
      currentViewId: observable,
      currentHotspotId: observable,
      mainFigureId: observable,
      mediaType: observable,
      stepMetaMedia: observable,
      stepObjects: observable,
      isPlaying: observable,
      playerLoadingIsfinished: observable,
      player3DIsReady: observable,
      targetStepByStep: observable,
      pouchReady: observable,
      applicableMD5: observable,
      showNotApplicable: observable,
      isFilteringAvailable: observable,
      user: observable,
      webtekSettings: observable,
      historySettings: observable,
      noteSettings: observable,
      playerSettings: observable,
      syncSettings: observable,
      dataCollectionSettings: observable,
      hotspotFromSvg: observable,
      hotspotFromText: observable,
      tocMode: observable,
      tocState: observable,
      userAutoSynchro: observable,
      currentPubDocNumber: computed,
      baseUrlWithPubFragments: computed,
      offlineSearchReady: computed,
      mediaIds: computed,
      hasThumbs: computed,
      currentMediaIndex: computed,
      hasPreviousThumb: computed,
      hasNextThumb: computed,
      publicationMajorRevision: computed,
      stepIds: computed,
      isLegacyImport: computed,
      updateMediaFromStep: action,
      currentStepIndex: computed,
      hasPreviousStep: computed,
      hasNextStep: computed,
      currentStep: computed,
      setOfflineSearchNotReady: action
    });

    // trick to update stat from computed value
    reaction(() => this.currentStep, this.updateMediaFromStep.bind(this), {
      name: "updateMediaFromStep"
    });
  }

  get currentPubDocNumber() {
    let total = 0;
    for (const prop in this.pubInfo?.dbs) {
      if (this.pubInfo.dbs.hasOwnProperty(prop)) {
        total += this.pubInfo.dbs[prop];
      }
    }
    return total;
  }

  get baseUrlWithPubFragments() {
    return `/pub/${this.publicationID}/${this.publicationRevision}/`;
  }

  get offlineSearchReady() {
    return this.offlineLoadingState === ESearchState.READY;
  }
  get mediaIds() {
    return this.mediaThumbs.map((media: MediaObject) => (media ? media.NOM_LOGIQUE : undefined));
  }

  get hasThumbs() {
    return this.mediaThumbs.length;
  }

  get currentMediaIndex() {
    return this.mediaIds.indexOf(this.currentMediaId);
  }
  get hasPreviousThumb() {
    return this.currentMediaIndex !== 0;
  }
  get hasNextThumb() {
    return this.currentMediaIndex < this.mediaThumbs.length - 1;
  }

  get publicationMajorRevision() {
    return this.publicationRevision.split(".")[0];
  }

  get stepIds() {
    return this.stepObjects.map((step: StepObject) => step.id);
  }

  get isLegacyImport() {
    return this.pubInfo?.versionImporter < incrementalAdminVersion;
  }

  get currentStepIndex() {
    return this.stepIds.indexOf(this.currentViewId);
  }

  get hasPreviousStep() {
    return this.currentStepIndex > 0;
  }

  get hasNextStep() {
    return this.currentStepIndex < this.stepObjects.length - 1;
  }

  get currentStep(): StepObject | undefined {
    return this.stepObjects[this.currentStepIndex];
  }

  // NOTE - The way of the checking if is tiger is temporary.
  get isTiger(): boolean {
    return this.pubInfo?.occurrenceCode === "999999";
  }

  /**
   * Get publication size in BYTES
   */
  public getPubDiskSize(pub: PubDoc): number {
    if (!this.pubsDiskSize.has(pub._id)) {
      const total =
        pub.sizes &&
        Object.keys(pub.sizes).reduce((acc, dbName) => acc + pub.sizes[dbName].disk, 0);
      // We cache to avoid calling the loop each time we refresh the card.
      this.pubsDiskSize.set(pub._id, total);
    }

    // SPEC: SQLite DB is not compressed, that's why DB size is x2 on Cordova
    const pubSize = this.pubsDiskSize.get(pub._id) * (environment.platform === "cordova" ? 2 : 1);

    // SPEC: Add more needed space for 3D player
    const playerSize =
      environment.platform === "cordova" &&
      pub.capabilities?.player3D &&
      this.playerSettings?.["cordova"]
        ? Math.pow(10, 8) // Player size is around 100 MB
        : 0;

    return pubSize + playerSize;
  }

  updateMediaFromStep(stepObject: StepObject) {
    if (stepObject) {
      // issue with ts
      const closeOrPrepa = stepObject.id === "close" || stepObject.id === "prepa";

      // shortcut if 3d or mp4 but not for prepa and closeup
      if (
        this.stepMetaMedia &&
        !closeOrPrepa &&
        (this.isPlayerActivated || this.mediaType !== "3D") &&
        this._3DisAvailable
      ) {
        this.currentMediaId = this.stepMetaMedia;
      } else {
        this.currentMediaId = this._getStepFirstAncestorIllusId(this.currentStepIndex);
      }
    }
  }

  _getStepFirstAncestorIllusId(index): string {
    // SPEC: If a step level 3 or more has no illustration, we display the illustration of its parent level 2
    if (this.stepObjects[index].level > 2 && !this.stepObjects[index].illusId) {
      for (let i = index; i >= 0; i--) {
        if (this.stepObjects[i].level === 2) {
          return this.stepObjects[i].illusId;
        }
      }
    }
    return this.stepObjects[index].illusId;
  }
  getStepMetadatasByIllusId(_illusId): StepObject | undefined {
    return this.stepObjects.find(step => step.illusId === _illusId);
  }
  getStepMetadatasByStepId(_stepId): StepObject | undefined {
    return this.stepObjects.find(step => step.id === _stepId);
  }

  public getDUMeta(dmc: string): SearchResult {
    const reference = this.resultToReferenceMap.get(dmc);
    return (
      //  We test this rather than as before where we test only the reference because in the case where the
      //  reference was 0 it would falsify the test because it was considered false.
      this.referenceToResultMap?.[reference]
    );
  }

  setOfflineSearchNotReady() {
    this.offlineLoadingState = ESearchState.NOTREADY;
  }
}
