/* eslint-disable @typescript-eslint/member-ordering */
// Used for suffixFunction and preffixFunction don't delete it
import { Constant } from "@viewer/shared-module/constant";
import { getIncrementalPackageId as _getIncrementalPackageId } from "@orion2/utils/functions.utils";
import schemaDefault from "libs/pub-schema/default.json";

export interface Capabilities {
  search?: {
    phonetizer?: boolean;
    task?: boolean;
    parts?: boolean;
  };
  bookmarks?: boolean;
  notes?: boolean;
  "shopping-basket"?: boolean;
  inspections?: boolean;
  player3D?: boolean;
  sync?: boolean;
  webtek?: boolean;
  historic?: boolean;
  superseded?: boolean;
  pym?: boolean;
  discrepancy_portal?: boolean;
  download: boolean;
  applicInline?: boolean;
}

interface DbSchemaInterface {
  type: string;
  replicable?: boolean;
  prefix?: string;
  suffix?: string;
  suffixFunction?: string;
  prefixFunction?: string;
}

export enum DBPubSchemaEnum {
  toc = "toc",
  toc_corp = "toc_corp",
  toc_public = "toc_public",
  toc_user = "toc_user",
  xml = "xml",
  media = "media",
  search = "search",
  applic = "applic",
  pdf = "pdf",
  pending_toc_items = "pending_toc_items"
}

export enum DBAppSchemaEnum {
  user = "user",
  stats = "stats",
  pub = "pubs",
  orion_toc_public = "orion_toc_public"
}

export interface ProfileInfo {
  samAccountName: string;
  siebelId: string;
}

export interface DbMap {
  id: string;
  type: DBPubSchemaEnum;
}

export function createProfileInfo(samAccountName: string, siebelId: string): ProfileInfo {
  return {
    samAccountName: samAccountName.toLowerCase(),
    siebelId: siebelId.toLowerCase()
  };
}

export class DbSchema implements DbSchemaInterface {
  type: DBPubSchemaEnum;
  replicable?: boolean;
  incremental?: boolean;
  _prefix?: string;
  _suffix?: string;
  suffixFunction?: string;
  prefixFunction?: string;
  batchVolume?: number;

  public defaultPrefix: string;

  //those variables are used in default.json
  private constant = Constant;
  private getIncrementalPackageId = _getIncrementalPackageId;
  private profileInfo: ProfileInfo = createProfileInfo("guest", "guest");
  private pending: string;

  constructor(schema: string | any, options?: any, defaultPrefix?: string) {
    if (!schema) {
      throw new Error("Schema should not be undefined");
    }

    let conf = schema;
    if (typeof schema === "string") {
      this.type = schema as DBPubSchemaEnum;
      conf = schemaDefault.pubSchema[schema];
      this.defaultPrefix = schemaDefault.pubSchema["defaultPrefix"];
    } else {
      this.defaultPrefix = defaultPrefix;
    }
    this.initFromObj(conf);

    // init before check profileInfo
    if (options) {
      if (options.profileInfo) {
        this.profileInfo = options.profileInfo;
      }
      if (options.pending) {
        this.pending = options.pending;
      }
    }
  }

  public initFromObj(schema: DbSchemaInterface) {
    if (!schema) {
      throw new Error("Unable to create dbSchema of type: " + this.type);
    }
    Object.keys(schema).forEach(prop => {
      this[prop] = schema[prop];
    });
  }

  get suffix() {
    if (!this._suffix) {
      let dbSuffixFunction: Function = () => {};
      if (this.suffixFunction) {
        // Warning this line create a memory leak, when itering a lot we need to find a way to avoid that
        dbSuffixFunction = new Function(this.suffixFunction).bind(this);
      }

      this.suffix = dbSuffixFunction() || this.type;
    }

    return this._suffix;
  }

  set suffix(suffix) {
    this._suffix = suffix;
  }

  get prefix() {
    if (!this._prefix) {
      let dbPrefixFunction: Function = () => {};
      if (this.prefixFunction) {
        // Warning this line create a memory leak, when itering a lot we need to find a way to avoid that
        dbPrefixFunction = new Function(this.prefixFunction).bind(this);
      }

      this.prefix = dbPrefixFunction() || this.defaultPrefix;
    }

    // to properly handle the case when the suffix is set to an empty string :
    if (!this._prefix) {
      throw new Error("No prefix for database name set for " + this.type);
    }

    return this._prefix;
  }

  set prefix(prefix) {
    this._prefix = prefix;
  }

  public toJSON(): any {
    const json = {};
    Object.keys(this)
      .filter(
        p =>
          ![
            "store",
            "constant",
            "defaultPrefix",
            "getIncrementalPackageId",
            "option",
            "getDbName"
          ].includes(p)
      )
      .forEach(prop => {
        if (!prop.startsWith("_")) {
          json[prop] = this[prop];
        } else {
          json[prop.slice(1, prop.length)] = this[prop];
        }
      });
    return json;
  }

  public getDbName(): string {
    return `${this.prefix}_${this.suffix}`;
  }
}

export class PubSchema {
  public batchVolume?: number;
  private _defaultPrefix: string;

  /**
   * Creates an instance of PubSchema and if template is undefined then use default.json properties
   *
   * @param template
   * @param options
   * @memberof PubSchema
   */
  constructor(template: any, options?: any) {
    this._defaultPrefix = template.defaultPrefix;
    this.initToDefaults();
    Object.keys(template)
      .filter(dbType => dbType in DBPubSchemaEnum)
      .forEach(dbType => {
        template[dbType].type = dbType;
        this[dbType] = new DbSchema(template[dbType], options, this._defaultPrefix);
      });
  }

  public getDbSchema(property: DBPubSchemaEnum): DbSchema {
    if (this.hasOwnProperty(property)) {
      return this[property];
    }

    return undefined;
  }

  public getAllReplicableDbTypes(): DBPubSchemaEnum[] {
    const list = [];
    for (const prop in this) {
      if (this.hasOwnProperty(prop) && typeof this[prop] === "object") {
        const schema = this[prop] as undefined as DbSchema;
        if (schema.replicable) {
          // Callers are identified by type and not name or suffix
          list.push(schema.type);
        }
      }
    }
    return list;
  }

  public toJSON(): any {
    const json = {};
    Object.keys(this).forEach(prop => {
      // With flag useDefineForClassFields = true (in tsconfig) some variables may be undefined
      // We do not want to set undefined variables in the returned json
      if (this[prop]) {
        if (typeof this[prop] !== "string") {
          json[prop] = typeof this[prop] !== "number" ? this[prop].toJSON() : this[prop];
        } else {
          json["defaultPrefix"] = this._defaultPrefix;
        }
      }
    });

    return json;
  }

  private initToDefaults() {
    Object.keys(DBPubSchemaEnum).forEach(dbType => {
      this[dbType] = new DbSchema(DBPubSchemaEnum[dbType]);
    });
  }

  set defaultPrefix(defaultPrefix) {
    this._defaultPrefix = defaultPrefix;
    Object.keys(DBPubSchemaEnum).forEach(dbType => {
      this[dbType].defaultPrefix = defaultPrefix;
    });
  }

  get defaultPrefix() {
    return this._defaultPrefix;
  }
}
