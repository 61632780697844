import { TocInfo } from "@orion2/models/couch.models";
import { TocItem } from "@orion2/models/tocitem.models";

export const TECH_REV_PKG_ORION = /(\d+[._]\d{2})([._]\d{2})$/;
export const REV_PKG_ORION = /((\d+)[._]\d{2})([._]\d{2})?$/;
export const TECH_REV_PKG_PDF = /\d+\.(\d+)\.\d+\.(\d+)$/;
// SPEC: Double parenthesis is needed to align with ORION regex to retrieve major revision
export const REV_PKG_PDF = /(([0-9a-zA-Z]+))_[0-9a-zA-Z]{6}/;
// SPEC: A folder can begin with "f__" or "folder__" or is "root"
export const FOLDER_REGEX = /^(f|folder)__|^root$/;

export const BATCH_ARR_SIZE = 250;

export function getRevRegex(isPackagePDF: boolean): RegExp {
  return isPackagePDF ? REV_PKG_PDF : REV_PKG_ORION;
}

export function getTechnicalRevRegex(isPackagePDF: boolean): RegExp {
  return isPackagePDF ? TECH_REV_PKG_PDF : TECH_REV_PKG_ORION;
}

export function isTechnicalRevision(revision: string, isPackagePDF = false): boolean {
  return getTechnicalRevRegex(isPackagePDF).test(revision);
}

export function getHighestTechnicalRevision(revision: string, isPackagePDF = false): string {
  return revision.replace(getRevRegex(isPackagePDF), "$1.99");
}

export function getIncrementalPackageId(packageId: string): string {
  // SPEC: ORION PackageID is like: as350-b2b3_010-00_en-en and PDF PackageID like: package_74_pdf_8_en-en
  // For package PDF, we also need to remove the UID from the revision.

  const regex = packageId.match(/package+-+\d/g)
    ? /_[0-9a-zA-Z]+(-[0-9a-zA-Z]+)?_+[0-9a-zA-Z]{6}_/g
    : /_\d+(-\d+)?_/g;
  return packageId.replace(regex, "_");
}

export function getMajorRevision(revision: string, isPackagePDF = false): string {
  return revision.replace(getRevRegex(isPackagePDF), "$1");
}

export function sortToc(a: TocInfo, b: TocInfo): number {
  if (a.order_manual && b.order_manual && a.order_manual !== b.order_manual) {
    // MANUALS
    return a.order_manual.localeCompare(b.order_manual, "en", { numeric: true });
  }
  if (a.referenceFolder && b.referenceFolder) {
    // OCMM/OPG FOLDER
    return a.referenceFolder.localeCompare(b.referenceFolder, "en", { numeric: true });
  }
  if (a.code && b.code) {
    // FOLDERS
    return a.code.localeCompare(b.code, "en", { numeric: true });
  }
  if (a.reference && b.reference) {
    // DOCS
    return a.reference.localeCompare(b.reference, "en", { numeric: true });
  }
  if (a.referenceFolder && b.reference) {
    // OCMM/OPG FOLDER & DOC
    return a.referenceFolder.localeCompare(b.reference, "en", { numeric: true });
  }
  if (a.reference && b.referenceFolder) {
    // OCMM/OPG FOLDER & DOC
    return a.reference.localeCompare(b.referenceFolder, "en", { numeric: true });
  }
  if (a.code && b.reference) {
    // FOLDER before DOC
    return -1;
  }
  if (a.reference && b.code) {
    // FOLDER before DOC
    return 1;
  }
  if (a._id === "loap") {
    // LOAP last
    return 1;
  }
  if (b._id === "loap") {
    // LOAP last
    return -1;
  }
  // Unexpected data
  return 0;
}

export function scrollToSelectedNode(behavior: ScrollBehavior): void {
  const selectedCard = document.querySelector(".selected-node");
  if (selectedCard) {
    const tocHeaderHeight = document.querySelector(".parent-node")?.clientHeight;
    scrollInvisibleElement(selectedCard, tocHeaderHeight, window.innerHeight, behavior);
  }
}

/**
 * We want to scroll the element if it is not totally visible beetwen topBound and bottomBound
 */
export function scrollInvisibleElement(
  element: Element,
  topBound: number,
  bottomBound: number,
  behavior: ScrollBehavior
): void {
  const yPositionTop = element.getBoundingClientRect().top;
  const yPositionBottom = element.getBoundingClientRect().bottom;

  // if the element is totally invisible, we scroll it on center
  if (yPositionBottom < topBound || yPositionTop > bottomBound) {
    element.scrollIntoView({ block: "center", behavior });
  }
  // if the element is partially invisible on top, we scroll it on top
  else if (yPositionTop < topBound && yPositionBottom > topBound) {
    element.scrollIntoView({ block: "start", behavior });
  }
  // if the element is partially invisible on bottom, we scroll it on bottom
  else if (yPositionBottom > bottomBound && yPositionTop < bottomBound) {
    element.scrollIntoView({ block: "end", behavior });
  }
}

/**
 * Add on click attribute to a element
 *
 * @param {Element} element
 * @param {string} href
 * @param {string} title
 */
export function addElementOnClick(element: Element, href: string, title: string) {
  if (href !== undefined && title !== undefined) {
    element.setAttribute("onclick", 'gotoRefExt("' + href + '","' + title + '","false")');
  }
}

// Return true if the device is an iPhone or iPad
export function isIos(): boolean {
  return (
    !!window.navigator.userAgent.match(/(iPhone|iPod|iPad)/) ||
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
}

// Return true if the browser is Safari
export function isSafari(): boolean {
  return (
    !!window.navigator.userAgent.match(/Safari/) && !window.navigator.userAgent.match(/Chrome/)
  );
}

export function getNextMajorRev(revision, isPackagePDF = false): string {
  const regex = getRevRegex(isPackagePDF);
  const [_, __, revMajorNumber] = regex.exec(revision);
  let nextMajorRev = `${+revMajorNumber + 1}`;
  if (!isPackagePDF) {
    nextMajorRev = `${nextMajorRev.padStart(3, "0")}.00`;
  }
  return nextMajorRev;
}

export function getStatus(data: string): string {
  switch (data) {
    case "status":
      return "unchanged";
    case "rinstate-status":
      return "new";
    case "rinstate-changed":
      return "new";
    case "rinstate-revised":
      return "new";
    case "revised":
      return "changed";
    default:
      return data;
  }
}

export function chunkArrayBuffer(buffer: ArrayBuffer, chunkSize: number): ArrayBuffer[] {
  const result = [];
  const len = buffer.byteLength;
  let i = 0;
  while (i < len) {
    result.push(buffer.slice(i, i + chunkSize));
    i += chunkSize;
  }

  return result;
}

export function chunkArray<T>(arr: T[], chunkSize = BATCH_ARR_SIZE): T[][] {
  const result = [];

  for (let i = 0; i < arr.length; i += chunkSize) {
    result.push(arr.slice(i, i + chunkSize));
  }

  return result;
}

// transform an string array of numbers to an array of intervals
// example [1,2,3,8,10,11,12,13] => [1-3,8,10-13]
export function getRanges(array: string[]): string[] {
  if (!array?.length) {
    return ["\u2011"]; // Non-breaking hyphen.
  }
  array.sort((string1: string, string2: string) => string1.localeCompare(string2));
  const ranges: string[] = [];
  let rstart: string;
  let rend: string;
  let j: number;
  for (let i = 0; i < array.length; i = j + 1) {
    rstart = array[i];
    rend = rstart;
    j = i;
    while (+array[j + 1] - +array[j] === 1) {
      rend = array[j + 1]; // increment the index if the numbers sequential
      j++;
    }
    ranges.push(rstart === rend ? rstart : `${rstart}\u2011${rend}`); // Non-breaking hyphen.
  }
  return ranges.filter(range => !!range);
}

// transform an string of intervals to an string array of numbers
// example "1-3" => [1,2,3]
export function splitRange(range: string): string[] {
  const splitted = range.split("-");
  const ret = [splitted[0]];
  for (let index = Number(splitted[0]) + 1; index <= Number(splitted[1]); index++) {
    ret.push(index.toString());
  }
  return ret;
}

export function isPackagePDFRev(revision: string): boolean {
  // SPEC: PDF revs are like: "1" or "1.01", and ORION revs like: "001.00" or "001.00.01"
  // WARNING: if revision is "100.01" there will be errors
  return revision?.length <= 5 || false;
}

export function convertRevLabel(
  revision: string,
  isPackagePDF = isPackagePDFRev(revision)
): string {
  if (revision && revision !== "undefined") {
    return revision.replace(getTechnicalRevRegex(isPackagePDF), (_, p1, p2) => {
      const update = p2.replace(".", "");
      return `${p1} (Update ${update})`;
    });
  }
  return "000.00";
}

/**
 * Get object parameter with case insensitive
 *
 * @param object
 * @param key
 */
export function getParam(key: string, object: unknown = {}) {
  return object[Object.keys(object).find((k: string) => k.toLowerCase() === key.toLowerCase())];
}

/**
 * Format CSN
 *
 * @param csn
 */
export function formatCSN(csn: string) {
  // example S1000D csn id = csn-63-1-0-0000-00-001
  // wanted csn format = 63-10-0000-00-001
  const regex = /(csn-)?(\d{2})-(\d)(-)?(\d)-(\d+)-(\d+)-(\d{3})/g;
  return csn.replace(regex, "$2-$3$5-$6-$7-$8");
}

/**
 * getMediaId, getThumbId, getSymbolId
 * Give the correct key for database
 */
export function getMediaId(isLegacyImport: boolean, dmc: string, mediaId: string): string {
  return isLegacyImport ? `${dmc}__${mediaId}` : mediaId;
}

export function getThumbId(isLegacyImport: boolean, dmc: string, mediaId: string): string {
  return isLegacyImport ? `thumb__${dmc}__${mediaId}` : `thumb__${mediaId}`;
}

export function getSymbolId(isLegacyImport: boolean, dmc: string, mediaId: string): string {
  return isLegacyImport ? `symb__${dmc}__${mediaId}` : `symb__${mediaId}`;
}

export function getFileNameFromTocItem(tocItem: TocItem): string {
  // we have splitted attachments and each attachment is like example.pdf__00000 or  example__test.pdf__00000...
  return Object.keys(tocItem._attachments)[0].replace(/(.*)__.*$/, "$1");
}

export function sliceIntoChunks<T>(items: T[], chunkSize: number): T[][] {
  const result: T[][] = [];
  for (let index = 0; index < items.length; index += chunkSize) {
    const chunk = items.slice(index, index + chunkSize);
    result.push(chunk);
  }
  return result;
}

export function convertCSV(array: string[]): string {
  return array.join(";");
}
