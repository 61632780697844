import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { BookmarkTreeService } from "@viewer/toc-items/bookmark-module/bookmark-list/bookmark-tree.service";
import { FolderEditData } from "@viewer/toc-items/bookmark-module/bookmark-list/models";

@Component({
  standalone: false,
  selector: "o-folder-edit",
  templateUrl: "./folder-edit.component.html",
  styleUrls: ["./folder-edit.component.scss"]
})
export class FolderEditComponent implements OnInit {
  public form: FormGroup;
  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: FolderEditData,
    private bookmarkTreeService: BookmarkTreeService
  ) {}

  ngOnInit(): void {
    const title = this.data.mode === "edit" && this.data.folder.name ? this.data.folder.name : "";
    this.form = new FormGroup({
      title: new FormControl(title, [Validators.required])
    });
  }

  public save(): void {
    const title = this.form.get("title").value;
    if (this.data.mode === "add") {
      this.bookmarkTreeService.addFolder(this.data.folder.id, title);
    }
    if (this.data.mode === "edit") {
      this.bookmarkTreeService.editFolder(this.data.folder.id, title);
    }
  }
}
