import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormControl, FormGroup } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { ConfService } from "@viewer/core";
import { map, Observable } from "rxjs";

export enum DisclaimerType {
  GDPR = "gdprPrivacyPolicy",
  TERMS_OF_USE = "termsOfUse"
}

@Component({
  standalone: false,
  selector: "o-disclaimer",
  templateUrl: "./disclaimer.component.html",
  styleUrls: ["./disclaimer.component.scss"]
})
export class DisclaimerComponent implements OnInit {
  public content: Observable<string>;
  public agreement = new FormGroup({
    response: new FormControl(false)
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { type: DisclaimerType; consult: boolean },
    private confService: ConfService,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    const version = this.confService.disclaimersVersions[this.data.type];
    const url = `./assets/disclaimers/${this.data.type}_v${version}.html`;
    this.content = this.http.get(url, { responseType: "text" }).pipe(
      // Extract the body content that is inside the <body> tags
      map((content: string) => /<body.*?>([\s\S]*)<\/body>/i.exec(content)[1])
    );
  }
}
