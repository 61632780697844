import { ViewerMessageService } from "@viewer/core/message/viewer.message.service";
import { CommonModule } from "@angular/common";
import { NgModule, Optional, SkipSelf } from "@angular/core";
import { ConfService } from "@viewer/core/conf/conf.service";
import { LoggerService } from "@viewer/core/logger/logger.service";
import { PouchService } from "@viewer/core/pouchdb/pouch.service";
import { SearchService } from "@viewer/core/search/search.service";
import { BackgroundService } from "@viewer/core/background/background.service";
import { WebWorkerService } from "@viewer/core/web-worker/web-worker.service";
import { IpcService } from "@viewer/core/ipc/ipc.service";
import { CiraService } from "@viewer/core/ipc/cira/cira.service";
import { VendorService } from "@viewer/core/ipc/vendor/vendor.service";
import { CirPartsService } from "@viewer/core/ipc/cir-parts/cir-parts.service";
import { Store } from "@viewer/core/state/store";
import { MobxAngularModule } from "mobx-angular";
import { ApplicabilityService } from "@viewer/core/applicability/applicability.service";
import { NoteService } from "@viewer/core/toc-items/note.service";
import { AuthGuard } from "@viewer/core/auth/auth.guard";
import { FacetService } from "@viewer/core/search/facet.service";
import { TocItemService } from "@viewer/core/toc-items/tocItem.service";
import { BookmarkService } from "@viewer/core/toc-items/bookmark.service";
import { ShoppingService } from "@viewer/core/toc-items/shopping.service";
import { LangService } from "@viewer/core/lang/lang.service";
import { BasicViewService } from "@viewer/content-provider/basic-view.service";
import { InspectionService } from "@viewer/core/toc-items/inspection.service";
import { PreprintService } from "@viewer/core/toc-items/preprint.service";
import { RoleService } from "@viewer/core/role/role.service";
import { EmptyModule } from "@viewer/core/empty/empty.module";
import { TaskService } from "@viewer/core/toc-items/task.service";
import { GdprService } from "@viewer/core/gdpr/gdpr.service";
import { TransfertTiService } from "@viewer/core/transfert-ti/transfert-ti.service";
import { TocItemLocalStorageService } from "@viewer/core/toc-items/tocItemLocalStorage.service";
import { FullHttpService } from "libs/http/fullHttp.service";
import { FolderService } from "@viewer/core/toc-items/folder.service";
import { DocumentService } from "@viewer/core/toc-items/document.service";
import { ServiceBulletinService } from "@viewer/core/toc-items/service-bulletin.service";
import { SupersededService } from "@viewer/core/superseded/superseded.service";
import { PymService } from "@viewer/core/pym/pym.service";
import { ExcelService } from "@viewer/core/excel/excel.service";
import { CsvService } from "@viewer/core/csv/csv.service";
import { DownloadFileService } from "libs/download/download-file.service";
import { CordovaService } from "libs/cordova/cordova.service";
import { SettingsService } from "@viewer/core/toc-items/settings.service";
import { StatsService } from "@viewer/core/stats/stats.service";
import { environment } from "@viewer-env/environment";
import { downloadZip } from "client-zip";
import { provideHttpClient } from "@angular/common/http";

@NgModule({
  imports: [CommonModule, MobxAngularModule, EmptyModule],
  providers: [
    PouchService,
    ConfService,
    SearchService,
    LoggerService,
    ViewerMessageService,
    BackgroundService,
    WebWorkerService,
    IpcService,
    VendorService,
    CiraService,
    CirPartsService,
    Store,
    ApplicabilityService,
    BookmarkService,
    InspectionService,
    SupersededService,
    FullHttpService,
    PreprintService,
    TaskService,
    ApplicabilityService,
    AuthGuard,
    ConfService,
    LoggerService,
    PouchService,
    FacetService,
    Store,
    TocItemService,
    TocItemLocalStorageService,
    NoteService,
    WebWorkerService,
    ShoppingService,
    LangService,
    RoleService,
    GdprService,
    BasicViewService,
    TransfertTiService,
    PymService,
    ExcelService,
    FolderService,
    DocumentService,
    ServiceBulletinService,
    CsvService,
    DownloadFileService,
    CordovaService,
    SettingsService,
    StatsService,
    {
      provide: "envFile",
      useValue: environment
    },
    {
      provide: "downloadZip",
      useValue: downloadZip
    },
    provideHttpClient()
  ]
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    if (parentModule) {
      throw new Error("CoreModule is already loaded. Import it in the AppModule only");
    }
  }
}
