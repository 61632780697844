import {
  Component,
  OnInit,
  Input,
  HostBinding,
  AfterViewInit,
  ElementRef,
  OnDestroy,
  ChangeDetectorRef
} from "@angular/core";
import { Store, ApplicabilityService, ShoppingService, IpcService } from "@viewer/core";
import { reaction } from "mobx";
import { IpcFormatter } from "@orion2/ipc-formatter/index";
import { PartNamespace } from "@orion2/ipc-formatter/types/IpcPart";
import { BasicViewService } from "@viewer/content-provider/basic-view.service";
import { addElementOnClick, getRanges } from "@orion2/utils/functions.utils";
import { ApplicObject, CiraService } from "@viewer/core/ipc/cira/cira.service";
import { isNoNumber } from "@orion2/utils/front.utils";
import { ItemBasket } from "@orion2/models/tocitem.models";
import { Subscription } from "rxjs";
import { separatorApplic } from "@orion2/utils/constants.utils";

@Component({
  standalone: false,
  selector: "o-part-card",
  templateUrl: "./part-card.component.html",
  styleUrls: ["./part-card.component.scss"]
})
export class PartCardComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() linkData: {
    editedTitle: string;
    shortTitle: string;
    id: number;
    reference: string;
    revision: string;
    dmc: string;
    versions: string[];
    url: string;
    params: string[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    part: any;
    applicabilityMD5: string; // Used for inspection
  };
  @HostBinding("class.not-applicable") isNotApplicable = false;
  @HostBinding("class.hide-not-applicable") hideNotApplicable = false;
  @Input() public initCollapse: boolean;

  public versions: string;
  public isInBasket: boolean;
  public partData: PartNamespace.IpcPart;
  public itemNumber: string;
  public collapsed: boolean;
  applicabilities: ApplicObject = { serialNo: [], versions: [] };
  private url: string;
  private disposers: Function[] = [];
  private subscriptions: Subscription;

  constructor(
    public store: Store,
    private basicViewService: BasicViewService,
    private elementRef: ElementRef,
    private ciraService: CiraService,
    private applicabilityService: ApplicabilityService,
    private shoppingService: ShoppingService,
    private changeDetector: ChangeDetectorRef,
    private ipcService: IpcService
  ) {}

  ngOnInit() {
    // we do this because the default value may be false or true due to the navigation in the pages
    this.collapsed = this.initCollapse ?? false;
    this.url = `pub/${this.store.publicationID}/${this.store.publicationRevision}`;

    const applicReaction = reaction(
      () => this.store.applicableMD5,
      () => {
        this.isNotApplicable =
          !this.applicabilityService.isApplicableInline(this.linkData) ||
          !this.applicabilityService.isApplicablePart(this.linkData);
      },
      { fireImmediately: true }
    );
    const showNotApplicableReaction = reaction(
      () => this.store.showNotApplicable,
      () => {
        this.hideNotApplicable = !this.store.showNotApplicable;
      },
      { fireImmediately: true }
    );

    this.partData = IpcFormatter.unserialize(this.linkData.part);
    this.itemNumber = this.getHotspotId();

    if (isNoNumber(this.partData.mpn)) {
      this.partData.mpn = "NO NUMBER";
    }

    this.setApplicabilities().then(() => {
      const applic = this.store.pubInfo.partS1000D
        ? this.applicabilities?.serialNo.join(separatorApplic)
        : this.partData.applic;

      this.partData.applic = this.ipcService.labelApplicability(
        Number(this.partData.indenture),
        applic
      );
    });

    this.isNotApplicable =
      !this.applicabilityService.isApplicableInline(this.linkData) ||
      !this.applicabilityService.isApplicablePart(this.linkData);
    this.disposers.push(applicReaction, showNotApplicableReaction);

    this.subscriptions = new Subscription();
    this.subscriptions.add(
      this.shoppingService.tocItemsOfType.subscribe((inBasket: ItemBasket[]) => {
        this.isInBasket = this.shoppingService.isInBasket(
          inBasket,
          this.partData.csn,
          this.partData.versions.join()
        );
        this.changeDetector.detectChanges();
      })
    );
  }

  selectISN() {
    if (!this.store.pubInfo.partS1000D) {
      this.store.selectedISN = this.partData.isn;
    }
    this.store.highlightKeyword = "";
  }

  setApplicabilities() {
    return this.ciraService
      .getApplicListFromMD5(this.linkData.applicabilityMD5)
      .then((applics: ApplicObject) => {
        this.applicabilities.serialNo = getRanges(applics.serialNo);
        //  applics.versions may contain duplicate values
        this.applicabilities.versions =
          applics.versions.length > 0
            ? // we fallback on versions of the DM if we dont found versions from md5
              Array.from(new Set(applics.versions))
            : this.linkData.versions;

        this.versions = this.applicabilities.versions.join(", ");
      });
  }

  ngAfterViewInit(): void {
    this.generateExternLinks();
    this.basicViewService.addDuLinks(this.elementRef.nativeElement);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.disposers.forEach((disposer: Function) => disposer());
  }

  /**
   * Create part documentation links and IPC links
   *
   * @memberof PartCardComponent
   */
  generateExternLinks(): void {
    const links = this.elementRef.nativeElement.querySelectorAll(".documentation.span_links");

    for (const link of links) {
      addElementOnClick(link, link.getAttribute("id"), link.getAttribute("title"));
    }
  }

  /**
   * Return URL to parent part
   *
   * @returns
   * @memberof PartCardComponent
   */
  getUrl(): string[] {
    if (this.partData) {
      const res = ["/" + this.url, "du", this.partData.parent];
      if (this.linkData.params !== undefined) {
        this.linkData.params.forEach(element => {
          res.push(element);
        });
      }
      return res;
    }
  }

  /**
   * Collapse part details, we pass the state with the "!this.collapsed" to reverse the collapse
   * but we can also pass another boolean when we use this function to toggleExpandCollapseAll
   *
   * @param event
   * @memberof PartCardComponent
   */
  collapse(event: Event = undefined, state = !this.collapsed): void {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    // we set the new state of the collapse toggle
    this.collapsed = state;
  }

  /**
   * Get param to param on redirect to DM
   *
   * @returns Object
   * @memberof PartCardComponent
   */
  getParams(): Object {
    const scroll = this.store.pubInfo.partS1000D
      ? `csn-${this.partData.csn.substring(0, 4)}-${this.partData.csn.substring(4)}`
      : this.partData.csn;
    const ret = {
      scroll,
      hotspotId: this.getHotspotId()
    };
    return ret;
  }

  getHotspotId(): string {
    const s1000dHotspotIdEnd = this.partData.isn[2] === "0" ? "" : this.partData.isn[2];
    return this.store.pubInfo.partS1000D
      ? this.partData.csn.split("-").pop() + s1000dHotspotIdEnd
      : this.partData.csn.split("-").pop();
  }

  toBasket(event: Event) {
    event.preventDefault();
    event.stopPropagation();

    const itemBasket: ItemBasket = this.shoppingService.partToItemBasket(
      this.partData,
      this.linkData
    );

    this.shoppingService.addItemsToBasket(
      [itemBasket],
      this.shoppingService.undoAddFactory([itemBasket])
    );
  }
}
