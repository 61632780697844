import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Store } from "@viewer/core/state/store";
import { environment } from "@viewer-env/environment";
import { ConfService, PouchService } from "@viewer/core";
import { Router } from "@angular/router";
import { runInAction } from "mobx";
import { DegradedStateService } from "libs/http/degraded-state/degraded-state.service";

@Component({
  standalone: false,
  selector: "o-settings-form",
  templateUrl: "./settings-form.component.html",
  styleUrls: ["./settings-form.component.scss"]
})
export class SettingsFormComponent {
  public platformAllowed: boolean;
  public hideDebugOption: boolean;
  public itemsPerPageValues = [5, 10, 25, 100];
  // regex for matching /stepbystep/ or /ipc/
  private potential3DView = /\/(stepbystep|ipc)\//g;

  constructor(
    public store: Store,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public dialogRef: MatDialogRef<any>,
    private pouchService: PouchService,
    private router: Router,
    private degradedStateHandler: DegradedStateService,
    private confService: ConfService
  ) {
    this.platformAllowed = this.store.playerSettings?.[environment.platform];
    // we want to hide debug options for prod and on-premise
    this.hideDebugOption = environment.production || this.confService.isOnPrem;
  }

  public get disableBP2(): boolean {
    return this.degradedStateHandler.isBlocked;
  }

  public set disableBP2(value: boolean) {
    this.degradedStateHandler.block(value);
  }

  public close() {
    this.dialogRef.close();
  }

  public changeTheme(isDarkSelected) {
    if (isDarkSelected) {
      this.store.darkMode = true;
      document.body.classList.remove("orion-light");
      document.body.classList.add("orion-dark");
    } else {
      this.store.darkMode = false;
      document.body.classList.remove("orion-dark");
      document.body.classList.add("orion-light");
    }
  }

  public changeDisplayMode(activate3D: boolean): void {
    this.pouchService.userCaller.saveUserActivate3D(activate3D).then(() => {
      if (this.router.url.match(this.potential3DView)) {
        const dmc = this.store.currentDMC;
        this.router
          .navigate(["/pub", this.store.publicationID, this.store.publicationRevision, "du", dmc])
          .then(() => {
            runInAction(() => {
              // we change value of needReload for trigger reaction on content provider
              // we need to reload the content provider only for potential 3D view
              this.store.needReload = !this.store.needReload;
            });
          });
      }
    });
  }

  public toggleAutoSynchro(autoSynchro: boolean): void {
    this.pouchService.userCaller.saveUserAutoSynchro(autoSynchro);
  }

  public updateItemsPerPage(value: number): void {
    this.pouchService.userCaller.saveUserItemsPerPage(value);
  }

  public updateUserRedirectDm(value: boolean): void {
    this.pouchService.userCaller.saveUserRedirectLastDm(value);
  }
}
