import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { Store } from "@viewer/core/state/store";

@Component({
  standalone: false,
  selector: "o-info-dialog",
  templateUrl: "./info-dialog.component.html",
  styleUrls: ["./info-dialog.component.scss"]
})
export class InfoDialogComponent {
  public infoContent: SafeHtml;

  constructor(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(MAT_DIALOG_DATA) public data: any,
    public sanitizer: DomSanitizer,
    public store: Store
  ) {
    if (data?.content) {
      this.infoContent = this.sanitizer.bypassSecurityTrustHtml(data.content);
    }
  }
}
