import { Component, Input } from "@angular/core";
import { Store, LoggerService } from "@viewer/core";
import { Router, ActivatedRoute } from "@angular/router";
import { runInAction } from "mobx";

export enum MediaSwitchType {
  LEFT = 1,
  RIGHT = 2
}

@Component({
  standalone: false,
  selector: "o-media-switch",
  templateUrl: "./media-switch.component.html",
  styleUrls: ["./media-switch.component.scss"]
})
export class MediaSwitchComponent {
  @Input() type: MediaSwitchType;
  // how to use enum in template
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public MediaSwitchType = MediaSwitchType;

  readonly mediaIdKey = "mediaId";

  constructor(
    private logger: LoggerService,
    public store: Store,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  canBeDisplayed() {
    if (this.store.isProcedural && this.store.isS1000D) {
      return this.type === MediaSwitchType.LEFT
        ? this.store.hasPreviousStep
        : this.store.hasNextStep;
    }
    return this.type === MediaSwitchType.LEFT
      ? this.store.hasPreviousThumb
      : this.store.hasNextThumb;
  }

  switch() {
    return this.store.isProcedural && this.store.isS1000D
      ? this._switchStep(this.afterSwitchStepIndex())
      : this._switchIllustration(this.afterSwitchMediaIndex());
  }

  private afterSwitchMediaIndex() {
    return this.type === MediaSwitchType.LEFT
      ? this.store.currentMediaIndex - 1
      : this.store.currentMediaIndex + 1;
  }

  private afterSwitchStepIndex() {
    return this.type === MediaSwitchType.LEFT
      ? this.store.currentStepIndex - 1
      : this.store.currentStepIndex + 1;
  }

  private _switch(id) {
    if (id) {
      if (!this.store.isS1000D || !this.store.isProcedural) {
        runInAction(() => {
          this.store.currentMediaId = id;
        });
      }
      return this.router.navigate([id], {
        relativeTo: this.route.parent,
        replaceUrl: true
      });
    } else {
      return this.logger.error("Index not found :", id);
    }
  }

  /**
   * redirect to illustration
   *
   * @param index
   */
  private _switchIllustration(index: number) {
    const afterSwitchIllustration = this.store.mediaIds[index];
    return this._switch(afterSwitchIllustration);
  }

  /**
   *
   * @param index Switch to next step
   */
  private _switchStep(index: number) {
    const afterSwitchStep = this.store.stepIds[index];
    const step = this.store.stepObjects[index];
    if (step && this.store.apiManager && step.internalRefTargetType === "irtt53") {
      this.store.apiManager.GoToScenario(step.ref);
    }
    if (step && this.store.apiManager && step.internalRefTargetType === "irtt51") {
      this.store.apiManager.GoToMarker(step.ref);
    }
    return this._switch(afterSwitchStep);
  }
}
