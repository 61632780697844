import { Injectable, Injector } from "@angular/core";
import { TocItemService } from "@viewer/core/toc-items/tocItem.service";
import { TocPublicCaller } from "@viewer/core/pouchdb/caller";
import { BehaviorSubject, Observable, filter, map } from "rxjs";
import { Preprint, TocItem } from "@orion2/models/tocitem.models";
import { reaction, runInAction } from "mobx";
import { TocInfo } from "@orion2/models/couch.models";
import { DBConnexionType } from "@viewer/core/pouchdb/types";
import { chunkArray } from "@orion2/utils/functions.utils";
import ExpiryMap from "expiry-map";
import { PromiseQueue } from "@orion2/utils/promises.utils";

@Injectable()
export class PreprintService extends TocItemService {
  public _preprintMinimize = false;
  public _sideBySide = false;

  protected tiType = "preprint";
  protected tiScope = "public";
  protected tiTarget = DBConnexionType.BOTH;

  private preprintMinimizeSubject = new BehaviorSubject<boolean>(this._preprintMinimize);
  private sideBySideSubject = new BehaviorSubject<boolean>(this._sideBySide);

  // In order to reduce memory impact we caching preprint list only for 2 hours
  private preprintList = new ExpiryMap<string, Preprint[]>(2 * 60 * 60 * 1000);
  private preprintReqQueue = new PromiseQueue();

  constructor(injector: Injector) {
    super(injector);

    reaction(
      () => this.store.publicationID,
      () => {
        if (this.store.publicationID) {
          // we reset preprintList when we change publication
          this.preprintList.clear();
        }
      }
    );
    this.setPreprintMinimize(false);
    this.setSideBySide(false);
  }

  get preprintMinimize(): Observable<boolean> {
    return this.preprintMinimizeSubject.asObservable();
  }

  get sideBySide(): Observable<boolean> {
    return this.sideBySideSubject.asObservable();
  }

  public get tocItemsOfType(): Observable<Preprint[]> {
    return super.tocItemsOfType.pipe(
      filter(Boolean),
      map((preprints: TocItem[]) => preprints as Preprint[])
    );
  }

  /**
   * Get preprint for a specific parent
   *
   * @param {string} parent
   * @return {Promise<Preprint>}
   * @memberof PreprintService
   */
  public getPreprint(parent: string): Promise<Preprint> {
    return this.getPreprints([parent]).then((preprints: Preprint[]) => preprints.shift());
  }

  /**
   * Get preprints list for the following parents
   *
   * @param {string[]} parents parents list to get preprints
   * @return {Promise<Preprint[]>}
   * @memberof PreprintService
   */
  public getPreprints(parents: string[]): Promise<Preprint[]> {
    return this.preprintReqQueue.enqueue(() => this.requestPreprint(parents));
  }

  public getPreprintPdf(pdfId: string): Promise<Blob> {
    return (this.getCaller(this.tiScope) as TocPublicCaller).getAttachmentsBlob(pdfId);
  }

  public isPreprint(node: TocInfo): Promise<boolean> {
    if (!node) {
      return Promise.resolve(false);
    }

    if (node._id.startsWith("preprint")) {
      return Promise.resolve(true);
    }

    if (node._id.match(/^sb|^document/)) {
      return Promise.resolve(false);
    }

    return this.getPreprint(node.dmc).then((preprint: Preprint) => Boolean(preprint));
  }

  public setPreprintMinimize(value: boolean): void {
    this._preprintMinimize = value;
    this.preprintMinimizeSubject.next(this._preprintMinimize);
    runInAction(() => {
      if (value) {
        this.store.view = "preprint_minimise";
      } else if (this._sideBySide) {
        this.store.view = "preprint_sidebyside";
      } else {
        this.store.view = "preprint_fullscreen";
      }
    });
  }

  public setSideBySide(value: boolean): void {
    this._sideBySide = value;
    runInAction(() => {
      this.store.view = value ? "preprint_sidebyside" : "preprint_fullscreen";
    });
    this.sideBySideSubject.next(this._sideBySide);
  }

  private requestPreprint(parents: string[]): Promise<Preprint[]> {
    const parentsSplited = chunkArray<string>(parents);

    return Promise.all(
      parentsSplited.map((parentsChunk: string[]) => {
        const { preprints: cachedPreprint, missing: parentToCheck } =
          this.getPreprintsFromCache(parentsChunk);

        if (parentToCheck.length) {
          return this.getCaller(this.tiScope)
            .getItemsOfType(this.tiType, this.tiTarget, parentToCheck)
            .then((preprints: Preprint[]) => {
              // Store the new preprints list into the cache
              parentToCheck.forEach((p: string) => {
                const preprintArr = preprints.filter((preprint: Preprint) =>
                  preprint.parents.includes(p)
                );

                this.preprintList.set(p, preprintArr);
              });

              return [cachedPreprint, preprints].flat();
            });
        } else {
          return Promise.resolve(cachedPreprint);
        }
      })
    ).then((preprintsRes: Preprint[][]) => preprintsRes.flat());
  }

  /**
   * Get all preprint from the cache and return the list of all missing parents in cache
   *
   * @private
   * @param {string[]} parents
   * @return {{ preprints: Preprint[]; missing: string[] }}
   * @memberof PreprintService
   */
  private getPreprintsFromCache(parents: string[]): { preprints: Preprint[]; missing: string[] } {
    const missingList: string[] = [];
    const preprintList: Preprint[][] = [];

    parents.forEach((parent: string) => {
      if (this.preprintList.has(parent)) {
        preprintList.push(this.preprintList.get(parent));
      } else {
        missingList.push(parent);
      }
    });

    return {
      preprints: preprintList.flat(),
      missing: missingList
    };
  }
}
