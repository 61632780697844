import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@viewer/core/state/store";
import { action, makeObservable } from "mobx";
import { getFullChangeStr } from "@viewer/shared-module/helper.utils";
import { TranslateService } from "@ngx-translate/core";
import { formatCSN } from "@orion2/utils/functions.utils";
import { IpcDetail, AdditionalInformations, EPN, Validity, IPP } from "@orion2/models/ipc.models";
import xpath from "xpath";
import { isNoNumber } from "@orion2/utils/front.utils";
import { DuObject } from "@viewer/content-provider/duObject";
import { PubLangPipe } from "libs/pipe/pub-lang.pipe";

export interface IpcTocData {
  key: string;
  indenture: number;
  hotspot: string;
  version: IpcDetail[];
  children: IpcTocData[];
  attachingParts: IpcTocData[];
  fittingVariants: IpcTocData[];
}

export interface ISN {
  isnValue?: string;
  label?: string;
  qty?: string;
  totalqty?: string;
  airbusPN?: string;
  equivalentPartNb?: string;
  mpn?: string;
  mfc?: string;
  can?: string;
  replaces?: string;
  replacedBy?: string;
  effectivity?: string[];
  rfd?: string;
  referredBy?: { label: string; link: string };
  referTo?: { label: string; link: string };
  nsn?: string;
  localManufact?: string;
  stock?: string;
  descriptionLocation?: string;
  applicability?: string;
  additionalInformations?: AdditionalInformations;
  availableConfIds?: string[];
  isnId?: string;
  isProcurable?: boolean;
  applicabilityMD5?: string;
  validities?: Validity[];
}

@Injectable()
export class IpcService {
  public detailsIdTab = 0;
  public ipcDetailState = false;
  public isn: ISN = {
    label: "",
    qty: "",
    totalqty: "",
    airbusPN: "",
    equivalentPartNb: "",
    mpn: "",
    mfc: "",
    can: "",
    replaces: "",
    replacedBy: "",
    effectivity: [],
    rfd: "",
    referTo: {
      label: "",
      link: ""
    },
    nsn: "",
    localManufact: "",
    stock: "",
    descriptionLocation: "",
    applicability: ""
  };

  public ipp: IPP;
  public items: IpcTocData[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public ipcXmlObs: BehaviorSubject<any> = new BehaviorSubject(undefined);

  private catalogItems: Element[];
  private parentForIndenture: IpcTocData[] = [];
  private parentForAttachingPart: IpcTocData;
  private parentForFittingPart: IpcTocData;

  constructor(
    private route: ActivatedRoute,
    private store: Store,
    private translate: TranslateService,
    private pubLangPipe: PubLangPipe
  ) {
    makeObservable(this, {
      handleIPC: action,
      setMediaID: action,
      setSelectedParts: action
    });

    const initialSelectedHotspots = this.route.snapshot.queryParamMap.get("hotspotId");
    this.setSelectedParts(initialSelectedHotspots);
  }

  setSelectedParts(selectedParts: string) {
    this.store.selectedParts = selectedParts || "";
  }

  /**
   * Get a specific CSN details
   *
   * @param item
   * @memberof IpcService
   */
  public getDetailsFromCSN(item: string): IpcDetail {
    if (!this.store.isS1000D) {
      const node = this.getCSNFromHotspotId(item);
      return this.getDetails(node);
    }
    // select catalog with id
    // SPEC
    // the version of the item is the last alphabetic character (example item = 010A)
    // for basic version (example item = 010), version is '0' on S1000D standard
    const version = item.match(/\D/g) ? item.match(/\D/g)[0] : "0";
    const partNode = this.getS1000DPartElement(item);
    const csnNode = this.getS1000DVersionElement(partNode, version);
    const indenture = partNode.getAttribute("indenture");
    const hotspotId = partNode.getAttribute("item") + (version !== "0" ? version : "");
    const id = partNode.getAttribute("id")
      ? partNode.getAttribute("id").split("csn-")[1]
      : hotspotId;

    // fill catalog details
    const details = this.getDetails1000D(csnNode);
    details.csn = formatCSN(id);
    details.id = id;
    details.indenture = parseInt(indenture, 10);
    details.hotspotId = hotspotId;

    return details;
  }

  /**
   * get CSN details
   *
   * @param i (index)
   * @returns json object
   * @memberof IpcTocComponent
   */
  public getDetails(xmlNode: Element): IpcDetail {
    // Retrieve values through IPC service
    const currentIsn = xmlNode.querySelector("ISN[CHANGE]");

    this.isn = this.getInfosISN(currentIsn);

    let hotspotID: string;
    let itemNumber: string;
    // If a XREF exists, gets the related hotspotId
    const internalRefTag = xmlNode.querySelector("XREF");
    if (internalRefTag) {
      const internalRefId = internalRefTag.getAttribute("XREFID");
      hotspotID = this.store.ipcXML
        .querySelector(`HOTSPOT[ID=${internalRefId}]`)
        .getAttribute("APSNAME");
      // in this case we want to display itemNumber instead of hotspotID
      itemNumber = xmlNode.getAttribute("ITEM");
    } else {
      hotspotID = xmlNode.getAttribute("ITEM");
    }
    const nil = xmlNode.querySelector("NIL")?.innerHTML || "";

    // get DFL to identify custom parts
    const CBS = xmlNode.querySelector("CBS");
    const DFLtags = CBS?.querySelectorAll("DFL");
    const customPart = Array.from(DFLtags)
      .map(tag => tag.innerHTML)
      .includes("!!! CUSTOMIZED PART !!!");

    // get after amendment
    const afterAmendment = xmlNode.querySelector("CAN")?.innerHTML;

    // get attaching part
    const attachingPart = !!xmlNode.querySelector("ASP");

    const change = getFullChangeStr(currentIsn.getAttribute("CHANGE"));

    const indenture = +xmlNode.getAttribute("IND");
    const csn = xmlNode.getAttribute("CSN") || xmlNode.getAttribute("ITEM");
    const applicableVersion = this.getApplicableVersions();

    const details = {
      id: csn,
      label: this.isn["label"],
      qty: this.isn.qty || "-",
      totalqty: this.isn.totalqty,
      airbusPN: this.isn.airbusPN,
      equivalentPartNb: this.isn.equivalentPartNb,
      mpn: this.isn.mpn,
      mfc: this.isn.mfc,
      can: this.isn.can,
      replaces: this.isn.replaces,
      replacedBy: this.isn.replacedBy,
      effectivity: this.isn.effectivity,
      rfd: this.isn.rfd,
      referTo: this.isn.referTo,
      nsn: this.isn.nsn,
      localManufact: this.isn.localManufact,
      stock: this.isn.stock,
      descriptionLocation: this.isn.descriptionLocation,
      applicableVersion,
      applicability: this.isn.applicability,
      hotspotId: hotspotID,
      idTab: 0,
      isn: [],
      allIsn: xmlNode.querySelectorAll("ISN"),
      change,
      csn,
      indenture,
      nil,
      customPart,
      afterAmendment,
      attachingPart,
      isProcurable: this.isn.isProcurable,
      itemNumber
    } as IpcDetail;

    for (let j = 0; j < details.allIsn.length; j++) {
      details.isn[j] = {
        id: (details.allIsn[j] as Element).getAttribute("ID"),
        pnr: (details.allIsn[j] as Element).querySelector("PNR").innerHTML || "-"
      };
    }

    return details;
  }

  /**
   * Get applicability data for Legacy
   */
  public _retrieveApplicabilityATA(xmlNode: Element): string {
    const applics = xmlNode.querySelectorAll("MOV");
    const indenture = +xmlNode.getAttribute("IND");
    // Added a space after a comma for better visibility
    const applicability = applics[applics.length - 1]?.getAttribute("MOV")?.replace(/,/g, ", ");
    return this.labelApplicability(indenture, applicability);
  }

  /**
   * SPEC: items whose applicability has a range equal to
          1001-9999 or 0005-9999 or 9004-9999 or 0001-9999 ,
          must refer to the applicability of the parent. Except for the root node (indenture = 1)
     SPEC: not diplsay disclaimer in IPC Card
   * @param indenture Inenture of the node (CSN)
   * @param applicability Applicability (serial numbers ranges)
   * @returns The disclaimer or the applicabilty passed as input
   */
  public labelApplicability(
    indenture: number,
    applicability: string,
    displayDisclaimer = true
  ): string {
    if (applicability?.match(/(1001|0005|9004|0001)-9999/g) && indenture > 1) {
      return displayDisclaimer ? this.translate.instant("applicability.sn.disclaimer") : "";
    }
    return applicability;
  }

  /**
   * Get all ISN datas
   *
   * @param {Element} currentIsn
   * @returns isn object
   * @memberof IpcService
   */
  public getInfosISN(currentIsn: Element): ISN {
    const status = currentIsn.getAttribute("CHANGE");
    // We don't want to get infos if it's a deleted ISN due to missing attributes.
    if (status === "D") {
      this.isn = {
        label: "DELETED",
        qty: "",
        totalqty: "",
        airbusPN: "",
        equivalentPartNb: "",
        mpn: "",
        mfc: "",
        can: "",
        replaces: "",
        replacedBy: "",
        effectivity: [],
        rfd: "",
        referTo: {
          label: "",
          link: ""
        },
        nsn: "",
        localManufact: "",
        stock: "",
        descriptionLocation: "",
        applicability: "",
        isProcurable: false
      };
      return this.isn;
    }

    const label = currentIsn.querySelector("DFP").innerHTML;

    const qty = currentIsn.querySelector("QNA").innerHTML;
    const totalqty = currentIsn.querySelector("TQY")?.innerHTML;

    const airbusPN = this.getValues(currentIsn, "ECPNR");
    const equivalentPartNb = this.getValues(currentIsn, "OPN");

    const mpn = currentIsn.querySelector("PNR").innerHTML;
    const mfc = currentIsn.querySelector("MFC").innerHTML;

    const can = currentIsn.querySelector("CAN")?.innerHTML;
    const replaces = currentIsn.querySelector("ICYOLD")?.innerHTML;
    const replacedBy = currentIsn.querySelector("ICYNEW")?.innerHTML;

    const effectivity = Array.from(currentIsn.querySelectorAll("UCA")).map(
      (uca: Element) => uca.innerHTML
    );
    const rfd = currentIsn.querySelector("RFD")?.innerHTML;

    const referTo = {
      label: "",
      link: ""
    };
    const referNode = currentIsn.querySelector("CSNREF");
    if (referNode) {
      referTo.label = referNode.getAttribute("REFCSN");
      referTo.link = referNode.getAttribute("xlink:href");
    }

    const nsn = currentIsn.querySelector("NSN")?.getAttribute("NSN");

    const alternateParts = Array.from(
      currentIsn.querySelectorAll("DFL"),
      (dfl: Element) => `(${dfl.innerHTML.trim()})`
    );
    const isProcurable = this._isProcurable(mpn, alternateParts);
    const descriptionLocation = this._getDescriptionLocation(alternateParts, isProcurable);

    const localManufactDisplayed: string = this.retrieveLocalManufact(currentIsn);
    const stock = currentIsn.querySelector("STOCK")?.getAttribute("STOCK");
    const stockDisplayed = this._getDisplayedStock(stock);

    const applicability = this._retrieveApplicabilityATA(currentIsn);

    this.isn = {
      label,
      qty,
      totalqty,
      airbusPN,
      equivalentPartNb,
      mpn,
      mfc,
      can,
      replaces,
      replacedBy,
      effectivity,
      rfd,
      referTo,
      nsn,
      descriptionLocation,
      localManufact: localManufactDisplayed,
      stock: stockDisplayed,
      applicability,
      isProcurable
    };
    return this.isn;
  }

  /**
   * Construct referential
   *
   * @param ipcDoc
   */
  handleIPC(ipcDoc: DuObject) {
    this.items = [];
    this.parentForIndenture = [];
    this.store.hotspotObjects = [];
    this.store.ipcXML = ipcDoc.data;
    this.ipcXmlObs.next(ipcDoc.data);
    if (this.store.isS1000D) {
      this.catalogItems = [].slice.call(this.store.ipcXML.querySelectorAll("catalogSeqNumber"));

      this.store.mediaType =
        this.store.ipcXML.querySelector("multimediaObject") &&
        this.store.ipcXML.querySelector("multimediaObject").getAttribute("multimediaType");
      this.constructIpcTocS1000D(this.catalogItems);
    } else {
      if (this.store.ipcXML.querySelectorAll("CSN").length > 0) {
        this.catalogItems = [].slice.call(this.store.ipcXML.querySelectorAll("CSN"));

        this.constructIpcTocATA(this.catalogItems);
      }
    }
    this.setInitialProvisioningProject();
  }

  /**
   * With the xml ATA(Legacy), construct ipc TOC
   * This method reminds itself in a recursive way, this first time, currentNode is empty
   *
   * @param catalogItems is XML Data
   * @param index
   * @param currentNode
   */
  constructIpcTocATA(catalogItems: Element[], index = 0, currentNode?: IpcTocData): void {
    if (index < catalogItems.length) {
      const details = this.getDetails(catalogItems[index]);
      // get unique key for new node
      const key = details.hotspotId;

      // If the CSN does not contain a letter [A-Z] as the last character or it is the first call .
      if (!currentNode || !this.checkVersions(catalogItems, index, key)) {
        currentNode = this._createDataForIpcTocNode(key, details);

        // If the CSN contains a letter, it is checked current CSN and a one of the previous CSN in XML
        // Check if the CSN contain a letter [A-Z] as the last character.
      } else if (
        index !== 0 &&
        catalogItems[index].getAttribute("CSN").match(/[a-zA-Z]$/g) != null
      ) {
        const currentCatalogItemsSize: number = catalogItems[index].getAttribute("CSN").length;
        const lastCatalogItemsSize: number = catalogItems[index - 1].getAttribute("CSN").length;
        const currentCatalogItems: string = catalogItems[index]
          .getAttribute("CSN")
          .substr(0, currentCatalogItemsSize - 1);
        let lastCatalogItems: string = catalogItems[index - 1].getAttribute("CSN");

        // If exist deletes the letter from CSN -1
        if (catalogItems[index - 1].getAttribute("CSN").match(/[a-zA-Z]$/g) != null) {
          lastCatalogItems = catalogItems[index - 1]
            .getAttribute("CSN")
            .substr(0, lastCatalogItemsSize - 1);
        }

        // If the two CSNs are different then create a new card
        if (currentCatalogItems !== lastCatalogItems) {
          currentNode = this._createDataForIpcTocNode(key, details);
        }
      } else {
        console.error("Fail create Data For Toc Node - " + catalogItems[index].getAttribute("CSN"));
      }

      // Fill node with node details and index for mat tab
      details.idTab = this.detailsIdTab;
      currentNode.version.push(details);
      this.detailsIdTab++;
      // recursively iterates
      this.constructIpcTocATA(catalogItems, index + 1, currentNode);
    }
  }

  /**
   * Compare the CSN attributes and check if the versions exist.
   *
   * @param catalogItems the catalog of items.
   * @param index the index to search in the catalog.
   * @param idNum the CSN to check.
   * @returns true if everything matche, false otherwise.
   *
   * @memberof IpcTocComponent
   */
  checkVersions(catalogItems: Element[], index: number, idNum: string): boolean {
    if (!idNum || !idNum.match(/[a-zA-Z]$/g)) {
      return false;
    }

    const catalogItem = catalogItems[index];
    if (!catalogItem) {
      return false;
    }

    if (this.store.isS1000D) {
      return (
        idNum.replace(/\D/g, "") === catalogItem.getAttribute("item") &&
        catalogItem.querySelectorAll("itemSeqNumber").length > 1
      );
    }
    return idNum === catalogItem.getAttribute("ITEM");
  }

  /**
   * Update currentMediaID with the picture related to the selected hotspotID.
   *
   * @param hotspotId the id of selected hotspot.
   */
  setMediaID(hotspotId: string): void {
    let markHotspot: Element;
    let mediaID: string;

    if (this.store.isS1000D) {
      hotspotId = hotspotId.replace(/\D/g, "");
      markHotspot = this.store.ipcXML.querySelector(`catalogSeqNumber[item='${hotspotId}']`);
      mediaID = this._getMediaIDS1000D(markHotspot, hotspotId);
    } else {
      markHotspot = this.store.ipcXML.querySelector(`HOTSPOT[APSNAME='${hotspotId}']`);
      if (markHotspot) {
        mediaID = markHotspot.parentElement.getAttribute("BOARDNO");
      }
    }

    if (mediaID) {
      this.store.currentMediaId = mediaID;
    } else {
      /**
       * The hotspot is pending to find its mediaId
       * in case the "findAllHotspots" function from mediaService
       * has not yet created the ipcHotspotMap.
       */
      this.store.pendingHotspot = hotspotId;
    }
  }

  /**
   *
   * @param catalogItems the catalog of items.
   * @param index
   * @param currentNode
   */
  constructIpcTocS1000D(catalogItems: Element[], index = 0, currentNode?: IpcTocData): void {
    if (index >= catalogItems.length) {
      return;
    }

    const indenture = catalogItems[index].getAttribute("indenture");
    const hotspotId = catalogItems[index].getAttribute("item");
    const id = catalogItems[index].getAttribute("id") || hotspotId;
    const csn = formatCSN(id);
    this.isn.label = id;

    const internalRefId = catalogItems[index]
      .querySelector("internalRef")
      ?.getAttribute("internalRefId");

    const hotspotElementRef: Element =
      internalRefId && this.store.ipcXML.querySelector(`hotspot[id=${internalRefId}]`);
    const hotspotParam = hotspotElementRef?.getAttribute("applicationStructureIdent");

    const isnList: NodeListOf<Element> = catalogItems[index].querySelectorAll("itemSeqNumber");
    // We determine if fitting variant or not
    const partStatus: string[] = [];
    isnList.forEach((isn: Element) => {
      partStatus.push(isn.getAttribute("partStatus"));
    });
    const fittingVariant: boolean = partStatus.includes("pst01") && partStatus.includes("pst05");

    const arrayFromIsnList: Element[] = Array.from(isnList);
    arrayFromIsnList.forEach((isn: Element, idx: number) => {
      const details = this.getDetails1000D(isn);
      const equivalentPartNb = this.getEquivalentPartNumber(catalogItems[index], details["mpn"]);

      details["equivalentPartNb"] = equivalentPartNb;
      details["id"] = id;
      details["csn"] = csn;
      details["indenture"] = parseInt(indenture, 10);
      details["hotspotId"] =
        details.isnValue[2] === "0" ? hotspotId : hotspotId + details.isnValue[2];
      details["hotspotParam"] = hotspotParam;
      details["fittingVariant"] = fittingVariant;

      const key = details["hotspotId"];

      // SPEC: We want to create a new node only for the first isn version.
      if (idx === 0) {
        currentNode = this._createDataForIpcTocNode(key, details);
      }
      this.setCurrentNodeVersion(currentNode, details, isn, arrayFromIsnList);
      this.store.hotspotObjects.push(details);
    });

    // recursively iterates
    this.constructIpcTocS1000D(catalogItems, index + 1);
  }

  getDetails1000D(xmlNode: Element): IpcDetail {
    const infosISN = this.getInfosISNs1000d(xmlNode);
    this.isn.mpn = infosISN.mpn;
    this.isn.mfc = infosISN.mfc;
    this.isn.qty = infosISN.qty;

    const attachingPart = !!xmlNode.querySelector(
      "partLocationSegment > attachStoreShipPart[attachStoreShipPartCode='1']"
    );
    const nil = xmlNode.querySelector("notIllustrated")?.innerHTML || "";
    const isnId = xmlNode.getAttribute("id");
    //SPEC: we get changeType from ISN tag. If it don't exist, we get it from CSN tag
    const changeType =
      xmlNode.getAttribute("changeType") || xmlNode.parentElement.getAttribute("changeType");
    const change = getFullChangeStr(changeType);
    const applicableVersion = this.getApplicableVersions();
    let availableConfIds = infosISN.availableConfIds;
    if (!availableConfIds && nil === "-") {
      availableConfIds = [isnId];
    }
    const validities = this.getValidities(xmlNode);

    return {
      idTab: 0,
      isnValue: infosISN.isnValue,
      mpn: infosISN.mpn,
      mfc: infosISN.mfc,
      qty: infosISN.qty.length > 0 ? infosISN.qty : "-",
      nsn: infosISN.nsn,
      totalqty: infosISN.totalqty,
      label: infosISN.label,
      referredBy: infosISN.referredBy,
      referTo: infosISN.referTo,
      additionalInformations: infosISN.additionalInformations,
      nil,
      attachingPart,
      isnId,
      availableConfIds,
      change,
      applicableVersion,
      isProcurable: infosISN.isProcurable,
      descriptionLocation: infosISN.descriptionLocation,
      applicabilityMD5: infosISN.applicabilityMD5,
      validities
    };
  }

  getInfosISNs1000d(currentIsn: Element): ISN {
    // QTY
    const quantityPerNextHigherAssy = currentIsn?.querySelector(
      "quantityPerNextHigherAssy"
    )?.innerHTML;
    // total QTY
    const totalQuantity = currentIsn?.querySelector("totalQuantity")?.innerHTML;
    // Nato Stock number
    const partSegment = currentIsn?.querySelector("partSegment");
    const nsn =
      partSegment?.querySelector("fullNatoStockNumber")?.innerHTML ??
      partSegment?.querySelector("natoStockNumber")?.innerHTML;
    // MFC && MPN
    const partRef = currentIsn && currentIsn.querySelector("partRef");
    const manufacturerCodeValue = partRef?.getAttribute("manufacturerCodeValue");
    const partNumberValue = partRef?.getAttribute("partNumberValue");
    const itemSeqNumberValue = currentIsn?.getAttribute("itemSeqNumberValue");

    // Refer to and referred by
    const referredBy = { label: "", link: "" };
    const referTo = { label: "", link: "" };
    const referNodes = currentIsn.querySelectorAll("referTo");
    let availableConfIds: string[];
    referNodes.forEach(referNode => {
      const targetLink = referNode.querySelector("catalogSeqNumberRef")?.getAttribute("xlink:href");
      // SPEC: xlink:href is of shape <DMC>##<csn>, we want to extract the DMC
      // We use a regex to match every characters up to the first "#" encountered
      const targetDmc = /^[^#]*/.exec(targetLink)[0];
      switch (referNode.getAttribute("refType")) {
        case "rft01":
          referredBy.label = this.store.getDUMeta(targetDmc)?.reference;
          referredBy.link = targetLink;
          break;
        case "rft02":
          referTo.label = this.store.getDUMeta(targetDmc)?.reference;
          referTo.link = targetLink;
          break;
        case "rft51":
          availableConfIds = Array.from(referNode.querySelectorAll("functionalItemRef")).map(
            (node: Element) => node.getAttribute("functionalItemNumber")
          );
          break;
        default:
          break;
      }
    });

    // Additonnal informations
    const elecItem = [];
    const tabElecItem = currentIsn?.querySelectorAll("itemSeqNumber > functionalItemRef");
    tabElecItem.forEach(item => {
      elecItem.push(item.getAttribute("functionalItemNumber"));
    });
    const smrCode = currentIsn?.querySelector("sourceMaintRecoverability")?.innerHTML || "";
    const unitOfIssue = currentIsn?.querySelector("unitOfIssue")?.innerHTML || "";
    const interchangeability = currentIsn?.querySelector("interchangeability")?.innerHTML || "";
    const descrForLocation = currentIsn?.querySelector("descrForLocation")?.innerHTML || "";
    const attachStoreShipPart =
      currentIsn?.querySelector("attachStoreShipPart")?.getAttribute("attachStoreShipPartCode") ||
      "";

    const additionalInformations: AdditionalInformations = {
      elecItem,
      smrCode,
      unitOfIssue,
      interchangeability,
      descrForLocation,
      attachStoreShipPart
    };

    const isProcurable = smrCode !== "XBZZZ";

    // Part description label
    let label = partSegment?.querySelector("descrForPart")?.innerHTML;

    const hasVendorDoc =
      currentIsn.querySelector("partRepository externalPubRefTitle")?.innerHTML?.trim() === "CMM";
    if (hasVendorDoc) {
      const trad = this.pubLangPipe.translate(
        "ipcDetails.labels.hasVendorDoc",
        this.store.pubInfo.lang
      );
      label += ` ${trad}`;
    }

    const applicabilityMD5 = currentIsn.getAttribute("applicability_md5");

    return {
      isnValue: itemSeqNumberValue,
      airbusPN: "",
      can: "",
      descriptionLocation: isProcurable ? "" : "(NP)",
      effectivity: [],
      equivalentPartNb: "",
      localManufact: "",
      mfc: manufacturerCodeValue,
      mpn: partNumberValue,
      qty: `${quantityPerNextHigherAssy} ${unitOfIssue}`,
      replacedBy: "",
      replaces: "",
      rfd: "",
      stock: "",
      totalqty: totalQuantity,
      additionalInformations,
      nsn,
      label,
      referredBy,
      referTo,
      availableConfIds,
      isProcurable,
      applicabilityMD5
    };
  }

  /**
   * Returns for a S1000D the part element with the given ID.
   *
   * @param itemID the ID of the part.
   * @returns the element with the given ID.
   */
  getS1000DPartElement(itemID: string): Element {
    const item = itemID.replace(/\D/g, "");
    return (this.store.ipcXML as Document).querySelector(`catalogSeqNumber[item='${item}']`);
  }

  /**
   * Get the version element for an S1000D.
   *
   * @param partNode the element in which we search the version element.
   * @param version the version to use.
   * @returns the version element.
   */
  getS1000DVersionElement(partNode: Element, version: string): Element {
    return partNode.querySelector(`itemSeqNumber[itemSeqNumberValue='00${version}']`);
  }

  /**
   * Get CSN element from the given hotspotId in Legacy
   *
   * @param hotspotId
   */
  getCSNFromHotspotId(hotspotId: string): Element {
    // SPEC : First we try to get the HOTSPOT element from the given hotspotId in the GRAPHIC part of the IPC's XML
    const hotspotEl = this.store.ipcXML.querySelector(`HOTSPOT[APSNAME='${hotspotId}']`);

    // SPEC : If no HOTSPOT is found, the part is not referenced in the figure (ex: the root "1" of an IPC), so we
    //  can use a direct query to the CSN using the correlation with the ITEM attribute of the CSN with the hotspotId.
    //  Note that this solution is not sure when we can use the HOTSPOT, that is why we do this onsly if we can't.
    if (!hotspotEl) {
      return this.store.ipcXML.querySelector(`CSN[ITEM='${hotspotId}']`);
    }

    // SPEC : If it exists, the ID attribute of the HOTSPOT is the same as the XREFID attribute of the XREF element
    //  that is son of the targeted CSN, so we can query it precisely.
    const id = hotspotEl.getAttribute("ID");
    return this.store.ipcXML.evaluate(
      `//XREF[@XREFID='${id}']/..`,
      this.store.ipcXML,
      undefined,
      XPathResult.FIRST_ORDERED_NODE_TYPE,
      undefined
    ).singleNodeValue;
  }

  /**
   *  Convert indenture to a number of points or stars.
   *
   * @param part
   * @returns
   * @memberof IpcService
   */
  public getIndentStr(part: IpcDetail): string {
    const identCar = !part.attachingPart ? "•••••••••••••" : "*************";
    return identCar.substr(0, part.indenture - 1);
  }

  /**
   * Function to get the table "Equivalent part number"
   */
  public getEquivalentPartNumber(partElement: Element, mpn: string): EPN[] {
    const partRefs = partElement.querySelectorAll(`partRef[partNumberValue='${mpn}']`);
    const epns: EPN[] = [];
    for (const partRef of Array.from(partRefs)) {
      const epnList = partRef.parentElement.querySelectorAll("partSegment partRef");
      for (const ref of Array.from(epnList)) {
        const manufacturerCodeValue = ref.getAttribute("manufacturerCodeValue");
        const partNumberValue = ref.getAttribute("partNumberValue");
        if (!epns.some(el => el.mpn === partNumberValue && el.mfc === manufacturerCodeValue)) {
          epns.push({
            mpn: partNumberValue,
            mfc: manufacturerCodeValue
            // NEXT UPGRADE: Equivalent Part Number with applicability inline
            // applicabilityMD5: applicabilityMD5
          });
        }
      }
    }
    return epns;
  }

  /**
   * Find the parameterIdent from the selectedParts
   * This function is only used for S1000D aircraft
   *
   * @returns
   */
  public findHotspotId(): string {
    return xpath
      .select(
        "string(//multimediaObject[@infoEntityIdent='" +
          this.store.currentMediaId +
          "']/parameter[@parameterName='HOT" +
          this.store.selectedParts +
          "']/@parameterIdent)",
        this.store.duObject.xml
      )
      .toString();
  }

  public compareHotspot(h1: string, h2: string): boolean {
    if (!h1 || !h2) {
      return false;
    }
    return h1.replace(/^0+|\D/g, "") === h2.replace(/^0+|\D/g, "");
  }

  // Construction of the data to be displayed at the top of the IPC tables.
  public setInitialProvisioningProject(): void {
    const initialProvisioningProject = this.store.ipcXML?.querySelector(
      "initialProvisioningProject"
    );

    if (initialProvisioningProject) {
      this.ipp = {
        number: initialProvisioningProject.getAttribute("initialProvisioningProjectNumber"),
        designation: initialProvisioningProject.getAttribute(
          "initialProvisioningProjectNumberSubject"
        )
      };
    }
  }

  /**
   * Get value with querySelectorAll
   *
   * @param currentIsn
   * @param selector
   */
  private getValues(currentIsn, selector: string) {
    return (
      currentIsn.querySelector(selector) &&
      Array.from(currentIsn.querySelectorAll(selector).values())
        .map((epn: Element) => epn.innerHTML)
        .join(", ")
    );
  }

  /**
   * Create card on IPC TOC Node
   *
   * @param key the hotspot ID.
   * @param details the details of the IPC.
   * @returns the current node.
   */
  private _createDataForIpcTocNode(key: string, details: IpcDetail): IpcTocData {
    // Inits index for mat tab group.
    this.detailsIdTab = 0;
    /**
     * We create a new node only if no node is passed, and if the current node
     * is not a version of the previous node.
     */
    const currentNode: IpcTocData = {
      key,
      indenture: details.indenture,
      hotspot: details.hotspotId,
      version: [],
      children: [],
      attachingParts: [],
      fittingVariants: []
    };

    // Each node that is not a attachingpart is potentially a parentForAttachingPart.
    if (!details.attachingPart) {
      this.parentForAttachingPart = currentNode;
    }

    // Each node that is not a fittingVariant is potentially a parentForFittingPart.
    if (!details.fittingVariant) {
      this.parentForFittingPart = currentNode;
    }

    /**
     * Each node that is neither an attachingpart, nor a fittingVariant,
     * is potentially a parentForIndenture.
     */
    if (!details.attachingPart && !details.fittingVariant) {
      this.parentForIndenture[currentNode.indenture] = currentNode;
    }
    if (details.indenture === 1) {
      // Only push into a node if its level is 1
      this.items.push(currentNode);
    } else {
      this._pushIpcTocNodeDataToParent(currentNode, details);
    }
    return currentNode;
  }

  private retrieveLocalManufact(node: Element): string {
    const localManufact: Element = node.querySelector("SMF[VALUE='M']>MFM");
    if (localManufact) {
      return this.translate.instant("ipcDetails.labels.localManufM", {
        mfm: localManufact.innerHTML
      });
    }
    // In case of not handled VALUE or no SMF
    const mfm = node.querySelector("MFM");
    return mfm && mfm.innerHTML;
  }

  private getApplicableVersions(): string {
    const applicList = this.store.ipcXML.querySelectorAll("WP6ApplicList>applic");
    const versions = Array.from(applicList).map((applic: Element) =>
      applic.getAttribute("version")
    );
    return versions.join(", ");
  }

  /**
   * Returns a boolean indicating whether the current ISN is procurable.
   *
   * @param mpn the "PNR" element of the ISN.
   * @param alternateParts array of "DFL" elements.
   * @returns true if the current ISN is procurable, false otherwise.
   */
  private _isProcurable(mpn: string, alternateParts: string[]): boolean {
    return mpn && !isNoNumber(mpn) && !alternateParts.includes("(NP)");
  }

  /**
   * Get the descriptionLocation attribute of an ISN.
   *
   * @param alternateParts array of "DFL" elements.
   * @param isProcurable if the ISN is procurable.
   * @returns the description of the location.
   */
  private _getDescriptionLocation(alternateParts: string[], isProcurable: boolean): string {
    let descriptionLocation = alternateParts
      .filter((alternatePart: string) => alternatePart !== "(NP)")
      .join("\n");
    if (!isProcurable) {
      descriptionLocation = "(NP)\n" + descriptionLocation;
    }
    return descriptionLocation;
  }

  /**
   * Get the message to display about the stock.
   *
   * @param stock the stock information.
   * @returns the information to display about the stock.
   */
  private _getDisplayedStock(stock: string): string {
    switch (stock) {
      case "C":
        return this.translate.instant("ipcDetails.labels.stockAvailableSpecificDeliveryTime");
      case "NOTAVA":
        return this.translate.instant("ipcDetails.labels.stockNotAvailable");
      case "SPECLT":
        return this.translate.instant("ipcDetails.labels.stockOrderSpecificLeadTime");
      case "STANREP":
        return this.translate.instant("ipcDetails.labels.stockStandardExchange");
      case "STOCK":
        return this.translate.instant("ipcDetails.labels.stockStock");
      default:
        return stock;
    }
  }

  /**
   * Returns for an S1000D the ID of the media (picture) related to the current
   * hotspot.
   *
   * @param markHotspot the hotspot element in the XML of the IPC.
   * @param hotspotId the ID of the current hotspot.
   * @returns the ID of the media related to the current hotspot.
   */
  private _getMediaIDS1000D(markHotspot: Element, hotspotId: string): string {
    let mediaID: string;
    if (markHotspot?.querySelector("internalRef")) {
      const internalRefId = markHotspot.querySelector("internalRef").getAttribute("internalRefId");
      const infoEntityIdent = this.store.ipcXML.querySelector(`hotspot[id='${internalRefId}']`);
      if (infoEntityIdent) {
        mediaID = infoEntityIdent.parentElement.getAttribute("infoEntityIdent");
      }
    }
    if (!mediaID && !this.store.isPlayerActivated) {
      // SPEC: If the hotpots are not present in the xml and we are in ipc S1000D 2D
      // We get mediaId from store.ipcHotspotMap without the leading zeros.
      mediaID = this.store.ipcHotspotMap?.get(this.store.currentDMC)?.[
        hotspotId.replace(/^0+/g, "")
      ]?.[0];
    }
    return mediaID;
  }

  /**
   * Set the current node version.
   * If the ISN does not have applicability_md5, we fallback to mountable ISN.
   *
   * @param currentNode the current node.
   * @param details the details of the current node.
   * @param isn the ISN of the node.
   * @param isnArray an array of ISN.
   */
  private setCurrentNodeVersion(
    currentNode: IpcTocData,
    details: IpcDetail,
    isn: Element,
    isnArray: Element[]
  ): void {
    if (
      this.detailsIdTab === 0 ||
      (this.detailsIdTab > 0 &&
        currentNode.version[this.detailsIdTab - 1].isnValue !== details.isnValue)
    ) {
      /**
       * Fill node with node details and index for mat tab.
       * Add version to the current card.
       */
      details.idTab = this.detailsIdTab;
      currentNode.version.push(details);
      this.detailsIdTab++;
    } else {
      // If the isn does not have applicability_md5, we fallback to mountable isn (partStatus = pst51).
      const applicabilityMD5 =
        isn.getAttribute("applicability_md5") ||
        isnArray
          .find((isnItem: Element) => isnItem.getAttribute("partStatus") === "pst51")
          ?.getAttribute("applicability_md5");
      currentNode.version[this.detailsIdTab - 1].applicabilityMD5 = applicabilityMD5;
    }
  }

  /**
   * Pushes to the "parentFor…" arrays data from the details of the
   * current node. It is only done if it respects the conditions.
   *
   * @param currentNode the current IPC node.
   * @param details the details of the node.
   */
  private _pushIpcTocNodeDataToParent(currentNode: IpcTocData, details: IpcDetail): void {
    // If the node is an attachingPart, we attach it to the parentForAttachingPart node.
    if (details.attachingPart) {
      this.parentForAttachingPart.attachingParts.push(currentNode);
    }

    // If the node is a fittingVariant, we attach it to the parentForFittingVariant node.
    if (details.fittingVariant) {
      this.parentForFittingPart.fittingVariants.push(currentNode);
    }

    /**
     * If the node is neither an attachingPart, nor a fittingVariant,
     * we attach it to the parentForFittingVariant node.
     */
    if (!details.attachingPart && !details.fittingVariant) {
      /**
       * SPEC: We can have holes in parentForIndenture.
       * So starting from currentNode.indenture - 1 we decrement indenture
       * until we find the first existing parent node.
       */
      let i = currentNode.indenture - 1;
      while (!this.parentForIndenture[i] && i > 0) {
        i--;
      }
      this.parentForIndenture[i]?.children.push(currentNode);
    }
  }

  private getValidities(xmlNode: Element): Validity[] {
    const locationRcmd = xpath.select(".//locationRcmd", xmlNode);

    // For each element in the "locationRcmd" XML tag.
    const validities = locationRcmd.map((lrcmd: Element) => {
      return {
        srv: xpath.select("string(service)", lrcmd).toString(),
        smr: xpath.select("string(sourceMaintRecoverability)", lrcmd).toString(),
        mov: xpath
          .select("modelVersion/@modelVersionValue", lrcmd)
          .map((node: Node) => node.nodeValue + "/ ")
          .join(" ")
      };
    });

    return validities;
  }
}
