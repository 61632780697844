import { Component, HostListener } from "@angular/core";
import { Location } from "@angular/common";
import { Router } from "@angular/router";
import { environment } from "@viewer-env/environment";
import { HistoricService } from "@viewer/core/toc-items/historic.service";
import { Store } from "@viewer/core/state/store";

@Component({
  standalone: false,
  selector: "o-history-switch",
  templateUrl: "./history-switch.component.html",
  styleUrls: ["./history-switch.component.scss"]
})
export class HistorySwitchComponent {
  constructor(
    private location: Location,
    private router: Router,
    private historicService: HistoricService,
    private store: Store
  ) {}

  @HostListener("window:popstate", ["$event"])
  public onPopState(_event: Event): void {
    // This event is trigger if the user click on the back button from the app or the browser
    this.historicService.backTo();
  }

  back() {
    // For electron the deeplinking create url with # between racin and the rest then redirects to the right URL.
    // The previous page, so become the page with the # in the middle of the URL
    // window.history.state.state.navigationId allows you to retrieve the navigation ID
    if (environment.platform === "electron" && window.history.state.navigationId === 1) {
      return this.router.navigate(["/"]);
    }

    // SPEC: Save position of the scroll before doing a location back
    // Because when we're going back from loap to /pubs/online, the navigation is done before really quitting the publication
    this.store.scrollYSave = window.scrollY;

    return this.location.back();
  }
}
