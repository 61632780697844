import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ItemBasket } from "@orion2/models/tocitem.models";

export interface InformationDialogData {
  dialogTitle: string;
  sections: [
    {
      title: string;
      informations: string[];
    }
  ];
}

@Component({
  standalone: false,
  selector: "o-information-dialog",
  templateUrl: "./information-dialog.component.html",
  styleUrls: ["./information-dialog.component.scss"]
})
export class InformationDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: InformationDialogData) {}

  /**
   * Remove "Fig. " from string
   *
   * @param reference string from which the substring will removed
   */
  public formatReference(reference: string): string {
    return reference.replace("Fig. ", "");
  }
}
