import { Component } from "@angular/core";

@Component({
  standalone: false,
  selector: "o-empty",
  templateUrl: "./empty.component.html"
})
/**
 * Empty component.
 * Component is mandatory for every route, but nothing to do here.
 */
export class EmptyComponent {}
