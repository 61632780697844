export const infoDuXSL = `<?xml version="1.0" encoding="UTF-8"?>
<xsl:stylesheet xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:xsl="http://www.w3.org/1999/XSL/Transform" version="1.0">
<xsl:output doctype-public="doctype html" encoding="iso-8859-1" indent="yes" method="html"/>

<xsl:param name="UPPER_CASE_LETTER">ABCDEFGHIJKLMNOPQRSTUVWXYZ</xsl:param>
<xsl:param name="LOWER_CASE_LETTER">abcdefghijklmnopqrstuvwxyz</xsl:param>

<xsl:param name="isMobile" select="false()"/>
<xsl:param name="CHANGEMARK_CARRE" select="'changemarkCarre'"/>
<xsl:param name="STATUS_DIV_CLASS">duStatusDiv</xsl:param>
<xsl:param name="CHANGEMARK_LEGEND" select="&quot;[CHANGEMARK_LEGEND_LABEL] &quot;"/>

<xsl:param name="STATUS_AUTHOR_ELEMENT" select="&quot;[STATUS_AUTHOR_LABEL]&quot;"/>
<xsl:param name="STATUS_DATAMODULE_CODE_ELEMENT" select="&quot;[STATUS_DATAMODULE_CODE_LABEL]&quot;"/>
<xsl:param name="STATUS_EDITION_NUMBER_ELEMENT" select="&quot;[STATUS_EDITION_NUMBER_LABEL]&quot;"/>
<xsl:param name="STATUS_FIRST_VERIFICATION_ELEMENT" select="&quot;[STATUS_FIRST_VERIFICATION_LABEL]&quot;"/>
<xsl:param name="STATUS_SECOND_VERIFICATION_ELEMENT" select="&quot;[STATUS_SECOND_VERIFICATION_LABEL]&quot;"/>
<xsl:param name="STATUS_QUALITY_ASSURANCE_STATUS_ELEMENT" select="&quot;[STATUS_QUALITY_ASSURANCE_STATUS_LABEL]&quot;"/>
<xsl:param name="STATUS_RESPONSIBLE_PARTNER_COMPANY_ELEMENT" select="&quot;[STATUS_RESPONSIBLE_PARTNER_COMPANY_LABEL]&quot;"/>
<xsl:param name="STATUS_SBC_ELEMENT" select="'SBC'"/>
<xsl:param name="STATUS_SECURITY_CLASSIFICATION_ELEMENT" select="&quot;[STATUS_SECURITY_CLASSIFICATION_LABEL]&quot;"/>
<xsl:param name="STATUS_TECHNICAL_STANDARD_BASE_TECHNICAL_PUBLICATION_ELEMENT" select="&quot;[STATUS_BASE_TECHNICAL_PUBLICATION_LABEL]&quot;"/>
<xsl:param name="STATUS_TECHNICAL_STANDARD_ELEMENT" select="&quot;[STATUS_TECHNICAL_STANDARD_LABEL]&quot;"/>
<xsl:param name="STATUS_TECHNICAL_STANDARD_NOTE_ELEMENT" select="&quot;[STATUS_NOTE_LABEL]&quot;"/>
<xsl:param name="STATUS_UNVERIFIED_ELEMENT" select="&quot;[STATUS_UNVERIFIED_LABEL]&quot;"/>
<xsl:param name="STATUS_VERIFICATION_TYPE_TAB_TOP_ELEMENT" select="&quot;[STATUS_VERIFICATION_TYPE_TAB_TOP_LABEL]&quot;"/>
<xsl:param name="STATUS_VERIFICATION_TYPE_ON_OBJECT_ELEMENT" select="&quot;[STATUS_VERIFICATION_TYPE_ON_OBJECT_LABEL]&quot;"/>

<!-- Security classification -->

<xsl:param name="STATUS_SECURITY_CLASSIFICATION_CONFIDENTIAL_ELEMENT" select="&quot;[STATUS_SECURITY_CLASSIFICATION_CONFIDENTIAL_LABEL]&quot;"/>
<xsl:param name="STATUS_SECURITY_CLASSIFICATION_RESTRICTED_ELEMENT" select="&quot;[STATUS_SECURITY_CLASSIFICATION_RESTRICTED_LABEL]&quot;"/>
<xsl:param name="STATUS_SECURITY_CLASSIFICATION_SECRET_ELEMENT" select="&quot;[STATUS_SECURITY_CLASSIFICATION_SECRET_LABEL]&quot;"/>
<xsl:param name="STATUS_SECURITY_CLASSIFICATION_UNCLASSIFIED_ELEMENT" select="&quot;[STATUS_SECURITY_CLASSIFICATION_UNCLASSIFIED_LABEL]&quot;"/>

<!-- END Security classification -->



<xsl:param name="STATUS_DISPLAYED_CLASS">status_displayed</xsl:param>
<xsl:param name="STATUS_HIDDEN_CLASS">status_hidden</xsl:param>
<xsl:param name="STATUS_TAB_TITLE" select="&quot;[STATUS_ADDRESS_STATUS_REFERENCE_LABEL]&quot;"/>
<xsl:param name="STATUS_TABLE_LABEL_CLASS" select="'status_table_label_class'"/>
<xsl:param name="STATUS_REFERENCE_ELEMENT" select="'[STATUS_REFERENCE_LABEL]'"/>
<xsl:param name="STATUS_TABLE_TITLE_CLASS" select="'status_table_title_class'"/>
<xsl:param name="STATUS_DMCODE_ELEMENT" select="&quot;[STATUS_DMC_LABEL]&quot;"/>
<xsl:param name="STATUS_TABLE_VALUE_CLASS" select="'status_table_value_class'"/>
<xsl:param name="UNDERSCORE_SEPARATOR" select="&quot; - &quot;"/>
<xsl:param name="STATUS_LANGAGE_ELEMENT" select="&quot;[STATUS_LANGUAGE_LABEL]&quot;"/>
<xsl:param name="OPENING_PARENTHESIS" select="&quot;(&quot;"/>
<xsl:param name="CLOSING_PARENTHESIS" select="&quot;)&quot;"/>
<xsl:param name="SPACE" select="' '"/>
<xsl:param name="STATUS_ISSUEDATE_ELEMENT" select="&quot;[STATUS_EDITION_DATE_LABEL]&quot;"/>
<xsl:param name="DATE_SEPARATOR" select="&quot;.&quot;"/>
<xsl:param name="STATUS_APPLICABILITY_ELEMENT" select="&quot;[STATUS_APPLICABILITY_LABEL]&quot;"/>
<xsl:param name="STATUS_APPLICABILITIES_SHOW_HIDE" select="'applicabilities_show_hide'"/>
<xsl:param name="STATUS_APPLICABILITIES_DISPLAYED" select="'applicabilities_displayed'"/>
<xsl:param name="DISPLAY_NONE" select="'display: none;'"/>
<xsl:param name="DISPLAY_INLINE" select="'display: inline;'"/>
<xsl:param name="STATUS_APPLICABILITIES_HIDDEN" select="'applicabilities_hidden'"/>
<xsl:param name="STATUS_SHOW_HIDE_APPLICABILITIES_ELEMENT" select="'[STATUS_SHOW_HIDE_APPLICABILITIES_LABEL]'"/>
<xsl:param name="STATUS_APPLICABILITY_MODEL_ELEMENT" select="&quot;[STATUS_APPLICABILITY_MODEL_LABEL]&quot;"/>
<xsl:param name="STATUS_APPLICABILITY_VERSION_ELEMENT" select="&quot;[STATUS_APPLICABILITY_VERSION_LABEL]&quot;"/>
<xsl:param name="COMMA" select="&quot;,&quot;"/>
<xsl:param name="STATUS_APPLICABILITY_SERIALNBR_ELEMENT" select="&quot;[STATUS_APPLICABILITY_SERIALNBR_LABEL]&quot;"/>
<xsl:param name="STATUS_STATUS_ELEMENT" select="&quot;[STATUS_STATUS_TITLE_LABEL]&quot;"/>
<xsl:param name="STATUS_STATUS_SUBTITLE" select="&quot;[STATUS_STATUS_LABEL]&quot;"/>
<xsl:param name="STATUS_MAINT_AFFECTED_ELEMENT_DESCRIPTION" select="'[STATUS_MAINT_AFFECTED_DESCRIPTION_LABEL]'"/>
<xsl:param name="STATUS_REASONFORUPDATE_ELEMENT" select="&quot;[STATUS_UPDATING_REASON_LABEL]&quot;"/>
<xsl:param name="STATUS_MAINT_AFFECTED_ELEMENT" select="&quot;[STATUS_MAINT_AFFECTED_LABEL]&quot;"/>
<xsl:param name="TRUE" select="&quot;[TRUE_LABEL]&quot;"/>
<xsl:param name="FALSE" select="&quot;[FALSE_LABEL]&quot;"/>

<xsl:template match="identAndStatusSection" name="identAndStatusSection">
    <xsl:if test="//node()[@CHANGEMARK='1'] or //node()[@changemark='1']">
        <xsl:element name="div">
            <xsl:element name="span">
                <xsl:attribute name="class">
                    <xsl:value-of select="$CHANGEMARK_CARRE"/>
                </xsl:attribute>
            </xsl:element>
            <xsl:element name="span">
                <xsl:attribute name="style">font-weight:bold</xsl:attribute>
                <xsl:value-of select="$CHANGEMARK_LEGEND"/>
            </xsl:element>
        </xsl:element>
    </xsl:if>

    <xsl:if test="not($isMobile)">
        <xsl:element name="div">
            <xsl:attribute name="class"><xsl:value-of select="$STATUS_DIV_CLASS"/></xsl:attribute>
            <xsl:apply-templates select="child::node()"/>
        </xsl:element>
    </xsl:if>
</xsl:template>

<xsl:template match="dmAddress" name="translateDmAddress">
    <xsl:element name="table">
        <xsl:attribute name="class">
            <xsl:value-of select="'status_table'"/>
        </xsl:attribute>

        <!-- Start of Reference -->

        <xsl:element name="tr">
            <xsl:element name="th">
                <xsl:attribute name="class">
                    <xsl:value-of select="$STATUS_TABLE_LABEL_CLASS"/>
                </xsl:attribute>
                <xsl:attribute name="colspan">
                    <xsl:value-of select="2"/>
                </xsl:attribute>
                <xsl:value-of select="$STATUS_REFERENCE_ELEMENT"/>
            </xsl:element>
        </xsl:element>

        <!-- Data Module Code -->
        <xsl:variable name="dmc" select="./ancestor::*/WP6Status/dmc"/>
        <xsl:if test="$dmc != ''">
            <xsl:element name="tr">
                <xsl:element name="td">
                    <xsl:attribute name="class">
                        <xsl:value-of select="$STATUS_TABLE_TITLE_CLASS"/>
                    </xsl:attribute>
                    <xsl:value-of select="$STATUS_DATAMODULE_CODE_ELEMENT"/>
                </xsl:element>
                <xsl:element name="td">
                    <xsl:attribute name="class">
                        <xsl:value-of select="$STATUS_TABLE_VALUE_CLASS"/>
                    </xsl:attribute>
                    <xsl:value-of select="./ancestor::*/WP6Status/dmc"/>
                </xsl:element>
            </xsl:element>
        </xsl:if>

        <!-- Documentary Unit -->
        <xsl:if test="not(dmIdent/dmCode[@systemCode='00' and @subSystemCode='0' and @subSubSystemCode='0' and @assyCode='00' and @disassyCode='00' and @disassyCodeVariant='000'])">
            <xsl:element name="tr">
                <xsl:element name="td">
                    <xsl:attribute name="class">
                        <xsl:value-of select="$STATUS_TABLE_TITLE_CLASS"/>
                    </xsl:attribute>
                    <xsl:value-of select="$STATUS_DMCODE_ELEMENT"/>
                </xsl:element>
                <xsl:element name="td">
                    <xsl:attribute name="class">
                        <xsl:value-of select="$STATUS_TABLE_VALUE_CLASS"/>
                    </xsl:attribute>
                    <xsl:attribute name="id">
                        <xsl:value-of select="'DMC_VALUE'"/>
                    </xsl:attribute>
                    <xsl:value-of select="./ancestor::*/WP6Status/shortDmc"/>
                </xsl:element>
            </xsl:element>
        </xsl:if>

        <!-- References/Effectivity -->
        <xsl:variable name="techName" select="./dmAddressItems/dmTitle/techName"/>
        <xsl:if test="$techName != ''">
            <xsl:element name="tr">
                <xsl:element name="td">
                    <xsl:attribute name="class">
                        <xsl:value-of select="$STATUS_TABLE_TITLE_CLASS"/>
                    </xsl:attribute>
                    <xsl:value-of select="$STATUS_TAB_TITLE"/>
                </xsl:element>
                <xsl:element name="td">
                    <xsl:attribute name="class">
                        <xsl:value-of select="$STATUS_TABLE_VALUE_CLASS"/>
                    </xsl:attribute>

                    <xsl:variable name="infoName" select="./dmAddressItems/dmTitle/infoName"/>
                    <xsl:choose>
                        <xsl:when test="$infoName != ''">
                            <xsl:value-of select="concat($techName,  $UNDERSCORE_SEPARATOR, $infoName)"/>
                        </xsl:when>
                        <xsl:otherwise>
                            <xsl:value-of select="$techName"/>
                        </xsl:otherwise>
                    </xsl:choose>
                </xsl:element>
            </xsl:element>
        </xsl:if>

        <!-- Issue number -->
        <xsl:variable name="issueNumber" select="./dmIdent/issueInfo/@issueNumber"/>
        <xsl:variable name="issueType" select="../dmStatus/@issueType"/>
        <xsl:if test="$issueNumber != '' and $issueType != ''">
            <xsl:element name="tr">
                <xsl:element name="td">
                    <xsl:attribute name="class">
                        <xsl:value-of select="$STATUS_TABLE_TITLE_CLASS"/>
                    </xsl:attribute>
                    <xsl:value-of select="$STATUS_EDITION_NUMBER_ELEMENT"/>
                </xsl:element>
                <xsl:element name="td">
                    <xsl:attribute name="class">
                        <xsl:value-of select="$STATUS_TABLE_VALUE_CLASS"/>
                    </xsl:attribute>
                    <xsl:value-of select="concat($issueNumber, $SPACE, $OPENING_PARENTHESIS, translate($issueType, $LOWER_CASE_LETTER, $UPPER_CASE_LETTER), $CLOSING_PARENTHESIS)"/>
                </xsl:element>
            </xsl:element>
        </xsl:if>

        <!-- Language -->
        <xsl:variable name="language" select="./dmIdent/language"/>
        <xsl:if test="$language">
            <xsl:element name="tr">
                <xsl:element name="td">
                    <xsl:attribute name="class">
                        <xsl:value-of select="$STATUS_TABLE_TITLE_CLASS"/>
                    </xsl:attribute>
                    <xsl:value-of select="$STATUS_LANGAGE_ELEMENT"/>
                </xsl:element>
                <xsl:element name="td">
                    <xsl:attribute name="class">
                        <xsl:value-of select="$STATUS_TABLE_VALUE_CLASS"/>
                    </xsl:attribute>
                    <xsl:value-of select="concat($language/@countryIsoCode, $SPACE, $OPENING_PARENTHESIS, $language/@languageIsoCode, $CLOSING_PARENTHESIS, $SPACE)"/>
                </xsl:element>
            </xsl:element>
        </xsl:if>

        <!-- Issue date -->
        <xsl:variable name="issueDate" select="dmAddressItems/issueDate"/>
        <xsl:if test="$issueDate[@day != ''] and $issueDate[@month != ''] and $issueDate[@year != '']">
            <xsl:element name="tr">
                <xsl:element name="td">
                    <xsl:attribute name="class">
                        <xsl:value-of select="$STATUS_TABLE_TITLE_CLASS"/>
                    </xsl:attribute>
                    <xsl:value-of select="$STATUS_ISSUEDATE_ELEMENT"/>
                </xsl:element>
                <xsl:element name="td">
                    <xsl:attribute name="class">
                        <xsl:value-of select="$STATUS_TABLE_VALUE_CLASS"/>
                    </xsl:attribute>
                    <xsl:value-of select="concat($issueDate/@year, $DATE_SEPARATOR, $issueDate/@month, $DATE_SEPARATOR, $issueDate/@day)"/>
                </xsl:element>
            </xsl:element>
        </xsl:if>

        <!-- End of Reference -->

        <!-- Start of Effectivity -->

        <xsl:element name="tr">
            <xsl:element name="th">
                <xsl:attribute name="class">
                    <xsl:value-of select="$STATUS_TABLE_LABEL_CLASS"/>
                </xsl:attribute>
                <xsl:attribute name="colspan">
                    <xsl:value-of select="2"/>
                </xsl:attribute>
                <xsl:value-of select="$STATUS_APPLICABILITY_ELEMENT"/>
            </xsl:element>
        </xsl:element>

        <xsl:element name="tr">
            <xsl:element name="td">
                <xsl:attribute name="class">
                    <xsl:value-of select="$STATUS_APPLICABILITIES_SHOW_HIDE"/>
                </xsl:attribute>
                <xsl:attribute name="colspan">
                    <xsl:value-of select="2"/>
                </xsl:attribute>
                <xsl:value-of select="$SPACE"/>
                <xsl:value-of select="$STATUS_SHOW_HIDE_APPLICABILITIES_ELEMENT"/>
            </xsl:element>
        </xsl:element>

        <xsl:for-each select="ancestor::*/WP6Status/WP6ApplicList">

            <!-- Model -->
            <xsl:if test="descendant::applic[@model != '']">
                <xsl:element name="tr">
                    <xsl:element name="td">
                        <xsl:attribute name="class">
                            <xsl:value-of select="$STATUS_TABLE_TITLE_CLASS"/>
                        </xsl:attribute>
                        <xsl:value-of select="$STATUS_APPLICABILITY_MODEL_ELEMENT"/>
                    </xsl:element>
                    <xsl:element name="td">
                        <xsl:attribute name="class">
                            <xsl:value-of select="$STATUS_TABLE_VALUE_CLASS"/>
                        </xsl:attribute>
                        <xsl:for-each select="applic[@model != '']">
                            <xsl:choose>
                                <xsl:when test="position() = 1">
                                    <xsl:value-of select="./@model"/>
                                </xsl:when>
                                <xsl:otherwise>
                                    <xsl:value-of select="concat($COMMA, $SPACE, ./@model)"/>
                                </xsl:otherwise>
                            </xsl:choose>
                        </xsl:for-each>
                    </xsl:element>
                </xsl:element>
            </xsl:if>

            <!-- Version -->
            <xsl:if test="descendant::applic[@version != '']">
                <xsl:element name="tr">
                    <xsl:element name="td">
                        <xsl:attribute name="class">
                            <xsl:value-of select="$STATUS_TABLE_TITLE_CLASS"/>
                        </xsl:attribute>
                        <xsl:value-of select="$STATUS_APPLICABILITY_VERSION_ELEMENT"/>
                    </xsl:element>
                    <xsl:element name="td">
                        <xsl:attribute name="class">
                            <xsl:value-of select="$STATUS_TABLE_VALUE_CLASS"/>
                        </xsl:attribute>
                        <xsl:for-each select="applic[@version != '']">
                            <xsl:choose>
                                <xsl:when test="position() = 1">
                                    <xsl:value-of select="./@version"/>
                                </xsl:when>
                                <xsl:otherwise>
                                    <xsl:value-of select="concat($COMMA, $SPACE, ./@version)"/>
                                </xsl:otherwise>
                            </xsl:choose>
                        </xsl:for-each>
                    </xsl:element>
                </xsl:element>
            </xsl:if>

            <!-- Serial number -->
            <xsl:if test="descendant::applic[@serialno != '']">
                <xsl:element name="tr">
                    <xsl:element name="td">
                        <xsl:attribute name="class">
                            <xsl:value-of select="$STATUS_TABLE_TITLE_CLASS"/>
                        </xsl:attribute>
                        <xsl:value-of select="$STATUS_APPLICABILITY_SERIALNBR_ELEMENT"/>
                    </xsl:element>
                    <xsl:element name="td">
                        <xsl:attribute name="class">
                            <xsl:value-of select="concat($STATUS_TABLE_VALUE_CLASS, ' applicability_serial_numbers_value')"/>
                        </xsl:attribute>
                        <xsl:for-each select="applic[@serialno !='']">
                            <xsl:choose>
                                <xsl:when test="position() = 1">
                                    <xsl:value-of select="./@serialno"/>
                                </xsl:when>
                                <xsl:otherwise>
                                    <xsl:value-of select="concat($COMMA, $SPACE, ./@serialno)"/>
                                </xsl:otherwise>
                            </xsl:choose>
                        </xsl:for-each>
                    </xsl:element>
                </xsl:element>
            </xsl:if>

        </xsl:for-each>

        <!-- End of Effectivity -->

        <!-- Start of Status -->

        <xsl:element name="tr">
            <xsl:element name="th">
                <xsl:attribute name="class">
                    <xsl:value-of select="$STATUS_TABLE_LABEL_CLASS"/>
                </xsl:attribute>
                <xsl:attribute name="colspan">
                    <xsl:value-of select="2"/>
                </xsl:attribute>
                <xsl:value-of select="$STATUS_STATUS_ELEMENT"/>
            </xsl:element>
        </xsl:element>

        <!-- Security classification -->
        <xsl:variable name="security" select="./following-sibling::dmStatus/security"/>
        <xsl:if test="$security[@securityClassification != '']">
            <xsl:element name="tr">
                <xsl:element name="td">
                    <xsl:attribute name="class">
                        <xsl:value-of select="$STATUS_TABLE_TITLE_CLASS"/>
                    </xsl:attribute>
                    <xsl:value-of select="$STATUS_SECURITY_CLASSIFICATION_ELEMENT"/>
                </xsl:element>
                <xsl:element name="td">
                    <xsl:attribute name="class">
                        <xsl:value-of select="$STATUS_TABLE_VALUE_CLASS"/>
                    </xsl:attribute>
                    <xsl:call-template name="getSecurityClassificationAndCaveat">
                      <xsl:with-param name="security" select="$security"/>
                    </xsl:call-template>
                </xsl:element>
            </xsl:element>
        </xsl:if>

        <!-- Responsible partner company -->
        <xsl:variable name="responsiblePartnerCompany" select="following-sibling::dmStatus/responsiblePartnerCompany/@enterpriseCode"/>
        <xsl:if test="$responsiblePartnerCompany != ''">
            <xsl:element name="tr">
                <xsl:element name="td">
                    <xsl:attribute name="class">
                        <xsl:value-of select="$STATUS_TABLE_TITLE_CLASS"/>
                    </xsl:attribute>
                    <xsl:value-of select="$STATUS_RESPONSIBLE_PARTNER_COMPANY_ELEMENT"/>
                </xsl:element>
                <xsl:element name="td">
                    <xsl:attribute name="class">
                        <xsl:value-of select="$STATUS_TABLE_VALUE_CLASS"/>
                    </xsl:attribute>
                    <xsl:call-template name="getResponsiblePartnerCompany">
                      <xsl:with-param name="responsiblePartnerCompany" select="$responsiblePartnerCompany"/>
                    </xsl:call-template>
                </xsl:element>
            </xsl:element>
        </xsl:if>

        <!-- Originator -->
        <xsl:variable name="originator" select="following-sibling::dmStatus/originator/@enterpriseCode"/>
        <xsl:if test="$originator != ''">
            <xsl:element name="tr">
                <xsl:element name="td">
                    <xsl:attribute name="class">
                        <xsl:value-of select="$STATUS_TABLE_TITLE_CLASS"/>
                    </xsl:attribute>
                    <xsl:value-of select="$STATUS_AUTHOR_ELEMENT"/>
                </xsl:element>
                <xsl:element name="td">
                    <xsl:attribute name="class">
                        <xsl:value-of select="$STATUS_TABLE_VALUE_CLASS"/>
                    </xsl:attribute>
                    <xsl:call-template name="getOriginator">
                      <xsl:with-param name="originator" select="$originator"/>
                    </xsl:call-template>
                </xsl:element>
            </xsl:element>
        </xsl:if>

        <!-- Quality assurance status -->
        <xsl:variable name="qualityAssurance" select="./following-sibling::dmStatus/qualityAssurance"/>
        <xsl:if test="$qualityAssurance/*">
            <xsl:element name="tr">
                <xsl:element name="td">
                    <xsl:attribute name="class">
                        <xsl:value-of select="$STATUS_TABLE_TITLE_CLASS"/>
                    </xsl:attribute>
                    <xsl:value-of select="$STATUS_QUALITY_ASSURANCE_STATUS_ELEMENT"/>
                </xsl:element>
                <xsl:element name="td">
                    <xsl:attribute name="class">
                        <xsl:value-of select="$STATUS_TABLE_VALUE_CLASS"/>
                    </xsl:attribute>
                    <xsl:call-template name="getQualityAssuranceStatus">
                        <xsl:with-param name="qualityAssurance" select="$qualityAssurance"/>
                    </xsl:call-template>
                </xsl:element>
            </xsl:element>
        </xsl:if>

        <!-- System breakdown (SBC) -->
        <xsl:variable name="systemBreakdownCode" select="./following-sibling::dmStatus/systemBreakdownCode"/>
        <xsl:if test="$systemBreakdownCode != ''">
            <xsl:element name="tr">
                <xsl:element name="td">
                    <xsl:attribute name="class">
                        <xsl:value-of select="$STATUS_TABLE_TITLE_CLASS"/>
                    </xsl:attribute>
                    <xsl:value-of select="$STATUS_SBC_ELEMENT"/>
                </xsl:element>
                <xsl:element name="td">
                    <xsl:attribute name="class">
                        <xsl:value-of select="$STATUS_TABLE_VALUE_CLASS"/>
                    </xsl:attribute>
                    <xsl:value-of select="$systemBreakdownCode"/>
                </xsl:element>
            </xsl:element>
        </xsl:if>

        <!-- Type of modification -->
        <xsl:variable name="updateReasonType" select="./following-sibling::dmStatus/reasonForUpdate/@updateReasonType"/>

        <xsl:if test="$issueType != '' or $updateReasonType != ''">
            <xsl:element name="tr">
                <xsl:element name="td">
                    <xsl:attribute name="class">
                        <xsl:value-of select="$STATUS_TABLE_TITLE_CLASS"/>
                    </xsl:attribute>
                    <xsl:value-of select="$STATUS_STATUS_SUBTITLE"/>
                </xsl:element>
                <xsl:element name="td">
                    <xsl:attribute name="class">
                        <xsl:value-of select="$STATUS_TABLE_VALUE_CLASS"/>
                    </xsl:attribute>
                    <xsl:call-template name="getStatus">
                        <xsl:with-param name="status" select="$issueType"/>
                        <xsl:with-param name="urtCode" select="$updateReasonType"/>
                    </xsl:call-template>
                </xsl:element>
            </xsl:element>
        </xsl:if>

        <!-- Description -->
        <xsl:if test="$updateReasonType != ''">
            <xsl:element name="tr">
                <xsl:element name="td">
                    <xsl:attribute name="class">
                        <xsl:value-of select="$STATUS_TABLE_TITLE_CLASS"/>
                    </xsl:attribute>
                    <xsl:value-of select="$STATUS_MAINT_AFFECTED_ELEMENT_DESCRIPTION"/>
                </xsl:element>
                <xsl:element name="td">
                    <xsl:attribute name="class">
                        <xsl:value-of select="$STATUS_TABLE_VALUE_CLASS"/>
                    </xsl:attribute>
                    <xsl:call-template name="getChangeDesc">
                        <xsl:with-param name="code" select="$updateReasonType"/>
                    </xsl:call-template>
                </xsl:element>
            </xsl:element>
        </xsl:if>

        <!-- Reason for update -->
        <xsl:variable name="reasonForUpdate" select="./following-sibling::dmStatus/reasonForUpdate"/>
        <xsl:if test="$reasonForUpdate/*">
            <xsl:element name="tr">
                <xsl:element name="td">
                    <xsl:attribute name="class">
                        <xsl:value-of select="$STATUS_TABLE_TITLE_CLASS"/>
                    </xsl:attribute>
                    <xsl:value-of select="$STATUS_REASONFORUPDATE_ELEMENT"/>
                </xsl:element>
                <xsl:element name="td">
                    <xsl:attribute name="class">
                        <xsl:value-of select="$STATUS_TABLE_VALUE_CLASS"/>
                    </xsl:attribute>
                    <xsl:value-of select="$reasonForUpdate"/>
                </xsl:element>
            </xsl:element>
        </xsl:if>

        <!-- Maintenance work affected -->
        <xsl:variable name="updateHighlight" select="$reasonForUpdate/@updateHighlight"/>
        <xsl:if test="$updateReasonType != '' or $updateHighlight != ''">
            <xsl:element name="tr">
                <xsl:element name="td">
                    <xsl:attribute name="class">
                        <xsl:value-of select="$STATUS_TABLE_TITLE_CLASS"/>
                    </xsl:attribute>
                    <xsl:value-of select="$STATUS_MAINT_AFFECTED_ELEMENT"/>
                </xsl:element>
                <xsl:element name="td">
                    <xsl:attribute name="class">
                        <xsl:value-of select="$STATUS_TABLE_VALUE_CLASS"/>
                    </xsl:attribute>
                    <xsl:variable name="code" select="$updateReasonType"/>
                    <xsl:call-template name="getMaintenanceWorkAffected">
                        <xsl:with-param name="code" select="$updateReasonType"/>
                        <xsl:with-param name="updateHighlight" select="$updateHighlight"/>
                    </xsl:call-template>
                </xsl:element>
            </xsl:element>
        </xsl:if>

        <!-- End of Status -->


        <!-- Technical Standard -->

        <xsl:variable name="techPubBase" select="./following-sibling::dmStatus/techStandard/techPubBase"/>
        <xsl:variable name="authorityNotes" select="./following-sibling::dmStatus/techStandard/authorityNotes"/>

        <xsl:if test="$techPubBase != '' or $authorityNotes != ''">
            <xsl:element name="tr">
                <xsl:element name="th">
                    <xsl:attribute name="class">
                        <xsl:value-of select="$STATUS_TABLE_LABEL_CLASS"/>
                    </xsl:attribute>
                    <xsl:attribute name="colspan">
                        <xsl:value-of select="2"/>
                    </xsl:attribute>
                    <xsl:value-of select="$STATUS_TECHNICAL_STANDARD_ELEMENT"/>
                </xsl:element>
            </xsl:element>

            <!-- Base technical publication -->
                <xsl:if test="$techPubBase != ''">
                    <xsl:element name="tr">
                        <xsl:element name="td">
                            <xsl:attribute name="class">
                                <xsl:value-of select="$STATUS_TABLE_TITLE_CLASS"/>
                            </xsl:attribute>
                            <xsl:value-of select="$STATUS_TECHNICAL_STANDARD_BASE_TECHNICAL_PUBLICATION_ELEMENT"/>
                        </xsl:element>
                        <xsl:element name="td">
                            <xsl:attribute name="class">
                                <xsl:value-of select="$STATUS_TABLE_VALUE_CLASS"/>
                            </xsl:attribute>
                            <xsl:value-of select="$techPubBase"/>
                        </xsl:element>
                    </xsl:element>
                </xsl:if>

                <!-- Note -->
                <xsl:if test="$authorityNotes != ''">
                    <xsl:element name="tr">
                        <xsl:element name="td">
                            <xsl:attribute name="class">
                                <xsl:value-of select="$STATUS_TABLE_TITLE_CLASS"/>
                            </xsl:attribute>
                            <xsl:value-of select="$STATUS_TECHNICAL_STANDARD_NOTE_ELEMENT"/>
                        </xsl:element>
                        <xsl:element name="td">
                            <xsl:attribute name="class">
                                <xsl:value-of select="$STATUS_TABLE_VALUE_CLASS"/>
                            </xsl:attribute>
                            <xsl:value-of select="$authorityNotes"/>
                        </xsl:element>
                    </xsl:element>
                </xsl:if>

        </xsl:if>

        <!-- End of Technical Standard -->

    </xsl:element>
</xsl:template>


<!-- Get the text to display for the security classification (level) of the Status. -->
<xsl:template name="getSecurityClassification">
    <xsl:param name="securityClassification"/>
    <xsl:choose>
        <xsl:when test="$securityClassification = '01'">
           <xsl:value-of select="$STATUS_SECURITY_CLASSIFICATION_UNCLASSIFIED_ELEMENT"/>
        </xsl:when>
        <xsl:when test="$securityClassification = '02'">
           <xsl:value-of select="$STATUS_SECURITY_CLASSIFICATION_RESTRICTED_ELEMENT"/>
        </xsl:when>
        <xsl:when test="$securityClassification = '03'">
           <xsl:value-of select="$STATUS_SECURITY_CLASSIFICATION_CONFIDENTIAL_ELEMENT"/>
        </xsl:when>
        <xsl:when test="$securityClassification = '04'">
           <xsl:value-of select="$STATUS_SECURITY_CLASSIFICATION_SECRET_ELEMENT"/>
        </xsl:when>
        <xsl:when test="$securityClassification = '05'">
            <xsl:value-of  select="'Top secret'"/>
        </xsl:when>
        <xsl:when test="$securityClassification = '51'">
            <xsl:value-of select="'Special France'"/>
        </xsl:when>
        <xsl:when test="$securityClassification = '52'">
           <xsl:value-of select="'Special Germany'"/>
        </xsl:when>
        <xsl:when test="$securityClassification = '53'">
           <xsl:value-of select="'Special Spain'"/>
        </xsl:when>
        <xsl:otherwise>
            <xsl:value-of select="$securityClassification"/>
        </xsl:otherwise>
    </xsl:choose>
</xsl:template>

<!-- Get the caveat corresponding to a security classification (level) of
4 (Secret). -->
<xsl:template name="getLevel4SecurityCaveat">
    <xsl:param name="securityCaveat"/>
    <xsl:choose>
        <!-- Innovation & Assets -->
        <xsl:when test="$securityCaveat = 'cv51'">
            <xsl:value-of select="'RED-AH Secret'"/>
        </xsl:when>
        <!-- Defense France -->
        <xsl:when test="$securityCaveat = 'cv52'">
            <xsl:value-of select="'Secret D&#233;fense'"/>
        </xsl:when>
        <!-- Defense Germany -->
        <xsl:when test="$securityCaveat = 'cv53'">
            <xsl:value-of select="'Geheim'"/>
        </xsl:when>
        <!-- Defense Spain -->
        <xsl:when test="$securityCaveat = 'cv54'">
            <xsl:value-of select="'Reservado'"/>
        </xsl:when>
        <!-- OCCAR -->
        <xsl:when test="$securityCaveat = 'cv60'">
            <xsl:value-of select="'OCCAR SECRET'"/>
        </xsl:when>
        <!-- NATO -->
        <xsl:when test="$securityCaveat = 'cv70'">
            <xsl:value-of select="'NATO SECRET'"/>
        </xsl:when>
    </xsl:choose>
</xsl:template>

<!-- Get the caveat corresponding to a security classification (level) of
3 (Confidential). -->
<xsl:template name="getLevel3SecurityCaveat">
    <xsl:param name="securityCaveat"/>
    <xsl:choose>
        <!-- Innovation & Assets -->
        <xsl:when test="$securityCaveat = 'cv51'">
            <xsl:value-of select="'RED'"/>
        </xsl:when>
        <!-- Defense France -->
        <xsl:when test="$securityCaveat = 'cv52'">
            <xsl:value-of select="'Confidentiel D&#233;fense'"/>
        </xsl:when>
        <!-- Defense Germany -->
        <xsl:when test="$securityCaveat = 'cv53'">
            <xsl:value-of select="'VS-Vertraulich'"/>
        </xsl:when>
        <!-- Defense Spain -->
        <xsl:when test="$securityCaveat = 'cv54'">
            <xsl:value-of select="'Confidencial'"/>
        </xsl:when>
        <!-- OCCAR -->
        <xsl:when test="$securityCaveat = 'cv60'">
            <xsl:value-of select="'OCCAR CONFIDENTIAL'"/>
        </xsl:when>
        <!-- NATO -->
        <xsl:when test="$securityCaveat = 'cv70'">
            <xsl:value-of select="'NATO CONFIDENTIAL'"/>
        </xsl:when>
    </xsl:choose>
</xsl:template>

<!-- Get the caveat corresponding to a security classification (level) of
2 (Restricted). -->
<xsl:template name="getLevel2SecurityCaveat">
    <xsl:param name="securityCaveat"/>
    <xsl:choose>
        <!-- Innovation & Assets -->
        <xsl:when test="$securityCaveat = 'cv51'">
            <xsl:value-of select="'AMBER'"/>
        </xsl:when>
        <!-- Defense France -->
        <xsl:when test="$securityCaveat = 'cv52'">
            <xsl:value-of select="'Diffusion restreinte'"/>
        </xsl:when>
        <!-- Defense Germany -->
        <xsl:when test="$securityCaveat = 'cv53'">
            <xsl:value-of select="'VS-NFD'"/>
        </xsl:when>
        <!-- Defense Spain -->
        <xsl:when test="$securityCaveat = 'cv54'">
            <xsl:value-of select="'Difusi&#243;n limitada'"/>
        </xsl:when>
        <!-- OCCAR -->
        <xsl:when test="$securityCaveat = 'cv60'">
            <xsl:value-of select="'OCCAR RESTRICTED'"/>
        </xsl:when>
        <!-- NATO -->
        <xsl:when test="$securityCaveat = 'cv70'">
            <xsl:value-of select="'NATO RESTRICTED'"/>
        </xsl:when>
    </xsl:choose>
</xsl:template>

<!-- Get the caveat corresponding to a security classification (level) of
1 (Unclassified). -->
<xsl:template name="getLevel1SecurityCaveat">
    <xsl:param name="securityCaveat"/>
    <xsl:choose>
        <!-- OCCAR -->
        <xsl:when test="$securityCaveat = 'cv60'">
            <xsl:value-of select="'OCCAR SENSITIVE'"/>
        </xsl:when>
        <!-- NATO -->
        <xsl:when test="$securityCaveat = 'cv70'">
            <xsl:value-of select="'NATO UNCLASSIFIED'"/>
        </xsl:when>
        <!-- Innovation & Assets, Defense France, Defense Germany, and
        Defense Spain -->
        <xsl:otherwise>
            <xsl:value-of select="''"/>
        </xsl:otherwise>
    </xsl:choose>
</xsl:template>

<!-- Get the security caveat of the security classification. -->
<xsl:template name="getSecurityCaveat">
    <xsl:param name="securityClassification"/>
    <xsl:param name="securityCaveat"/>
    <xsl:choose>
        <!-- SECRET level -->
        <xsl:when test="$securityClassification = '04'">
            <xsl:call-template name="getLevel4SecurityCaveat">
                <xsl:with-param name="securityCaveat" select="$securityCaveat"/>
            </xsl:call-template>
        </xsl:when>
        <!-- CONFIDENTIAL level -->
        <xsl:when test="$securityClassification = '03'">
            <xsl:call-template name="getLevel3SecurityCaveat">
                <xsl:with-param name="securityCaveat" select="$securityCaveat"/>
            </xsl:call-template>
        </xsl:when>
        <!-- RESTRICTED level -->
        <xsl:when test="$securityClassification = '02'">
            <xsl:call-template name="getLevel2SecurityCaveat">
                <xsl:with-param name="securityCaveat" select="$securityCaveat"/>
            </xsl:call-template>
        </xsl:when>
        <!-- UNCLASSIFIED level -->
        <xsl:when test="$securityClassification = '01'">
            <xsl:call-template name="getLevel1SecurityCaveat">
                <xsl:with-param name="securityCaveat" select="$securityCaveat"/>
            </xsl:call-template>
        </xsl:when>
        <!-- SPECIAL FRANCE level -->
        <xsl:when test="$securityClassification = '51' and $securityCaveat = 'cv52'">
            <xsl:value-of select="'For French Eyes Only'"/>
        </xsl:when>
        <!-- SPECIAL GERMANY level -->
        <xsl:when test="$securityClassification = '52' and $securityCaveat = 'cv53'">
            <xsl:value-of select="'For German Eyes Only'"/>
        </xsl:when>
        <!-- SPECIAL SPAIN level -->
        <xsl:when test="$securityClassification = '53' and $securityCaveat = 'cv54'">
            <xsl:value-of select="'For Spanish Eyes Only'"/>
        </xsl:when>
        <xsl:otherwise>
        <!-- Empty or N/A cells. -->
            <xsl:value-of select="''"/>
        </xsl:otherwise>
    </xsl:choose>
</xsl:template>

<!-- Get the text to display for the security classification (level)
and caveat of the Status. -->
<xsl:template name="getSecurityClassificationAndCaveat">
    <xsl:param name="security"/>

    <xsl:variable name="securityClassification">
        <xsl:call-template name="getSecurityClassification">
            <xsl:with-param name="securityClassification" select="$security/@securityClassification"/>
        </xsl:call-template>
    </xsl:variable>

    <xsl:variable name="securityCaveat" select="$security/@caveat"/>
    <xsl:choose>
        <xsl:when test="$securityCaveat != ''">
            <xsl:variable name="caveat">
                <xsl:call-template name="getSecurityCaveat">
                    <xsl:with-param name="securityClassification" select="$security/@securityClassification"/>
                    <xsl:with-param name="securityCaveat" select="$securityCaveat"/>
                </xsl:call-template>
            </xsl:variable>
            <xsl:choose>
                <xsl:when test="$caveat != ''">
                    <xsl:value-of select="concat($securityClassification, $SPACE, $OPENING_PARENTHESIS, $caveat, $CLOSING_PARENTHESIS)"/>
                </xsl:when>
                <xsl:otherwise>
                    <xsl:value-of select="$securityClassification"/>
                </xsl:otherwise>
            </xsl:choose>
        </xsl:when>
        <xsl:otherwise>
            <xsl:value-of select="$securityClassification"/>
        </xsl:otherwise>
    </xsl:choose>
</xsl:template>

<!-- Get the text to display for the responsible partner company of the Status. -->
<xsl:template name="getCompanyNameWithCode">
    <xsl:param name="code"/>
    <xsl:choose>
        <xsl:when test="$code = 'B0139'">
            <xsl:value-of select="concat($code, ' (FORGES DE ZEEBRUGGE)')"/>
        </xsl:when>
        <xsl:when test="$code = 'F0210'">
            <xsl:value-of select="concat($code, ' (AIRBUS (ETS DE MARIGNANE))')"/>
        </xsl:when>
        <xsl:when test="$code = 'C0418'">
            <xsl:value-of select="concat($code, ' (AIRBUS DEUTSCHLAND)')"/>
        </xsl:when>
        <xsl:when test="$code = 'C4270'">
            <xsl:value-of select="concat($code, ' (MTU TURBOMECA ROLLS-ROYCE)')"/>
        </xsl:when>
        <xsl:otherwise>
            <xsl:value-of select="$code"/>
        </xsl:otherwise>
    </xsl:choose>
</xsl:template>

<!-- Get the text to display for the responsible partner company of the Status. -->
<xsl:template name="getResponsiblePartnerCompany">
    <xsl:param name="responsiblePartnerCompany"/>
    <xsl:call-template name="getCompanyNameWithCode">
        <xsl:with-param name="code" select="$responsiblePartnerCompany"/>
    </xsl:call-template>
</xsl:template>

<!-- Get the text to display for the originator (author) of the Status. -->
<xsl:template name="getOriginator">
    <xsl:param name="originator"/>
    <xsl:call-template name="getCompanyNameWithCode">
        <xsl:with-param name="code" select="$originator"/>
    </xsl:call-template>
</xsl:template>

<!-- Get the verification number of the quality assurance status. -->
<xsl:template name="getQualityAssuranceStatusVerificationNumber">
    <xsl:param name="qualityAssurance"/>
    <xsl:choose>
        <xsl:when test="$qualityAssurance/firstVerification">
           <xsl:value-of select="$STATUS_FIRST_VERIFICATION_ELEMENT"/>
        </xsl:when>
        <xsl:when test="$qualityAssurance/secondVerification">
           <xsl:value-of select="$STATUS_SECOND_VERIFICATION_ELEMENT"/>
        </xsl:when>
        <xsl:otherwise>
            <xsl:value-of select="$qualityAssurance"/>
        </xsl:otherwise>
    </xsl:choose>
</xsl:template>

<!-- Get the verification type of the quality assurance status. -->
<xsl:template name="getQualityAssuranceStatusVerificationType">
    <xsl:param name="verificationType"/>
    <xsl:choose>
        <xsl:when test="$verificationType = 'tabtop'">
            <xsl:value-of select="$STATUS_VERIFICATION_TYPE_TAB_TOP_ELEMENT"/>
        </xsl:when>
        <xsl:when test="$verificationType = 'onobject'">
            <xsl:value-of select="$STATUS_VERIFICATION_TYPE_ON_OBJECT_ELEMENT"/>
        </xsl:when>
        <xsl:otherwise>
            <xsl:value-of select="$verificationType"/>
        </xsl:otherwise>
    </xsl:choose>
</xsl:template>

<!-- Get the text to display for the quality assurance status of the Status. -->
<xsl:template name="getQualityAssuranceStatus">
    <xsl:param name="qualityAssurance"/>

    <xsl:choose>
        <xsl:when test="$qualityAssurance/unverified">
            <xsl:value-of select="$STATUS_UNVERIFIED_ELEMENT"/>
        </xsl:when>
        <xsl:otherwise>
            <xsl:variable name="verificationNumber">
                <xsl:call-template name="getQualityAssuranceStatusVerificationNumber">
                    <xsl:with-param name="qualityAssurance" select="$qualityAssurance"/>
                </xsl:call-template>
            </xsl:variable>

            <xsl:variable name="verificationType">
                <xsl:call-template name="getQualityAssuranceStatusVerificationType">
                    <xsl:with-param name="verificationType" select="$qualityAssurance/*/@verificationType"/>
                </xsl:call-template>
            </xsl:variable>

            <xsl:value-of select="concat($verificationNumber, $SPACE, $verificationType)"/>
        </xsl:otherwise>
    </xsl:choose>
</xsl:template>

<xsl:template name="getStatus">
    <xsl:param name="status"/>
    <xsl:param name="urtCode"/>
    <xsl:choose>
        <xsl:when test="$urtCode='urt63' or $status='deleted'">
            <xsl:value-of select="'D'"/>
        </xsl:when>
        <xsl:when test="$status='changed'">
            <xsl:value-of select="'R'"/>
        </xsl:when>
        <xsl:when test="$status='status'or $status='unchanged'">
            <xsl:value-of select="'U'"/>
        </xsl:when>
        <xsl:when test="$status='new'">
            <xsl:value-of select="'N'"/>
        </xsl:when>
        <xsl:otherwise>
            <xsl:value-of select="$status"/>
        </xsl:otherwise>
    </xsl:choose>
</xsl:template>

<xsl:template name="getChangeDesc">
    <xsl:param name="code"/>
    <xsl:choose>
        <xsl:when test="$code='urt51' or $code='urt52'">
            <xsl:value-of select="'N'"/>
        </xsl:when>
        <xsl:when test="$code='urt53' or $code='urt54' or $code='urt81'">
            <xsl:value-of select="'M'"/>
        </xsl:when>
        <xsl:when test="$code='urt01' or $code='urt82'">
            <xsl:value-of select="'F'"/>
        </xsl:when>
        <xsl:when test="$code='urt55' or $code='urt56' or $code='urt83'">
            <xsl:value-of select="'I'"/>
        </xsl:when>
        <xsl:when test="$code='urt59' or $code='urt60' or $code='urt84'">
            <xsl:value-of select="'R'"/>
        </xsl:when>
        <xsl:when test="$code='urt57' or $code='urt58' or $code='urt85'">
            <xsl:value-of select="'C'"/>
        </xsl:when>
        <xsl:when test="$code='urt61' or $code='urt62'">
            <xsl:value-of select="'O'"/>
        </xsl:when>
    </xsl:choose>
</xsl:template>

<!-- Get the text to display for the maintenance work affected of the Status. -->
<xsl:template name="getMaintenanceWorkAffected">
    <xsl:param name="code"/>
    <xsl:param name="updateHighlight"/>

    <xsl:choose>
        <xsl:when test="$code='urt01' or $code='urt02' or $code='urt03' or $code='urt52' or $code='urt54' or $code='urt56' or $code='urt58' or $code='urt60' or $code='urt62'">
            <xsl:value-of select="$FALSE"/>
        </xsl:when>

        <xsl:when test="$code='urt04' or $code='urt51' or $code='urt53' or $code='urt55' or $code='urt57' or $code='urt59' or $code='urt61' or $code='urt63'">
            <xsl:value-of select="$TRUE"/>
        </xsl:when>

        <xsl:when test="$updateHighlight = '0'">
            <xsl:value-of select="$FALSE"/>
        </xsl:when>

        <xsl:when test="$updateHighlight = '1'">
            <xsl:value-of select="$TRUE"/>
        </xsl:when>
    </xsl:choose>
</xsl:template>

<xsl:template match="ENTITE_GRAPHIQUE"></xsl:template>
<xsl:template match="dmStatus" name="displayDmStatus"/>
<xsl:template match="content" name="displayContent"></xsl:template>
<xsl:template match="WP6Status" name="displayWP6Status"></xsl:template>
<xsl:template match="WP6RefBy"></xsl:template>
<xsl:template match="WP6PmInformation"></xsl:template>

</xsl:stylesheet>
`;
