import { Pipe, PipeTransform } from "@angular/core";
import { convertRevLabel } from "@orion2/utils/functions.utils";

@Pipe({
  standalone: false,
  name: "parseRevision"
})
export class ParseRevisionPipe implements PipeTransform {
  transform(value: string, isPackagePDF?: boolean): string {
    return convertRevLabel(value, isPackagePDF);
  }
}
