import xpath from "xpath";
import { TaskNamespace } from "@orion2/msm-formatter/types/MsmTask";
import { Util, OBJECT_SEPARATOR, ARRAY_SEPARATOR } from "@orion2/utils/datamodule.utils";

export class Documentation {
  public static extractLegacy(xmlNode: Node): string {
    const data: string[] = [];

    const refdocs = xpath.select("MAINLIMI/REFDOC", xmlNode);
    refdocs.forEach((refdoc: Node) => {
      const changed = xpath.select("string(.//@CHANGEMARK)", refdoc).length > 0;
      const refman = Util.cleanXml(xpath.select("string(./SREFMAN)", refdoc));
      const refloc = Util.cleanXml(xpath.select("string(./SREFLOC)", refdoc));
      const shortDMC = refman + " " + refloc;

      let locs = [];
      locs = xpath.select("LOC/text()", refdoc);
      const locString = locs.join("////");

      const title = Util.cleanXml(xpath.select("string(@*[name()='xlink:title'])", refdoc));

      const href = Util.cleanXml(xpath.select("string(@*[name()='xlink:href'])", refdoc));

      data.push(
        "" +
          OBJECT_SEPARATOR +
          href +
          OBJECT_SEPARATOR +
          title +
          OBJECT_SEPARATOR +
          locString +
          OBJECT_SEPARATOR +
          changed +
          OBJECT_SEPARATOR +
          shortDMC
      );
    });

    return data.join(ARRAY_SEPARATOR);
  }

  public static extractH160(xmlNode: Node): string {
    const data: string[] = [];

    // SPEC : tasks nodes are identified by the taskDefinition or timeLimitInfo tag (childs of maintPlanning)
    // SPEC : tasks documentation reference can are in refs/dmRef or in preliminaryRqmts//dmRef node
    // SPEC : preliminaryRqmts is a sibling node of the task node
    // SPEC : links to cards should appear in the same order they appear in the xml => preliminary first then timeLimitInfo.
    // We can't do maintPlanning//dmRef because we have some exclusion, for example maintPlanning//applicRef/dmRef
    const dmRefs = xpath.select(
      "../preliminaryRqmts/reqTechInfoGroup/reqTechInfo/dmRef | refs/dmRef",
      xmlNode
    );

    const isParentChanged =
      xpath.select(
        "string(..//preliminaryRqmts/@changeMark | ..//reqTechInfoGroup/@changeMark)",
        xmlNode
      ).length > 0;

    dmRefs.forEach((dmRef: Document) => {
      const changed =
        isParentChanged ||
        xpath.select("string(../@changeMark | .//@changeMark)", dmRef).length > 0;

      const refman = Util.cleanXml(
        xpath.select(
          "string(concat(dmRefIdent/dmCode/@systemCode, '-', " +
            "dmRefIdent/dmCode/@subSystemCode, dmRefIdent/dmCode/@subSubSystemCode," +
            "'-', dmRefIdent/dmCode/@assyCode, '-', dmRefIdent/dmCode/@infoCode))",
          dmRef
        )
      );

      const shortDMC = Util.cleanXml(xpath.select("string(@shortDMC)", dmRef));

      const title = Util.cleanXml(xpath.select("string(@*[name()='xlink:title'])", dmRef));

      const href = Util.cleanXml(xpath.select("string(@*[name()='xlink:href'])", dmRef));

      data.push(
        refman +
          OBJECT_SEPARATOR +
          href +
          OBJECT_SEPARATOR +
          title +
          OBJECT_SEPARATOR +
          "" +
          OBJECT_SEPARATOR +
          changed +
          OBJECT_SEPARATOR +
          shortDMC
      );
    });

    return data.join(ARRAY_SEPARATOR);
  }

  // the documentaion shortDMC is set by the enrichDocumentation function
  // we pass this function on parameter because we need to use the searchService
  public static unserialize(
    data: string,
    enrichDocumentation?: Function
  ): TaskNamespace.Documentation[] | undefined {
    if (!data) {
      return undefined;
    }
    return data
      .split(ARRAY_SEPARATOR)
      .map((documentation: string) => documentation.split(OBJECT_SEPARATOR))
      .map((val: string[]) => {
        const res = {
          linkDM: val[0],
          href: val[1],
          title: val[2],
          loc: val[3]?.split("////"),
          changed: val[4] === "true",
          shortDMC: val[5]
        };
        return enrichDocumentation ? enrichDocumentation(res) : res;
      });
  }
}
