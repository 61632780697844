import { Component, Input } from "@angular/core";

@Component({
  standalone: false,
  selector: "o-loading",
  templateUrl: "./loading.component.html",
  styleUrls: ["./loading.component.scss"]
})
export class LoadingComponent {
  @Input() isLoading: boolean;
  @Input() textToDisplay: string;
}
