import { TocCaller } from "@viewer/core/pouchdb/caller/tocCaller";
import { Injector } from "@angular/core";
import { DbSchema } from "libs/transfert/model/pubSchema";
import { reaction } from "mobx";
import { Design } from "libs/design/design";

export class OrionTocPublicCaller extends TocCaller {
  constructor(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    processor: any,
    protected injector: Injector,
    dbSchema: DbSchema
  ) {
    super(processor, injector, dbSchema);
    this.dbParams = { ...this.dbParams, local: { auto_compaction: true } };
    this.init();
    reaction(
      () => this.store.isLoggedIn,
      () => this.ensureIndexIsBuilt()
    );
  }

  public ensureIndexIsBuilt(): Promise<any> {
    // SPEC: Since we use _find for Toc Items, we don't need children anymore
    return super.ensureFilterIndexIsBuilt("_design/filter-type");
  }

  protected addFilterIndex(): Promise<unknown> {
    return this.callFunction("createIndex", Design.indexFilterType());
  }
}
