import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { PubDoc } from "@orion2/models/couch.models";
import { Observable, map } from "rxjs";

@Component({
  standalone: false,
  selector: "o-home-redirect",
  templateUrl: "./home-redirect.component.html",
  styleUrls: ["./home-redirect.component.scss"]
})
export class HomeRedirectComponent implements OnInit {
  public products: Observable<PubDoc[]>;
  public searchInPub: string;

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.searchInPub = this.activatedRoute.snapshot.queryParams.search;
    this.products = this.activatedRoute.data.pipe(
      map((data: { products: PubDoc[] }) => data.products)
    );
  }
}
