import { Component, Inject } from "@angular/core";
import { BookmarkImportResultData } from "@viewer/toc-items/bookmark-module/bookmark-list/models";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  standalone: false,
  selector: "o-bookmark-import-result-dialog",
  templateUrl: "./bookmark-import-result-dialog.component.html",
  styleUrls: ["./bookmark-import-result-dialog.component.scss"]
})
export class BookmarkImportResultDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: BookmarkImportResultData) {}
}
