import { Component, OnInit, Injector } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { EOrderingService } from "@viewer/toc-items/shopping-basket/e-ordering.service";
import { MessageService } from "@orion2/message-service/message.service";
import { TranslateService } from "@ngx-translate/core";
import { ShoppingService } from "@viewer/core";
import { BaseStore } from "@viewer/toc-items/shopping-basket/shopping-basket.module";
import { ItemBasket } from "@orion2/models/tocitem.models";

@Component({
  standalone: false,
  selector: "o-eordering-dialog",
  templateUrl: "./e-ordering-dialog.component.html",
  styleUrls: ["./e-ordering-dialog.component.scss"]
})
export class EOrderingDialogComponent implements OnInit {
  public step: "storeSelect" | "result" | "loading" = "loading";
  public status = "";
  public link = "";
  public message = "";

  private storeIndex = -1;
  private baseStores: BaseStore[] = [];
  private items: ItemBasket[];
  private shoppingService: ShoppingService;

  constructor(
    private dialog: MatDialogRef<EOrderingDialogComponent>,
    private eOrderingService: EOrderingService,
    private messageService: MessageService,
    private translate: TranslateService,
    injector: Injector
  ) {
    this.shoppingService = injector.get(ShoppingService);
  }

  ngOnInit() {
    this.shoppingService.getShoppingItems().then(items => {
      this.items = items;
      this.eOrderingService
        .getAvailableBaseStores()
        .then(stores => {
          if (!stores || stores.length === 0) {
            this.closeWithFailMessage(this.translate.instant("eOrdering.noStores"));
          }
          this.baseStores = stores;
          this.storeIndex = 0;
          this.step = "storeSelect";
        })
        .catch(err => {
          console.error("Can't get baseStores", err);
          this.closeWithFailMessage(this.translate.instant("eOrdering.error"));
        });
    });
  }

  /**
   * Closes the popup
   */
  close(): void {
    this.dialog.close();
  }

  /**
   * Print an error message through the message service then closes the popup
   *
   * @param message {string} - The message to be printed
   */
  closeWithFailMessage(message: string): void {
    this.messageService.error(message);
    this.close();
  }

  /**
   * Submit the item list along with the base store and handle the response
   */
  submit(): void {
    // Normally the button to submit is disable when no base store is selected so this condition
    //  should always pass. We left it to avoid bypassing by editing 'disable' attribute through HTML.
    if (this.storeIndex >= 0) {
      this.step = "loading";
      this.eOrderingService
        .pushItems(this.items, this.baseStores[this.storeIndex])
        .then(res => {
          // Because there is no 'conflict' or 'partial success' status code we have to use the message returned from eOrdering
          if (res.message.match(/^\d+ items added to the cart\.$/)) {
            this.status = "ok";
          } else {
            this.status = "conflict";
          }
          this.link = res.url;
          this.message = res.message;
          this.step = "result";
        })
        .catch(err => {
          if (err.error.errorCode) {
            this.status = "error";
            this.message = err.error.message;
            this.step = "result";
          } else {
            console.error("Can't push items to eOrdering", err);
            this.closeWithFailMessage(this.translate.instant("eOrdering.error"));
          }
        });
    }
  }

  /**
   * Open the URL using the system default browser
   */
  openURL() {
    window.open(this.link, "_system");
  }
}
