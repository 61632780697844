import { Injectable } from "@angular/core";
import { HttpParams } from "@angular/common/http";
import { Basepub } from "@orion2/models/basepub.models";
import { HttpClientOptions, HttpMethod } from "libs/http/abstractHttp.service";
import { AbstractBasepubService } from "libs/basepub/abstract-basepub.service";
import { FullHttpService } from "libs/http/fullHttp.service";
import { TranslateService } from "@ngx-translate/core";
import { ViewerMessageService } from "@viewer/core/message/viewer.message.service";
import { Params } from "@angular/router";
import { MimeType } from "@orion2/models/enums";
import { DegradedStateService } from "libs/http/degraded-state/degraded-state.service";

@Injectable()
export class BasepubUserService extends AbstractBasepubService {
  protected readonly apiPath = "basepub/api/user";

  constructor(
    protected messageService: ViewerMessageService,
    protected translate: TranslateService,
    private httpService: FullHttpService,
    degradedStateHandler: DegradedStateService
  ) {
    super(messageService, translate, degradedStateHandler);
  }

  /**
   * Fetch a products list from Basepub API.
   * If parameters are provided, we get the list of products associated to the given set of parameters.
   * Otherwise we get the list of recently consulted products.
   *
   * @param params The query parameters to send to BasePub.
   * @param path Temporary parameter to override the API path while we maintain retrocompatibility with BP1.
   */
  public getProducts(params?: Params, path = this.apiPath): Promise<Basepub.Product[]> {
    return this.whithErrorHandling(
      () =>
        this.httpService.sendRequest<Basepub.Product[]>(HttpMethod.GET, `${path}/products`, {
          params,
          headers: { "Content-Type": MimeType.JSON }
        }),
      []
    );
  }

  /**
   * Fetch a contracts list from BasePub API.
   * If parameters are provided, we get the list of contracts associated to the given set of parameters.
   * Otherwise we get the list of recently consulted contracts.
   *
   * @param params The query parameters to send to BasePub.
   */
  public getContracts(params?: Params): Promise<Basepub.Contract[]> {
    return this.whithErrorHandling(
      () =>
        this.httpService.sendRequest<Basepub.Contract[]>(
          HttpMethod.GET,
          `${this.apiPath}/contracts`,
          {
            params,
            headers: { "Content-Type": MimeType.JSON }
          }
        ),
      []
    );
  }

  /**
   * Fetch available aircrafts from BasePub API.
   */
  public getAircrafts(): Promise<Basepub.Aircraft[]> {
    return this.whithErrorHandling(
      () =>
        this.httpService.sendRequest<Basepub.Aircraft[]>(
          HttpMethod.GET,
          `${this.apiPath}/aircrafts`,
          {
            params: { available: "true" },
            headers: { "Content-Type": MimeType.JSON }
          }
        ),
      []
    );
  }

  /**
   * Fetch contract PDF from BasePub API.
   *
   * @param contractId The ID for the contract to fetch.
   */
  public getContractPdf(contractId: string): Promise<ArrayBuffer> {
    return this.whithErrorHandling(
      () =>
        this.httpService.sendRequest<ArrayBuffer>(
          HttpMethod.GET,
          `${this.apiPath}/contracts/${contractId}`,
          {
            headers: { "Content-Type": MimeType.BIN },
            responseType: "arraybuffer"
          }
        ),
      null,
      true
    );
  }

  /**
   * Get the available search options for contracts from BasePub API.
   *
   */
  public getContractSearchData(): Promise<Basepub.ContractSearchData> {
    return this.whithErrorHandling(
      () =>
        this.httpService.sendRequest<Basepub.ContractSearchData>(
          HttpMethod.GET,
          `${this.apiPath}/searchCriterias`,
          {
            params: { target: "contract" },
            headers: { "Content-Type": MimeType.JSON }
          }
        ),
      null,
      true
    );
  }

  /**
   * Fetch SRD (standard or base) PDF from BasePub API.
   *
   * @param params The informations about the requested SRD.
   */
  public getSRD(params: HttpParams): Promise<ArrayBuffer> {
    return this.whithErrorHandling(
      () =>
        this.httpService.sendRequest<ArrayBuffer>(HttpMethod.GET, `${this.apiPath}/srd`, {
          params,
          headers: { "Content-Type": MimeType.BIN },
          responseType: "arraybuffer"
        }),
      null,
      true
    );
  }

  /**
   * Send consult or download info to BasePub API for tracking purpose.
   *
   * @param body The informations about the tracking event to send.
   */
  public postTracking(body: HttpClientOptions["body"]): Promise<void> {
    return this.whithErrorHandling(() =>
      this.httpService.sendRequest<void>(HttpMethod.POST, `${this.apiPath}/trackings`, {
        headers: { "Content-Type": MimeType.JSON },
        body
      })
    );
  }
}
