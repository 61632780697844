import { AfterViewInit, Directive, ElementRef, Input } from "@angular/core";
import { HistoricService } from "@viewer/core/toc-items/historic.service";
import { HistoryElem } from "@orion2/models/tocitem.models";
import { cloneDeep } from "lodash";
import { HistorySource } from "@orion2/models/enums";

@Directive({
  standalone: false,
  selector: "[oHistoric]"
})
export class HistoricDirective implements AfterViewInit {
  @Input() historicData: HistoryElem;

  @Input() from: string = undefined;

  // SPEC: Coming from one of these sources, adds potential dmRoot
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly POTENTIAL_ROOT_SOURCES = [
    HistorySource.SEARCH,
    HistorySource.BOOKMARK,
    HistorySource.NOTES,
    HistorySource.SHOPPING,
    HistorySource.TOC,
    HistorySource.TOC_ITEMS
  ];

  constructor(
    private element: ElementRef,
    private historicService: HistoricService
  ) {}

  // ------------------------------------ LifeCycle ------------------------------------
  ngAfterViewInit() {
    if (this.historicService.isActive()) {
      this.element.nativeElement.addEventListener("click", this.clickHandler.bind(this));
    }
  }

  public clickHandler(event: Event) {
    // Without this condition the submit event will be overrided by the preventDefault() (only for NoteComponent)
    if (this.from !== HistorySource.NOTES) {
      event.preventDefault();
    }

    const match = this.isPotentialDmRoot();

    if (this.historicData && match) {
      this.historicService.addPotentialDmRoot(cloneDeep(this.historicData), this.from);
    } else {
      this.historicService.updateCurrentDMRoot(cloneDeep(this.historicData), this.from);
    }
  }

  // ------------------------------------ Functions------------------------------------

  private isPotentialDmRoot(): boolean {
    return (
      !!this.from &&
      this.POTENTIAL_ROOT_SOURCES.some((element: string) => !!this.from.match(element))
    );
  }
}
