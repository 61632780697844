import { Component, OnInit } from "@angular/core";
import { PubDoc } from "@orion2/models/couch.models";
import { Store } from "@viewer/core";
import { ProductsService } from "@viewer/home/service/products.service";

@Component({
  standalone: false,
  selector: "o-local-products",
  templateUrl: "./local-products.component.html",
  styleUrls: ["./local-products.component.scss"]
})
export class LocalProductsComponent implements OnInit {
  public isRecentlyConsulted: boolean;
  public products: PubDoc[];

  constructor(
    public store: Store,
    private productsService: ProductsService
  ) {}

  ngOnInit() {
    this.getLocalProducts();
  }

  private getLocalProducts(): Promise<void> {
    return this.productsService.getLocalProducts().then((products: PubDoc[]) => {
      this.products = products;
    });
  }
}
