import { Injectable, Injector } from "@angular/core";
import { TocItemService } from "@viewer/core/toc-items/tocItem.service";
import { TocPublicCaller } from "@viewer/core/pouchdb/caller";
import { reaction } from "mobx";
import { TocItem, ServiceBulletin } from "@orion2/models/tocitem.models";
import { DBConnexionType } from "@viewer/core/pouchdb/types";
import {
  convertToCompactedDMC,
  S1000D_DM_REPLACE,
  S1000D_DM_WITH_Z_REGEX
} from "@viewer/shared-module/helper.utils";
import { Observable, filter, map } from "rxjs";
import { SearchResult } from "@viewer/core/search/searchModel";

export interface ImpactLink {
  id: string;
  label: string;
}

@Injectable()
export class ServiceBulletinService extends TocItemService {
  protected tiType = "sb";
  protected tiScope = "public";
  protected tiTarget = DBConnexionType.BOTH;

  private sbList: Promise<TocItem[]>;
  private impactLinksMap = new Map<string, Promise<ImpactLink[]>>();

  constructor(injector: Injector) {
    super(injector);

    reaction(
      () => this.store.publicationID,
      () => {
        if (this.store.publicationID) {
          // we reset sbList when we change publication
          this.sbList = undefined;
          this.impactLinksMap.clear();
        }
      }
    );
  }

  public get tocItemsOfType(): Observable<ServiceBulletin[]> {
    return super.tocItemsOfType.pipe(
      filter(Boolean),
      map((sbs: TocItem[]) => sbs as ServiceBulletin[])
    );
  }

  // If sb.service is a singleton instantiated with the app
  // Even if sb.service instantiate once when the sb module
  // is loaded
  // then there's no need to fetch the sb in db every time.
  public async getAllServiceBulletin(
    parent: string,
    all = false
  ): Promise<ServiceBulletin | ServiceBulletin[]> {
    if (!this.sbList) {
      this.sbList = this.getItemsOfType(this.tiType, this.tiScope);
    }
    return this.sbList.then((docs: ServiceBulletin[]) => {
      const filteredDocs = docs.filter(
        (doc: ServiceBulletin) =>
          doc.parents.find((p: string) => p === parent) || doc._id === parent
      );
      return filteredDocs && filteredDocs.length
        ? all
          ? filteredDocs
          : filteredDocs[0]
        : undefined;
    });
  }

  /**
   * Find all impact links for SB or DMC
   * Use impactLinksMap for save all links found by dmc
   * When impactLinksMap size is over 50, we remove the older item added
   * When searchIsReady is false we do not save links in impactLinksMap
   *
   * @param dmc
   * @param searchIsReady
   * @return Promise<ImpactLink[]>.
   */
  public getImpactLinks(dmc: string, searchIsReady: boolean): Promise<ImpactLink[]> {
    if (this.impactLinksMap.has(dmc)) {
      return this.impactLinksMap.get(dmc);
    }
    if (!this.sbList) {
      this.sbList = this.getItemsOfType(this.tiType, this.tiScope);
    }
    const impactLinks: Promise<ImpactLink[]> = dmc.startsWith("sb__")
      ? this.getImpactLinksForSB(dmc)
      : this.getImpactLinksForDMC(dmc);

    if (searchIsReady) {
      this.impactLinksMap.set(dmc, impactLinks);
      if (this.impactLinksMap.size > 50) {
        const firstKey = this.impactLinksMap.keys().next().value;
        this.impactLinksMap.delete(firstKey);
      }
      return this.impactLinksMap.get(dmc);
    }
    return impactLinks;
  }

  public getServiceBulletinPdf(pdfId: string): Promise<Blob> {
    return (this.getCaller(this.tiScope) as TocPublicCaller).getAttachmentsBlob(pdfId);
  }

  /**
   * Find all impact links for SB
   * Read the property impactedDM for current valid SB (not archived)
   * We check in search map if a element corresponds to the impactedDMs by DMC or compactedDMC
   * If exist we get dmc for link and shortDMC (reference) for display
   * Else we keep impactedDM for link and display
   * @param dmc
   * @return Promise<ImpactLink[]>.
   */
  private getImpactLinksForSB(sbId: string): Promise<ImpactLink[]> {
    return this.sbList.then((sbs: ServiceBulletin[]) =>
      sbs
        .find((doc: ServiceBulletin) => doc._id === sbId && !doc.isArchived)
        ?.impactedDM?.map((datamodule: string) => {
          const result = this.store.referenceToResultMap?.find(
            (searchResult: SearchResult) =>
              searchResult.dmc === datamodule ||
              convertToCompactedDMC(searchResult.dmc) === datamodule
          );
          return { id: result?.dmc || datamodule, label: result?.reference || datamodule };
        })
    );
  }

  /**
   * Find all impact links for DMC
   * Get all valid SB (not archived) in which the current DMC appears in impactedDM
   * Correspondence is done on DMC, compactedDMC, and shortDMC (reference), taking into account the DM containers
   * @param dmc
   * @return Promise<ImpactLink[]>.
   */
  private getImpactLinksForDMC(dmc: string): Promise<ImpactLink[]> {
    const compactedDMC = convertToCompactedDMC(dmc);
    return this.sbList.then((sbs: ServiceBulletin[]) =>
      sbs
        .filter(
          (sb: ServiceBulletin) =>
            !sb.isArchived &&
            sb.impactedDM?.find((datamodule: string) => {
              const shortDMC = this.store.getDUMeta(dmc)?.reference;
              // we create regex by transforming "Z" in DM containers to [A-Z]
              // this work for DMC or compactedDMC
              const regex = new RegExp(
                datamodule.replace(S1000D_DM_WITH_Z_REGEX, S1000D_DM_REPLACE),
                "g"
              );
              return compactedDMC.match(regex) || dmc.match(regex) || shortDMC === datamodule;
            })
        )
        .map((sb: ServiceBulletin) => ({ id: sb._id, label: `${sb.reference} - ${sb.title}` }))
    );
  }

  /**
   * Get Revision Mark to display (use in orion card)
   * @param sbId
   * @return Promise<string>
   */
  public getRevisionMark(sbId: string): Promise<string> {
    if (!this.sbList) {
      this.sbList = this.getItemsOfType(this.tiType, this.tiScope);
    }
    return this.sbList.then((sbs: ServiceBulletin[]) =>
      sbs.find((sb: ServiceBulletin) => sb._id === sbId).isArchived ? "archivedsb" : "sb"
    );
  }
}
