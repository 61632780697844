import { Component, Input, OnInit } from "@angular/core";
import { BasepubUserService } from "@viewer/core/basepub/basepub-user.service";
import { MatDialog } from "@angular/material/dialog";
import { ContractModalComponent } from "@viewer/contract-modal/contract-modal.component";
import { Router } from "@angular/router";
import { Basepub } from "@orion2/models/basepub.models";

@Component({
  standalone: false,
  selector: "o-card-contract",
  templateUrl: "./card-contract.component.html",
  styleUrls: ["./card-contract.component.scss"]
})
export class CardContractComponent implements OnInit {
  @Input() contract: Basepub.Contract;
  @Input() isDetails = false;
  public imgPath: string;

  constructor(
    private basepubUserService: BasepubUserService,
    private dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit() {
    this.imgPath = this.isDetails
      ? "./assets/img/contract_download.png"
      : "./assets/img/contract.png";
  }

  /**
   * Handle click on Contract card.
   */
  public handleClick(): void {
    if (!this.isDetails) {
      this.openContractDetail();
    } else {
      // If we are already in contract details, open the contract PDF.
      this.router.navigate([`/home/contract/${this.contract.uidAhead}`]);
    }
  }

  /**
   * Open the ContractModal for the current contract.
   */
  private openContractDetail(): void {
    this.basepubUserService.postTracking({
      objectId: this.contract.uidAhead,
      objectType: "contract",
      date: new Date(),
      type: "consult"
    });

    this.dialog.open(ContractModalComponent, {
      panelClass: "contract-modal-container",
      data: this.contract
    });
  }
}
