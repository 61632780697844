import { firstValueFrom, Observable, Subscriber } from "rxjs";

interface PromiseInfo<T> {
  observable: Observable<T>;
  subscriber?: Subscriber<T>;
}

export class PromisesRepository {
  private static promises: Map<string, PromiseInfo<unknown>> = new Map();

  public static create<T>(key: string): Promise<T> {
    const observable = new Observable<T>(subscriber => {
      this.get<T>(key).subscriber = subscriber;
    });
    this.promises.set(key, {
      observable
    });
    return this.getPromise(key);
  }

  public static reset(key: string) {
    this.promises.delete(key);
  }

  public static get<T>(key: string): PromiseInfo<T> {
    return this.promises.get(key) as PromiseInfo<T>;
  }

  public static getPromise<T>(key: string): Promise<T> {
    const observable = this.get<T>(key)?.observable;
    return observable ? firstValueFrom(observable) : null;
  }

  public static resolve<T>(key: string, response: T) {
    const subscriber = this.get(key)?.subscriber;
    if (subscriber) {
      subscriber.next(response);
      subscriber.complete();
    }
  }

  public static reject<T>(key: string, error: T) {
    const subscriber = this.get(key)?.subscriber;
    if (subscriber) {
      subscriber.error(error);
      subscriber.complete();
    }
  }
}
