import {
  Component,
  OnInit,
  Input,
  HostBinding,
  AfterViewInit,
  ElementRef,
  OnDestroy
} from "@angular/core";
import { Store, ApplicabilityService, SearchService } from "@viewer/core";
import { reaction } from "mobx";
import { MsmFormatter } from "@orion2/msm-formatter/index";
import { TaskNamespace } from "@orion2/msm-formatter/types/MsmTask";
import { BasicViewService } from "@viewer/content-provider/basic-view.service";
import { InspectionService } from "@viewer/core/toc-items/inspection.service";
import { MessageService } from "@orion2/message-service/message.service";
import { TaskId } from "@orion2/models/tocitem.models";
import { addElementOnClick, getRanges } from "@orion2/utils/functions.utils";
import { ApplicObject, CiraService } from "@viewer/core/ipc/cira/cira.service";
import { enrichDocumentation } from "@viewer/shared-module/helper.utils";

@Component({
  standalone: false,
  selector: "o-task-card",
  templateUrl: "./task-card.component.html",
  styleUrls: ["./task-card.component.scss"]
})
export class TaskCardComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() linkData: {
    editedTitle: string;
    shortTitle: string;
    id: number;
    reference: string;
    revision: string;
    dmc: string;
    versions: string[];
    url: string;
    params: string[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    task: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    parents?: any[];
    applicabilityMD5: string; // Used for inspection
  };
  @HostBinding("class.not-applicable") isNotApplicable = false;
  @HostBinding("class.hide-not-applicable") hideNotApplicable = false;
  @HostBinding("class.ponctual-task") ponctual = false;
  @Input() public initCollapse: boolean;
  // Collapse mode to define the size of the task card when reduced
  @Input() public collapseMode: "search" | "inspection";

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public taskInspections: any[];
  public versions: string;

  public taskData: TaskNamespace.MsmTask;
  public collapsed: boolean;
  applicabilities: ApplicObject = { serialNo: [], versions: [] };
  private url: string;
  private disposers: Function[] = [];

  constructor(
    public store: Store,
    private basicViewService: BasicViewService,
    private inspectionService: InspectionService,
    private searchService: SearchService,
    private messageService: MessageService,
    private elementRef: ElementRef,
    private ciraService: CiraService,
    private applicabilityService: ApplicabilityService
  ) {}

  ngOnInit() {
    // we do this because the default value may be false or true due to the navigation in the pages
    this.collapsed = this.initCollapse ?? false;
    this.url = `pub/${this.store.publicationID}/${this.store.publicationRevision}`;

    this.taskInspections = this.linkData.parents;

    const applicReaction = reaction(
      () => this.store.applicableMD5,
      () => {
        this.isNotApplicable = !this.applicabilityService.isApplicable(this.linkData);
      },
      { fireImmediately: true }
    );
    const showNotApplicableReaction = reaction(
      () => this.store.showNotApplicable,
      () => {
        this.hideNotApplicable = !this.store.showNotApplicable;
      },
      { fireImmediately: true }
    );

    this.taskData = MsmFormatter.unserialize(this.linkData.task, enrichDocumentation.bind(this));

    // ponctual info is the limitTypeValue attribute always on first interval line
    // in this case we want to have a dashed border in the msm-view
    // (deleted tasks do not have interval array)
    const limitTypeValue = this.taskData.interval?.[0].limitTypeValue;
    this.ponctual = limitTypeValue === "PO" || limitTypeValue === "OC";
    this.setApplicabilities();
    this.isNotApplicable = !this.applicabilityService.isApplicable(this.linkData);
    this.disposers.push(applicReaction, showNotApplicableReaction);
  }

  setApplicabilities() {
    return this.ciraService
      .getApplicListFromMD5(this.linkData.applicabilityMD5)
      .then((applics: ApplicObject) => {
        this.applicabilities.serialNo = getRanges(applics.serialNo);
        //  applics.versions may contain duplicate values
        this.applicabilities.versions =
          applics.versions.length > 0
            ? // we fallback on versions of the DM if we dont found versions from md5
              Array.from(new Set(applics.versions))
            : this.linkData.versions;

        this.versions = this.applicabilities.versions.join(", ");
      });
  }
  ngAfterViewInit(): void {
    this.generateExternLinks();
    this.basicViewService.addDuLinks(this.elementRef.nativeElement);
  }

  ngOnDestroy(): void {
    this.disposers.forEach((disposer: Function) => disposer());
  }

  /**
   * Create task documentation links and IPC links
   *
   * @memberof TaskCardComponent
   */
  generateExternLinks(): void {
    const links = this.elementRef.nativeElement.querySelectorAll(
      ".documentation.span_links, .mpPn.span_links"
    );

    for (const link of links) {
      addElementOnClick(link, link.getAttribute("id"), link.getAttribute("title"));
    }
  }

  /**
   * Return URL to parent task
   *
   * @returns
   * @memberof TaskCardComponent
   */
  getUrl(): string[] {
    if (this.taskData) {
      const res = ["/" + this.url, "du", this.taskData.parent];
      if (this.linkData.params !== undefined) {
        this.linkData.params.forEach(element => {
          res.push(element);
        });
      }
      return res;
    }
  }

  /**
   * Collapse task details, we pass the state with the "!this.collapsed" to reverse the collapse
   * but we can also pass another boolean when we use this function to toggleExpandCollapseAll
   *
   * @param event
   * @memberof TaskCardComponent
   */
  collapse(event: Event = undefined, state = !this.collapsed): void {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    // we set the new state of the collapse toggle
    this.collapsed = state;
  }

  /**
   * Get param to param on redirect to DM
   *
   * @param task
   * @returns
   * @memberof TaskCardComponent
   */
  getParams(task): Object {
    return {
      scroll: task.id
    };
  }

  /**
   * Remove the task from the inspection clicked on
   *
   * @param parent
   * @memberof TaskCardComponent
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public removeTaskFromInspection(inspection: any): void {
    const taskIds = {} as TaskId;
    // Remove revision for dmc
    taskIds.idRefDM = this.linkData.dmc.replace(/__\d{3}\.\d{2}(\.\d{2})?/g, "");
    taskIds.idRefSearch = this.linkData.id;
    this.inspectionService.removeTasksFromInspection([taskIds], inspection.id).then(() => {
      this.searchService.updateSearch();
      this.messageService.success("Task is removed from selected inspection");
    });
  }
}
