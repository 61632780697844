import { Component, OnInit, Input, HostBinding, OnDestroy } from "@angular/core";
import { Store, ApplicabilityService, PouchService } from "@viewer/core";
import { reaction } from "mobx";
import { Params } from "@angular/router";

@Component({
  standalone: false,
  selector: "o-card",
  templateUrl: "./card.component.html",
  styleUrls: ["./card.component.scss"]
})
export class CardComponent implements OnInit, OnDestroy {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() cardData: any;
  @HostBinding("class.not-applicable") isNotApplicable = false;
  @HostBinding("class.hide-not-applicable") hideNotApplicable = false;
  urlPub: string;
  metadata;
  loading = true;
  applicReaction: Function;
  showNotApplicableReaction: Function;

  public queryParam: Params;

  constructor(
    private store: Store,
    private applicabilityService: ApplicabilityService,
    private pouchService: PouchService
  ) {}

  ngOnInit() {
    this.urlPub = `pub/${this.store.publicationID}/${this.store.publicationRevision}`;
    this.retrieveMetadata(this.cardData.dmc).then(() => {
      this.loading = false;

      this.applicReaction = reaction(
        () => this.store.applicableMD5,
        () => {
          if (this.store.isS1000D) {
            this.isNotApplicable = !this.applicabilityService.isApplicableInline(this.metadata);
          } else {
            this.isNotApplicable = !this.applicabilityService.isApplicable(this.metadata);
          }
        },
        { fireImmediately: true }
      );
      this.showNotApplicableReaction = reaction(
        () => this.store.showNotApplicable,
        () => {
          this.hideNotApplicable = !this.store.showNotApplicable;
        },
        { fireImmediately: true }
      );
    });
    this.getQueryParams();
  }

  /**
   * get dmc url
   *
   * @returns
   * @memberof CardComponent
   */
  getUrl() {
    return ["/" + this.urlPub, "du", this.cardData.dmc];
  }

  /**
   * get metadata with dmc
   *
   * @param dmc
   * @returns
   * @memberof CardComponent
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  retrieveMetadata(dmc): Promise<any> {
    return this.pouchService.tocCaller.get(dmc).then(res => {
      this.metadata = res;
    });
  }

  ngOnDestroy() {
    this.applicReaction();
    this.showNotApplicableReaction();
  }

  /**
   * get query params
   *
   * @returns
   * @memberof CardComponent
   */
  private getQueryParams(): void {
    let scrollTo: string;

    if (this.cardData.csn) {
      scrollTo = this.store.isS1000D ? this.cardData.mpn : this.cardData.csn;
    } else {
      scrollTo = this.store.currentDMC;
    }

    this.queryParam = {
      scroll: scrollTo
    };
  }
}
