import { Injectable } from "@angular/core";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { TranslateService } from "@ngx-translate/core";
import { firstValueFrom } from "rxjs";

/**
 * Utility service necessary to translate the mat-paginator
 * References:
 * https://material.angular.io/components/paginator/overview
 * https://stackoverflow.com/questions/46869616/how-to-use-matpaginatorintl
 * https://dsebastien.medium.com/translating-the-matpaginator-angular-material-component-f72b52158dfc
 */

@Injectable()
export class MatPaginationIntlCustom extends MatPaginatorIntl {
  private ofLabel: string;

  constructor(private translate: TranslateService) {
    super();

    // React whenever the language is changed
    // And initialize the translations at construction time
    this.translate.onLangChange.subscribe(() => {
      this.itemsPerPageLabel = this.translate.instant("paginator.perpage");
      this.nextPageLabel = this.translate.instant("paginator.next");
      this.previousPageLabel = this.translate.instant("paginator.previous");
      this.ofLabel = this.translate.instant("paginator.of");
      // Fire a change event to make sure that the labels are refreshed
      this.changes.next();
    });
    // To force the initialization
    this.translate.onLangChange.emit({ lang: "", translations: {} });
  }

  getRangeLabel = (page: number, pageSize: number, length: number): string => {
    if (length === 0 || pageSize === 0) {
      return `0 ${this.ofLabel} ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex =
      page * pageSize > length ? (Math.ceil(length / pageSize) - 1) * pageSize : page * pageSize;

    const endIndex = Math.min(startIndex + pageSize, length);
    return `${startIndex + 1} - ${endIndex} ${this.ofLabel} ${length}`;
  };
}
