import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@viewer/core";
import {
  ImpactLink,
  ServiceBulletinService
} from "@viewer/core/toc-items/service-bulletin.service";
import { IReactionDisposer, reaction } from "mobx";

@Component({
  standalone: false,
  selector: "o-sb-impacting-warning",
  templateUrl: "./sb-impacting-warning.component.html",
  styleUrls: ["./sb-impacting-warning.component.scss"]
})
export class SbImpactingWarningComponent implements OnInit, OnDestroy {
  public dmcChangeHandlerReaction: IReactionDisposer;
  public isClose = true;
  public message: string;
  public links: ImpactLink[];

  private navigationMode: string;

  constructor(
    public store: Store,
    public sbService: ServiceBulletinService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.dmcChangeHandlerReaction = reaction(
      () => [this.store.currentDMC, this.store.offlineSearchReady],
      () => {
        if (!this.store.currentDMC) {
          return;
        }
        this.links = [];
        this.message = this.store.currentDMC.startsWith("sb__")
          ? "linked.datamodule.label"
          : "linked.sb.label";
        this.sbService
          .getImpactLinks(this.store.currentDMC, this.store.offlineSearchReady)
          .then((links: ImpactLink[]) => {
            this.links = links;
          });
      },
      { fireImmediately: true }
    );
  }

  ngOnDestroy() {
    this.dmcChangeHandlerReaction();
  }

  public navigateTo(linkId: string) {
    this.router.navigate(["redirect", linkId], {
      relativeTo: this.route.root.firstChild.firstChild
    });
  }

  public toggle() {
    this.isClose = !this.isClose;
  }
}
