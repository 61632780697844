import { DBConnexionType } from "@viewer/core/pouchdb/types";
import { Constant } from "@viewer/shared-module/constant";
import { UserCriterias } from "@viewer/core/applicability/applicability.service";
import { Injector } from "@angular/core";
import { DbSchema } from "libs/transfert/model/pubSchema";
import { LocalTocCaller } from "@viewer/core/pouchdb/caller/localTocCaller";
import { DEFAULT_ITEMS_PER_PAGE } from "@orion2/utils/constants.utils";

export interface DocLanguage {
  _id: string;
  value?: string;
  _rev?: string;
}

export interface DocIpcTocMode {
  _id: string;
  mode?: string;
}

export interface DocUserActivate3D {
  _id: string;
  activate3D?: boolean;
}

export interface DocUserAutoSynchro {
  _id: string;
  autoSynchro?: boolean;
}

export class UserCaller extends LocalTocCaller {
  constructor(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    processor: any,
    protected injector: Injector,
    dbSchema: DbSchema
  ) {
    super(processor, injector, dbSchema);
    this.dbParams = { ...this.dbParams, local: { auto_compaction: true } };
    super.init();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useCache(): Promise<any> {
    return this.doOnInstance(DBConnexionType.LOCAL, "get", Constant.cacheKey);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  couchURI(): Promise<any> {
    return this.doOnInstance(DBConnexionType.LOCAL, "get", Constant.uriCouchKey);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getAppPreferences(): Promise<any> {
    return this.doOnInstance(DBConnexionType.LOCAL, "get", Constant.uriCouchKey);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  saveHistory(publicationId: string, historyList: string[]): Promise<any> {
    const doc = {
      _id: this.getHistoryKey(publicationId),
      value: historyList
    };
    return this.setLocal(doc);
  }

  /**
   * Save a new criterias reference
   *
   * @param publicationId
   * @param criterias
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  saveApplicCriterias(publicationId: string, criterias: UserCriterias): Promise<any> {
    return this.setLocal({
      ...criterias,
      _id: this.getApplicsKey(publicationId)
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setCache(useCache: boolean): Promise<any> {
    const doc = {
      _id: Constant.cacheKey,
      value: useCache
    };
    return this.setLocal(doc);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setLocal(doc): Promise<any> {
    return this.callFunction("setLocal", doc);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setCouchURI(uri: string): Promise<any> {
    const doc = {
      _id: Constant.uriCouchKey,
      value: uri
    };
    return this.setLocal(doc);
  }

  getHistoryKey(publicationId: string): string {
    return `${Constant.historyKey}_${publicationId}`;
  }

  getApplicsKey(publicationId: string): string {
    return `${Constant.criteriasKey}_${publicationId}`;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getHistory(publicationId: string): Promise<any> {
    return this.doOnInstance(DBConnexionType.LOCAL, "get", this.getHistoryKey(publicationId));
  }

  /**
   * Get criterias from user DB
   *
   * @param publicationId
   */
  getApplicCriterias(publicationId: string): Promise<UserCriterias> {
    return this.doOnInstance(DBConnexionType.LOCAL, "get", this.getApplicsKey(publicationId));
  }

  /**
   * Return the user document language
   *
   * @returns
   * @memberof UserCaller
   */
  getLanguage(): Promise<DocLanguage> {
    return this.doOnInstance(DBConnexionType.LOCAL, "get", "lang");
  }

  /**
   * Update the user document language in pouch database
   *
   * @param selectedLang
   * @returns
   * @memberof UserCaller
   */
  updateLanguage(selectedLang: string): Promise<void> {
    // Get user language from local database
    return this.getLanguage().then((doc: DocLanguage) => {
      // If not found create a new document
      if (!doc) {
        doc = {
          _id: "lang"
        };
      }

      doc.value = selectedLang;

      // Store document into the local database
      return this.setLocal(doc);
    });
  }

  /**
   * Delete local user Base uri
   *
   * @returns
   * @memberof UserCaller
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deleteBaseUrl(): Promise<any> {
    return this.doOnInstance(DBConnexionType.LOCAL, "get", "uricouchdb").then(doc => {
      if (doc) {
        return this.deleteLocal("uricouchdb");
      }
    });
  }

  getHasUserDeclineTransferForDB(occCode: string): Promise<boolean> {
    return this.doOnInstance(DBConnexionType.REMOTE, "get", `rejec_transfer_${occCode}`)
      .then(remoteDocument => !!remoteDocument)
      .catch(error => {
        console.error("Error on fetch remote reject document : ", error.message);
        return undefined;
      });
  }

  setUserDeclineTransferForDB(occCode: string): Promise<void> {
    return this.doOnInstance(DBConnexionType.REMOTE, "put", {
      _id: `rejec_transfer_${occCode}`,
      date: new Date(),
      occCode
    }).catch(error => {
      console.error("Error on 'put' reject document : ", error);
    });
  }

  public getIpcTocMode(): Promise<string> {
    return this.doOnInstance(DBConnexionType.LOCAL, "get", "ipc_toc_mode").then(
      (doc: DocIpcTocMode) => doc?.mode
    );
  }

  public saveIpcTocMode(tableMode: boolean): Promise<void> {
    return this.setLocal({
      _id: "ipc_toc_mode",
      mode: tableMode ? "table" : "tree"
    });
  }

  public getUserActivate3D(): Promise<boolean> {
    return this.doOnInstance(DBConnexionType.LOCAL, "get", "user_activate_3D").then(
      (doc: DocUserActivate3D) => doc?.activate3D
    );
  }

  public saveUserActivate3D(activate3D: boolean): Promise<void> {
    return this.setLocal({
      _id: "user_activate_3D",
      activate3D
    });
  }

  public getUserAutoSynchro(): Promise<boolean> {
    return this.doOnInstance(DBConnexionType.LOCAL, "get", "user_auto_synchro").then(
      // SPEC: By default, auto synchro is activated: we return true if setting is not found in DB.
      (doc: DocUserAutoSynchro) => doc?.autoSynchro ?? true
    );
  }

  public saveUserAutoSynchro(autoSynchro: boolean): Promise<void> {
    return this.setLocal({
      _id: "user_auto_synchro",
      autoSynchro
    });
  }

  public getUserItemsPerPage(): Promise<number> {
    return this.doOnInstance(DBConnexionType.LOCAL, "get", "user_items_per_page").then(
      // SPEC: By default we return 25 if setting is not found in DB.
      (doc: { _id: string; itemsPerPage: number }) => doc?.itemsPerPage ?? DEFAULT_ITEMS_PER_PAGE
    );
  }

  public saveUserItemsPerPage(itemsPerPage: number): Promise<void> {
    return this.setLocal({
      _id: "user_items_per_page",
      itemsPerPage
    });
  }

  public getUserRedirectLastDm(): Promise<boolean> {
    return this.doOnInstance(DBConnexionType.LOCAL, "get", "user_redirect_last_dm").then(
      (doc: { _id: string; value: boolean }) => doc?.value ?? false
    );
  }

  public saveUserRedirectLastDm(value: boolean): Promise<void> {
    return this.setLocal({
      _id: "user_redirect_last_dm",
      value
    });
  }
}
