import { Component, OnInit } from "@angular/core";
import { MmhMeta, MmhService } from "@viewer/core/mmh/mmh.service";

@Component({
  standalone: false,
  selector: "o-mmh",
  templateUrl: "./mmh.component.html",
  styleUrls: ["./mmh.component.scss"]
})
export class MmhComponent implements OnInit {
  public mmhMeta: MmhMeta;

  constructor(private mmhService: MmhService) {}

  ngOnInit() {
    this.mmhService.getMMHInfo().then(meta => {
      this.mmhMeta = meta;
    });
  }
}
