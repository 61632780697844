import { Pipe, PipeTransform } from "@angular/core";
import { TranslateParser } from "@ngx-translate/core";

@Pipe({
  standalone: false,
  name: "pubLang"
})
export class PubLangPipe implements PipeTransform {
  private static cache: Object = {};
  public langAvailable = ["en", "fr", "es", "de"];

  constructor(private parser: TranslateParser) {}

  translate(key: string, lang: string, args?: Object): string {
    if (!lang || !this.langAvailable.includes(lang)) {
      lang = "en";
    }

    if (!PubLangPipe.cache.hasOwnProperty(lang)) {
      PubLangPipe.cache[lang] = {};
    }

    // Load in memory if don't exist
    if (!PubLangPipe.cache[lang].hasOwnProperty(key)) {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      PubLangPipe.cache[lang][key] = require(`../../apps/viewer/src/assets/i18n/${lang}.json`)[key];
    }

    // fallback to english if key is not defined in lang.json
    if (!PubLangPipe.cache[lang][key]) {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      PubLangPipe.cache[lang][key] = require("../../apps/viewer/src/assets/i18n/en.json")[key];
    }

    return this.parser.interpolate(PubLangPipe.cache[lang][key], args);
  }

  transform(key: string, lang: string, args?: Object): string {
    return this.translate(key, lang, args);
  }
}
