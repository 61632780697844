import { Component, OnInit, ChangeDetectorRef, Input } from "@angular/core";
import { Store } from "@viewer/core";
import { PubReplicateService } from "@viewer/replication/service/pub-replicate.service";
import { LocalStorageBackend } from "@openid/appauth/built/storage";
import { PubDoc } from "libs/models/couch.models";

interface DownloadChange {
  downloaded: number;
  resumeState?: boolean;
  startTime?: number;
}

@Component({
  standalone: false,
  selector: "o-pub-replication-progress",
  templateUrl: "./pub-replication-progress.component.html",
  styleUrls: ["./pub-replication-progress.component.scss"]
})
export class PubReplicationProgressComponent implements OnInit {
  @Input() pub: PubDoc;
  public pendingReplication: boolean;
  public state = {
    percent: 0,
    totalDownloaded: 0,
    totalRemote: 0,
    eta: new Date(0),
    sessionDownloaded: 0,
    sessionDownloadedWeight: 0,
    sessionStartingPoint: 0,
    totalDownloadedWeight: 0
  };
  private storage = new LocalStorageBackend();

  constructor(
    private store: Store,
    private cd: ChangeDetectorRef,
    private pubReplicateService: PubReplicateService
  ) {}

  ngOnInit() {
    this.pubReplicateService.registerProgressHandlers(this.pub._id, {
      progress: this.progressHandler.bind(this),
      complete: this.completeHandler.bind(this),
      state: this.state
    });

    this.resumeState().then(() => this.handleChange({ downloaded: 0 }));
  }

  public format(val: number): string {
    return isNaN(val) ? "-" : val.toString().padStart(2, "0");
  }

  public displayTotDownloaded(): string {
    return isNaN(this.state.totalRemote) ? "-" : this.state.totalDownloadedWeight.toFixed(3);
  }

  public displayPercent(): string {
    return isNaN(this.state.percent) ? "-" : this.state.percent.toFixed(1);
  }

  private handleChange(change: DownloadChange): void {
    if (change.downloaded > 0) {
      this.pendingReplication = true;
    }
    this.state.totalDownloaded += change.downloaded;
    this.state.sessionDownloaded += change.downloaded;
    this.state.sessionDownloadedWeight =
      this.state.sessionDownloaded + this.state.sessionStartingPoint < 0.8 * this.state.totalRemote
        ? this.state.sessionDownloaded - this.state.sessionStartingPoint
        : this.getPonderation(
            this.state.sessionDownloaded + this.state.sessionStartingPoint,
            this.state.totalRemote
          ) - this.state.sessionStartingPoint;

    this.state.totalDownloadedWeight =
      this.state.totalDownloaded < 0.8 * this.state.totalRemote
        ? this.state.totalDownloaded
        : this.getPonderation(this.state.totalDownloaded, this.state.totalRemote);

    const elapsed = new Date().getTime() - change.startTime;
    const remaining = this.state.totalRemote - this.state.sessionStartingPoint;
    const eta = new Date(elapsed * (remaining / this.state.sessionDownloadedWeight - 1));
    this.state.percent = this.getProgression();
    this.state.eta = eta;
    this.saveDownloaded(this.state.totalDownloaded);
    this.cd.detectChanges();
  }

  private progressHandler(change: DownloadChange): Promise<void> {
    const resumeProm = change.resumeState ? this.resumeState() : Promise.resolve();
    return resumeProm.then(() => this.handleChange(change));
  }

  private completeHandler(): Promise<void> {
    this.pendingReplication = false;
    this.cd.detectChanges();
    return this.storage.removeItem(`${this.pub._id}__downloaded`);
  }

  private resumeState(): Promise<void> {
    return this.storage.getItem(`${this.pub._id}__downloaded`).then((downloaded: string) => {
      // We need to known what have been downloaded from first start until now
      this.state.totalDownloaded = parseFloat(downloaded) || 0;
      // We need to known what have been downloaded from restart (aftter a pause) until now
      this.state.sessionStartingPoint = this.state.totalDownloadedWeight || 0;
      this.state.sessionDownloaded = 0;
      this.pendingReplication = this.state.totalDownloaded > 0;
      const pubSize = this.store.getPubDiskSize(this.pub) / Math.pow(10, 9); // Pub size in GB
      this.state.totalRemote = pubSize;
    });
  }

  private getProgression(): number {
    const percent = (100 * this.state.totalDownloadedWeight) / this.state.totalRemote;
    return isNaN(percent) ? 0 : percent;
  }

  private getPonderation(x: number, max: number): number {
    // Sigmoide curve
    // https://www.desmos.com/calculator/asew9dumf9
    const fx = 100 / (1 + Math.exp(0.095 * (65 - (x / max) * 100)));
    return (fx / 100) * max;
  }

  private saveDownloaded(downloaded: number): void {
    this.storage.setItem(`${this.pub._id}__downloaded`, downloaded.toString());
  }
}
