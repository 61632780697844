import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatSidenav } from "@angular/material/sidenav";
import { ActivatedRoute } from "@angular/router";
import { Basepub } from "@orion2/models/basepub.models";
import { BasepubUserService } from "@viewer/core/basepub/basepub-user.service";
import { SearchContractsService } from "@viewer/home/search-contracts/service/search-contracts.service";
import { filterText } from "@viewer/shared-module/helper.utils";
import { Observable, Subscription, map } from "rxjs";

@Component({
  standalone: false,
  selector: "o-search-contracts",
  templateUrl: "./search-contracts.component.html",
  styleUrls: ["./search-contracts.component.scss"]
})
export class SearchContractsComponent implements OnInit, OnDestroy {
  @Input() public searchSidenav: MatSidenav;

  public searchForm = new FormGroup({
    contractNumber: new FormControl(""),
    startFrom: new FormControl<Date>(null),
    startTo: new FormControl<Date>(null),
    endFrom: new FormControl<Date>(null),
    endTo: new FormControl<Date>(null),
    aircraftTechnicalName: new FormControl(""),
    aircraftVersion: new FormControl({ value: "", disabled: true }),
    manualCode: new FormControl(""),
    langCode: new FormControl(""),
    customizationCode: new FormControl(""),
    booklet: new FormControl("")
  });
  public searchData: Basepub.ContractSearchData;
  public selectedAircraft: Basepub.Aircraft;
  public contractNumberList: Observable<string[]>;
  public customizationList: Observable<string[]>;
  public bookletList: Observable<string[]>;
  private subscriptions = new Subscription();

  constructor(
    private basepubUserService: BasepubUserService,
    private searchContractsService: SearchContractsService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.contractNumberList = this.getAutocompleteData("contractNumber");
    this.customizationList = this.getAutocompleteData("customizationCode");
    this.bookletList = this.getAutocompleteData("booklet");

    this.getSearchData().then(() => {
      this.searchForm.patchValue(this.route.snapshot.queryParams);
      this.updateSelectedAircraft(this.searchForm.get("aircraftTechnicalName").value);
    });

    this.subscriptions.add(
      this.searchContractsService.filterReset.subscribe((control: string) => {
        this.resetOption(control);
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  /**
   * Submit the form and start the contract search.
   */
  public submit(): void {
    this.searchContractsService.searchContracts(this.searchForm.value);
    this.close();
  }

  /**
   * Reset all form fields.
   */
  public reset() {
    this.searchForm.reset();
    this.updateSelectedAircraft();
    this.searchContractsService.searchContracts();
  }

  public close(): void {
    this.searchSidenav.close();
  }

  /**
   * Update selectedAircraft according to the commercialName selected in the form.
   *
   * @param commercialName The commercial name of the selected aircraft.
   */
  public updateSelectedAircraft(commercialName?: string): void {
    if (commercialName) {
      this.selectedAircraft = this.searchData.aircrafts.find(
        (aircraft: Basepub.Aircraft) => aircraft.commercialName === commercialName
      );
      this.searchForm.get("aircraftVersion").enable();
    } else {
      this.selectedAircraft = null;
      this.searchForm.get("aircraftVersion").disable();
    }
  }

  /**
   * Get available options for contract search.
   */
  private getSearchData(): Promise<void> {
    return this.basepubUserService
      .getContractSearchData()
      .then((searchData: Basepub.ContractSearchData) => {
        this.searchData = searchData;
      });
  }

  /**
   * Reset a specific field in search form and recompute the results.
   *
   * @param control The name of the form field to reset.
   */
  private resetOption(control: string): void {
    this.searchForm.get(control).reset();
    if (control === "aircraftTechnicalName") {
      this.updateSelectedAircraft();
    }
    this.submit();
  }

  private getAutocompleteData(control: string): Observable<string[]> {
    return this.searchForm
      .get(control)
      .valueChanges.pipe(
        map((value: string) => (value ? filterText(value, this.searchData?.[control]) : []))
      );
  }
}
