import { AfterViewInit, Component, OnInit } from "@angular/core";
import { environment } from "@viewer-env/environment";
import { Router } from "@angular/router";
import { MessageService } from "@orion2/message-service/message.service";
import { TranslateService } from "@ngx-translate/core";
import { Store } from "@viewer/core";

@Component({
  standalone: false,
  selector: "o-auth-login",
  templateUrl: "auth-login.component.html",
  styleUrls: ["auth-login.component.scss"]
})
export class AuthLoginComponent implements OnInit, AfterViewInit {
  public loading = false;
  private redirectRoute = "";

  constructor(
    private router: Router,
    private message: MessageService,
    private translate: TranslateService,
    private store: Store
  ) {}

  ngOnInit() {
    const paramMap = this.router.routerState.snapshot.root.queryParamMap;
    this.redirectRoute = paramMap.get("redirect").slice(1, -1) || "";
    this.redirectRoute += paramMap.get("search").slice(1, -1) || "";
  }

  ngAfterViewInit(): void {
    if (this.store.isLoggedIn) {
      this.loading = true;
      this.router.navigateByUrl(this.redirectRoute);
    }
  }

  // Trigger the login() from authService and the result is the navigate() result
  public triggerLogin(): Promise<boolean> {
    this.loading = true;
    return environment.authService
      .login()
      .then(() => this.router.navigateByUrl(this.redirectRoute))
      .catch(() => {
        this.message.error(this.translate.instant("login.message.error"));
        return this.router.navigateByUrl("/", { replaceUrl: true });
      });
  }
}
