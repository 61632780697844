import { Component, OnInit, OnDestroy } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { NoteService, Store } from "@viewer/core";
import { TitleService } from "@viewer/header/title.service";
import { Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { Note } from "@orion2/models/tocitem.models";
import { IReactionDisposer, runInAction } from "mobx";
import { customFilterPredicate } from "@orion2/utils/front.utils";
import { HistorySource } from "@orion2/models/enums";
import { join } from "lodash";
import { CsvService } from "@viewer/core/csv/csv.service";
import { DatePipe } from "@angular/common";

@Component({
  standalone: false,
  selector: "o-note-list",
  templateUrl: "./note-list.component.html",
  styleUrls: ["./note-list.component.scss"]
})
export class NoteListComponent implements OnInit, OnDestroy {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  readonly HistorySource = HistorySource;
  public dataSource = new MatTableDataSource<Note>([]);
  public displayedColumns: string[];
  public itemType = "note";

  private notes: Note[] = [];
  private subscription: Subscription = new Subscription();
  private disposers: IReactionDisposer[] = [];

  constructor(
    private noteService: NoteService,
    private translate: TranslateService,
    private titleService: TitleService,
    private store: Store,
    private csvService: CsvService,
    private datePipe: DatePipe
  ) {}

  // TODO DG find a way to mutualize this with bookmark-list
  ngOnInit() {
    this.titleService.setPageTitle("notes.label");
    this.displayedColumns = [this.itemType];
    this.subscription.add(
      this.noteService.tocItemsOfType.subscribe(data => {
        if (!data) {
          return this.noteService.refresh();
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.updateDataSource(data as any as Note[]);
      })
    );
    // unselect card on list and force combineLatest (toc.component)
    runInAction(() => {
      this.store.currentTocNode = {
        ...this.store.currentTocNode,
        unselected: true
      };
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.disposers.forEach(disposer => disposer());
  }

  // if the method is called without argument, then uses this.bookmarks
  // else first update this.bookmarks with the value passed and then creates the datasource.
  // updateDataSource(bookmarks = this.bookmarks) {
  updateDataSource(notes = this.notes) {
    if (this.notes !== notes) {
      this.notes = notes;
    }
    if (!this.dataSource) {
      this.dataSource = new MatTableDataSource<Note>(notes);
      this.dataSource.filterPredicate = customFilterPredicate;
    }
    this.noteService.sortTocItemList(this.notes);
    this.dataSource.data = this.notes;
  }

  setFormatFileName(creationDate: Date): string {
    return join(
      [
        this.store.publicationID,
        "NOTES",
        this.datePipe.transform(creationDate, "yyyy-MM-dd_HH_mm_ss")
      ],
      "_"
    );
  }

  exportAsCSV() {
    const date = new Date();
    const csvHeaderTitles: string[] = [
      "Type",
      "DMC",
      "Title",
      "Content",
      "Creator",
      "Date of creation",
      "Revision"
    ];
    const csvHeaderDate: string[] = [
      this.datePipe.transform(date, "dd/MM/yyyy"),
      "",
      "",
      "",
      "",
      "",
      ""
    ];
    const csvRowList = [
      csvHeaderDate,
      csvHeaderTitles,
      ...this.notes.map(
        item =>
          [
            item.status,
            item.dmc,
            `"${this.escapeSpecialCharacters(item.customTitle)}"`,
            `"${this.escapeSpecialCharacters(item.content)}"`,
            item.author,
            item.date,
            item.minRevision
          ] as string[]
      )
    ];
    const filename: string = this.setFormatFileName(date);
    this.csvService.download(filename, csvRowList);
  }

  escapeSpecialCharacters(data: string): string {
    return data.replace(/"/g, '""');
  }
}
