import { Component, Inject, OnInit, EventEmitter } from "@angular/core";
import { ConfService, NoteService } from "@viewer/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { WebTekService } from "@viewer/toc-items/note-module/web-tek.service";
import { MessageService } from "@orion2/message-service/message.service";
import { TranslateService } from "@ngx-translate/core";
import { Note, TechnicalEvent } from "@orion2/models/tocitem.models";

export interface WebTekDialogData {
  note: Note;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateList: EventEmitter<any>;
}

@Component({
  standalone: false,
  selector: "o-web-tek-dialog",
  templateUrl: "./web-tek-dialog.component.html",
  styleUrls: ["./web-tek-dialog.component.scss"]
})
export class WebTekDialogComponent implements OnInit {
  public step: "validation" | "loading" | "success" = "validation";
  public note: Note;

  constructor(
    private noteService: NoteService,
    @Inject(MAT_DIALOG_DATA) private data: WebTekDialogData,
    private dialogRef: MatDialogRef<WebTekDialogComponent>,
    private webTekService: WebTekService,
    private messageService: MessageService,
    private translate: TranslateService,
    private confService: ConfService
  ) {}

  ngOnInit() {
    this.note = this.data.note;
  }

  /**
   * Closes the modal
   */
  close(): void {
    this.dialogRef.close();
  }

  /**
   * Creates the URL to access the created technical event
   *
   * @returns The URL
   */
  linkURL(): string {
    return `${this.confService.conf.webTekUrl}/${this.note.technicalEvent.id}`;
  }

  /**
   * Open the URL using the system default browser
   */
  openURL(): void {
    window.open(this.linkURL(), "_system");
  }

  /**
   * Submit the TR request and handle the response
   */
  submit(): void {
    this.step = "loading";
    this.webTekService
      .sendToWebTek(this.note)
      .then((te: TechnicalEvent) => {
        // In case we couldn't get our conf there will be no throw of error and the service will have already send a message
        if (!te) {
          this.dialogRef.close();
          this.messageService.error(this.translate.instant("webTek.error"));
          return;
        }

        this.note = { ...this.note, technicalEvent: te };
        return this.noteService.update(this.data.note, this.note).then(() => {
          this.step = "success";
        });
      })
      .catch(err => {
        this.note.technicalEvent = null;
        console.error("Can't access webTek service:", err);
        this.messageService.error(this.translate.instant("webTek.error"));
        this.dialogRef.close();
      });
  }
}
