import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  standalone: false,
  selector: "o-transfert-ti-dialog",
  templateUrl: "./transfert-ti-dialog.component.html",
  styleUrls: ["./transfert-ti-dialog.component.scss"]
})
export class TransfertTiDialogComponent {
  constructor(public dialogRef: MatDialogRef<TransfertTiDialogComponent>) {}
}
