import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ApplicabilityService, PouchService, Store } from "@viewer/core";
import { TocInfo } from "libs/models/couch.models";
import {
  from,
  of,
  Subject,
  Subscription,
  Observable,
  combineLatest,
  switchMap,
  concatMap,
  toArray
} from "rxjs";
import { toStream } from "mobx-utils/lib/observable-stream";
import { PreprintService } from "@viewer/core/toc-items/preprint.service";
import { FolderService } from "@viewer/core/toc-items/folder.service";
import { sortToc } from "@orion2/utils/functions.utils";
import { ServiceBulletinService } from "@viewer/core/toc-items/service-bulletin.service";
import { DocumentService } from "@viewer/core/toc-items/document.service";
import { HistorySource } from "@orion2/models/enums";
import cloneDeep from "fast-copy";
import { TocItem, Folder, TocPublicType, AdditionalDocs } from "@orion2/models/tocitem.models";
import { OrionCardData } from "@viewer/shared-module/orion-card/orion-card.component";

@Component({
  standalone: false,
  selector: "o-toc",
  templateUrl: "./toc.component.html",
  styleUrls: ["./toc.component.scss"]
})
export class TocComponent implements OnInit, OnDestroy {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  readonly HistorySource = HistorySource;

  public loading: boolean;
  public toc: (TocInfo | TocItem)[];

  parentNode;
  dmcSelected = "";
  tocSorted = new Subject();
  currentTocNode = undefined;
  private _subscription: Subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private pouchService: PouchService,
    private router: Router,
    public store: Store,
    public applicabilityService: ApplicabilityService,
    private preprintService: PreprintService,
    private folderService: FolderService,
    private documentService: DocumentService,
    private sbService: ServiceBulletinService
  ) {}

  /**
   * Use mock value if needed and create reaction to currentDMC
   */
  ngOnInit() {
    const tocSortedObs = this.tocSorted
      .pipe(
        switchMap((toc: TocInfo[]) => this.checkChildrenApplicability(toc)),
        switchMap((toc: TocInfo[]) => this.checkChildrenHasTocItems(toc))
      )
      .subscribe((toc: TocInfo[]) => {
        // SPEC: Update TOC once children are known and once applic + check TI are done
        // TOC is updated here to update display if elements have changed
        // If a TOC node have a TI, we change its reference so we must sort the new toc result
        this.toc = toc?.sort(sortToc);
      });

    const obsApplicableMd5 = toStream(() => this.store.applicableMD5, true);
    const obsCurrentTocNode = toStream(() => this.store.currentTocNode, true);
    const combineLatestObs = combineLatest([obsApplicableMd5, obsCurrentTocNode]).subscribe(() => {
      this._dmcChangeHandler();
    });

    this._subscription.add(tocSortedObs);
    this._subscription.add(combineLatestObs);
  }

  /**
   * Remove the dmc reaction
   */
  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  updateError() {
    this.parentNode = { title: "Error while loading TOC" };
    this.toc = [];
  }

  updateParent(parent: string): Promise<void> {
    return this.pouchService.tocCaller
      .get<TocInfo>(parent)
      .then((tocInfo: TocInfo) => {
        if (tocInfo) {
          this.parentNode = tocInfo;
          return false;
        }
        return true;
      })
      .catch(_ => true)
      .then((fetchFolder: boolean) => {
        if (fetchFolder) {
          return this.folderService.getAllFolder(parent).then((folder: Folder) => {
            this.parentNode = folder;
          });
        }
      });
  }

  updateChildren(parent: string): Promise<void> {
    if (parent === "loap") {
      return Promise.resolve();
    }

    return Promise.all([
      this.pouchService.tocCaller.children(parent),
      this.preprintService.getPreprints([parent]),
      this.folderService.getAllFolder(parent, true),
      this.documentService.getAllDocument(parent, true),
      this.sbService.getAllServiceBulletin(parent, true)
    ]).then(([response, preprints, folders, documents, sbs]) => {
      let toc = preprints ? response.concat(preprints) : response;
      toc = folders ? toc.concat(folders) : toc;
      toc = documents ? toc.concat(documents) : toc;
      toc = sbs ? toc.concat(sbs) : toc;
      const sorted = this.sortChildren(toc);

      // SPEC: Update TOC once children are known and once applic + check TI are done
      // Use of cloneDeep to make sure that toc is fully updated on 2nd update
      // TOC is updated here to be displayed quickly
      if (this.IsTocUpdated(sorted)) {
        this.toc = cloneDeep(sorted);
      }

      this.tocSorted.next(sorted);

      this.loading = false;
    });
  }

  /**
   * Update child and parent of toc
   *
   * @param parent
   */
  public update(parent: string): Promise<void> {
    if (!parent) {
      return this.router
        .navigate(["du", "loap", "loap"], {
          relativeTo: this.route
        })
        .then(() => {});
    }

    this.loading = true;

    return Promise.all([this.updateParent(parent), this.updateChildren(parent)])
      .then(() => {})
      .catch(err => {
        console.error("Update error", err);
        this.updateError();
      });
  }

  sortChildren(toc: TocInfo[]): TocInfo[] {
    return toc.sort(sortToc);
  }

  public getOrionCardData(element: TocInfo): OrionCardData {
    return {
      dmc: element.dmc,
      title: element["type"] ? element.title : element.shortTitle,
      subtitle: element.reference,
      revisionMark: element["type"] || element.revision,
      versions: element.versions,
      applicabilityMD5: element.applicabilityMD5,
      showAdditionalInfos: true,
      issueInfo: element["issueInfo"]
    };
  }

  public getUrl(dmc: string): string {
    if (dmc === this.dmcSelected) {
      return undefined;
    }
    return `/pub/${this.store.publicationID}/${this.store.publicationRevision}/du/${dmc}`;
  }

  /**
   * Update the toc and handle moc value
   *
   */
  private _dmcChangeHandler(): Promise<void> {
    // if no currentTocNode is set no need to continue
    if (!this.store.currentTocNode) {
      return Promise.resolve();
    }

    this.currentTocNode = this.store.currentTocNode;

    // Fix toc selected node when we are on current dmc and back to store
    // Did that for fix selected dmc : see more on
    // https://gitlab.altengroup.net/airbus/orion2/orion2/issues/86
    if (this.currentTocNode?.unselected && this.toc?.length) {
      this.dmcSelected = undefined;
    } else if (this.currentTocNode && this.currentTocNode._id) {
      this.dmcSelected = this.currentTocNode._id;
    }

    const parent =
      this.currentTocNode.type && this.currentTocNode.type !== "folder"
        ? this.currentTocNode.parents[0]
        : this.currentTocNode.parent || "root";

    return this.update(parent);
  }

  private checkChildrenApplicability(toc: TocInfo[]): Observable<TocInfo[]> {
    if (toc) {
      return from(toc)
        .pipe(
          concatMap((child: TocInfo) =>
            from(
              this.applicabilityService.isFolderApplicable(child).then(isApplicable => {
                child["notApplicable"] = !isApplicable;
                return child;
              })
            )
          )
        )
        .pipe(toArray());
    }

    return of(undefined);
  }

  private checkChildrenHasTocItems(toc: (TocInfo | AdditionalDocs)[]): Observable<TocInfo[]> {
    if (toc) {
      // Update object for standalone preprints
      const standalonePreprint = toc.filter(
        (child: TocInfo | AdditionalDocs) => "type" in child && child.type !== TocPublicType.FOLDER
      ) as AdditionalDocs[];

      standalonePreprint.forEach((child: AdditionalDocs) => {
        delete child["date"];
        delete child["from"];
      });
      return of(toc);
    }

    return of(undefined);
  }

  private IsTocUpdated(toc: TocInfo[]): boolean {
    if (!this.toc || !toc || this.toc.length !== toc.length) {
      return true;
    }
    return this.toc.some((item, index) => sortToc(item as TocInfo, toc[index]) !== 0);
  }
}
