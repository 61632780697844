import { Injectable } from "@angular/core";
import { BasepubUserService } from "@viewer/core/basepub/basepub-user.service";
import { ConfService } from "@viewer/core/conf/conf.service";
import { Basepub } from "@orion2/models/basepub.models";
import { Params } from "@angular/router";

@Injectable()
export class ContractsService {
  constructor(
    private basepubUserService: BasepubUserService,
    private confService: ConfService
  ) {}

  public getContracts(params?: Params): Promise<Basepub.Contract[]> {
    if (!this.confService.useAuth) {
      return Promise.resolve([]);
    }

    return this.basepubUserService
      .getContracts(params)
      .then((contracts: Basepub.Contract[]) =>
        contracts.map((contract: Basepub.Contract) => this.addInfosToContract(contract))
      );
  }

  /**
   * Enrich contract object with an infos field that will be used by the search to filter contracts list
   *
   * @param contract The Contract object to add infos property to
   */
  private addInfosToContract(contract: Basepub.Contract): Basepub.Contract {
    contract.infos = [
      contract.aircraftTechnicalName,
      contract.number,
      contract.renewalNumber,
      contract.companyId,
      contract.companyFullName,
      contract.startDate,
      contract.endDate
    ].join(" ");
    return contract;
  }
}
