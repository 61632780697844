import { LocalTocCaller } from "@viewer/core/pouchdb/caller/localTocCaller";
import { Injector } from "@angular/core";
import { DbSchema } from "libs/transfert/model/pubSchema";

export class TocCorpCaller extends LocalTocCaller {
  constructor(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    processor: any,
    protected injector: Injector,
    dbSchema: DbSchema
  ) {
    super(processor, injector, dbSchema);
    this.dbParams = { ...this.dbParams, local: { auto_compaction: true } };
    // Warning: as init is a promise we cannot ensure that
    // the constructor ends only when init has returned.
    super.init();
  }

  public ensureIndexIsBuilt(): Promise<any> {
    // SPEC: Since we use _find for Toc Items, we don't need children anymore
    return super.ensureFilterIndexIsBuilt("_design/filter-type-min");
  }
}
