import { Injectable, OnDestroy } from "@angular/core";
import { XmlDoc } from "@orion2/models/couch.models";
import { PouchService } from "@viewer/core/pouchdb/pouch.service";
import { Store } from "@viewer/core/state/store";

import xpath from "xpath";

export interface MmhMeta {
  unitMmh: string;
  unitMet: string;
  nbAvionics: string;
  nbAirframe: string;
  nbOtherQualif: string;
  globalMmh: string;
  globalMet: string;
  minNbTech: string;
}

@Injectable()
export class MmhService implements OnDestroy {
  public mmhDu: Document;
  private metaMap: Map<string, MmhMeta>;

  constructor(
    private pouchService: PouchService,
    private store: Store
  ) {
    this.metaMap = new Map();
  }

  ngOnDestroy(): void {
    this.metaMap.clear();
    this.mmhDu = undefined;
  }

  public getMMHInfo(): Promise<MmhMeta> {
    // SPEC : The current DMC contains excess information compared to the search for a DU in the MMH file.
    // We retrieve the structure of DU : A-30-44-0080-00A-710A-B
    const regex = /.-.{2}-.{2}-.{4}-.{2}.-.{3}.-./g;
    const dmc = "H160-" + regex.exec(this.store.currentDMC);
    return this.metaMap.has(dmc)
      ? Promise.resolve(this.metaMap.get(dmc))
      : this.getMetaFromDocument(dmc);
  }

  private getMmhDU(): Promise<Document> {
    return this.mmhDu
      ? Promise.resolve(this.mmhDu)
      : this.pouchService.xmlCaller
          .get(this.store.pubInfo.specificParams["mmhDu"])
          .then(
            (doc: XmlDoc) => (this.mmhDu = new DOMParser().parseFromString(doc.data, "text/xml"))
          );
  }

  /**
   * Iterate over an array of quantityTypes and return the first quantity retrieved
   *
   * @param quantityTypes
   */
  private getQuantityValue(row: Document, quantityTypes: string[]): string {
    for (const quantityType of quantityTypes) {
      const value = xpath
        .select(
          `(entry/para/quantity[@quantityType = "${quantityType}"]/quantityGroup/quantityValue)`,
          row
        )
        .map((v: Node, _index, values) => {
          // SPEC: If there are more than two quantityValue in a quantityGroup and their unitOfMeasure is h and min
          // Then we want to display a custom text instead of their unitOfMeasure
          if (values.length > 1) {
            const unitOfMeasure = xpath.select("string(@quantityUnitOfMeasure)", v, true) as string;
            switch (unitOfMeasure) {
              case "h":
                return `${v.textContent} hour(s)`;
              case "min":
                return `${v.textContent} minute(s)`;
              default:
                return v.textContent;
            }
          }
          return v.textContent;
        })
        .join(" ");
      if (value) {
        return value;
      }
    }

    // Default return if no quantity found
    return "-";
  }

  private getMetaFromDocument(dmc: string): Promise<MmhMeta> {
    return this.getMmhDU().then(doc => {
      const goodRow = xpath.select(
        `dmodule/content/description/table/tgroup/tbody/row[entry/para[text() = "${dmc}"]]`,
        doc
      )[0] as Document;

      const meta = {
        unitMmh: this.ensureTimeFormat(this.getQuantityValue(goodRow, ["qty85", "qty96"])),
        unitMet: this.ensureTimeFormat(this.getQuantityValue(goodRow, ["qty86", "qty97"])),
        nbAvionics: this.getQuantityValue(goodRow, ["qty90"]),
        nbAirframe: this.getQuantityValue(goodRow, ["qty91"]),
        nbOtherQualif: this.getQuantityValue(goodRow, ["qty94"]),
        globalMmh: this.ensureTimeFormat(this.getQuantityValue(goodRow, ["qty87", "qty98"])),
        globalMet: this.ensureTimeFormat(this.getQuantityValue(goodRow, ["qty88", "qty99"])),
        minNbTech: this.getQuantityValue(goodRow, ["qty89"])
      };

      // Save meta to the cache map in order to not get it anymore
      this.metaMap.set(dmc, meta);
      return meta;
    });
  }

  /**
   * Check if the given quantity value is in decimal format, if yes convert it to hours/mins format
   *
   * @param quantityValue
   */
  private ensureTimeFormat(quantityValue: string): string {
    const duration = Number(quantityValue);
    if (duration) {
      const hours = Math.floor(duration);
      const mins = Math.ceil((duration - hours) * 60); // We round up to the upper minute
      if (mins !== 60) {
        return `${hours}h ${mins}m`;
      }
      return `${hours + 1}h 0m`;
    }
    return quantityValue;
  }
}
