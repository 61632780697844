import { Component } from "@angular/core";
import { XSLService } from "@viewer/content-provider/xsl.service";
import { Store } from "@viewer/core";

@Component({
  standalone: false,
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "o-alsMsmSignature-form",
  templateUrl: "./als-msm-signature.component.html",
  styleUrls: ["./als-msm-signature.component.scss"]
})
export class AlsMsmSignatureComponent {
  constructor(
    private xslService: XSLService,
    private store: Store
  ) {}
}
